"use strict";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.commonConfig = void 0;
exports.commonConfig = {
  CROSS_CHAIN: {
    MINIMUM_SLIPPAGE: '3',
    CCLP_TIMEOUT: 30
  },
  STAKE_UNSTAKE: {
    //it means 5 minutes
    LIMITED_INTERVAL_TIME: 300
  },
  ERROR_CODES: {
    NOT_FOUND: 404,
    NO_ROUTE: 402,
    NETWORK_COIN_NOT_SUFFICIENT: 405,
    INSUFFICIENT_TAKER_BALANCE: 406,
    HIGH_IMPACT: 407,
    INPUT_LIMIT_ISSUE: 408,
    EXECUTION_FEE_ERROR: 412,
    OUTPUT_AMOUNT_OUT_OF_SCOPE: 413,
    AMOUNT_OUT_ESTIMATION_UNKNOWN: 415,
    SUCCESSFULL_ESTIMATION: 200
  }
};
