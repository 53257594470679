const t = Symbol.for("@ts-pattern/matcher"),
  e = Symbol.for("@ts-pattern/isVariadic"),
  n = "@ts-pattern/anonymous-select-key",
  r = t => Boolean(t && "object" == typeof t),
  i = e => e && !!e[t],
  o = (n, s, c) => {
    if (i(n)) {
      const e = n[t](),
        {
          matched: r,
          selections: i
        } = e.match(s);
      return r && i && Object.keys(i).forEach(t => c(t, i[t])), r;
    }
    if (r(n)) {
      if (!r(s)) return !1;
      if (Array.isArray(n)) {
        if (!Array.isArray(s)) return !1;
        let t = [],
          r = [],
          a = [];
        for (const o of n.keys()) {
          const s = n[o];
          i(s) && s[e] ? a.push(s) : a.length ? r.push(s) : t.push(s);
        }
        if (a.length) {
          if (a.length > 1) throw new Error("Pattern error: Using `...P.array(...)` several times in a single pattern is not allowed.");
          if (s.length < t.length + r.length) return !1;
          const e = s.slice(0, t.length),
            n = 0 === r.length ? [] : s.slice(-r.length),
            i = s.slice(t.length, 0 === r.length ? Infinity : -r.length);
          return t.every((t, n) => o(t, e[n], c)) && r.every((t, e) => o(t, n[e], c)) && (0 === a.length || o(a[0], i, c));
        }
        return n.length === s.length && n.every((t, e) => o(t, s[e], c));
      }
      return Object.keys(n).every(e => {
        const r = n[e];
        return (e in s || i(a = r) && "optional" === a[t]().matcherType) && o(r, s[e], c);
        var a;
      });
    }
    return Object.is(s, n);
  },
  s = e => {
    var n, o, a;
    return r(e) ? i(e) ? null != (n = null == (o = (a = e[t]()).getSelectionKeys) ? void 0 : o.call(a)) ? n : [] : Array.isArray(e) ? c(e, s) : c(Object.values(e), s) : [];
  },
  c = (t, e) => t.reduce((t, n) => t.concat(e(n)), []);
function a(...t) {
  if (1 === t.length) {
    const [e] = t;
    return t => o(e, t, () => {});
  }
  if (2 === t.length) {
    const [e, n] = t;
    return o(e, n, () => {});
  }
  throw new Error(`isMatching wasn't given the right number of arguments: expected 1 or 2, received ${t.length}.`);
}
function u(t) {
  return Object.assign(t, {
    optional: () => l(t),
    and: e => m(t, e),
    or: e => d(t, e),
    select: e => void 0 === e ? p(t) : p(e, t)
  });
}
function h(t) {
  return Object.assign((t => Object.assign(t, {
    [Symbol.iterator]() {
      let n = 0;
      const r = [{
        value: Object.assign(t, {
          [e]: !0
        }),
        done: !1
      }, {
        done: !0,
        value: void 0
      }];
      return {
        next: () => {
          var t;
          return null != (t = r[n++]) ? t : r.at(-1);
        }
      };
    }
  }))(t), {
    optional: () => h(l(t)),
    select: e => h(void 0 === e ? p(t) : p(e, t))
  });
}
function l(e) {
  return u({
    [t]: () => ({
      match: t => {
        let n = {};
        const r = (t, e) => {
          n[t] = e;
        };
        return void 0 === t ? (s(e).forEach(t => r(t, void 0)), {
          matched: !0,
          selections: n
        }) : {
          matched: o(e, t, r),
          selections: n
        };
      },
      getSelectionKeys: () => s(e),
      matcherType: "optional"
    })
  });
}
const f = (t, e) => {
    for (const n of t) if (!e(n)) return !1;
    return !0;
  },
  g = (t, e) => {
    for (const [n, r] of t.entries()) if (!e(r, n)) return !1;
    return !0;
  };
function m(...e) {
  return u({
    [t]: () => ({
      match: t => {
        let n = {};
        const r = (t, e) => {
          n[t] = e;
        };
        return {
          matched: e.every(e => o(e, t, r)),
          selections: n
        };
      },
      getSelectionKeys: () => c(e, s),
      matcherType: "and"
    })
  });
}
function d(...e) {
  return u({
    [t]: () => ({
      match: t => {
        let n = {};
        const r = (t, e) => {
          n[t] = e;
        };
        return c(e, s).forEach(t => r(t, void 0)), {
          matched: e.some(e => o(e, t, r)),
          selections: n
        };
      },
      getSelectionKeys: () => c(e, s),
      matcherType: "or"
    })
  });
}
function y(e) {
  return {
    [t]: () => ({
      match: t => ({
        matched: Boolean(e(t))
      })
    })
  };
}
function p(...e) {
  const r = "string" == typeof e[0] ? e[0] : void 0,
    i = 2 === e.length ? e[1] : "string" == typeof e[0] ? void 0 : e[0];
  return u({
    [t]: () => ({
      match: t => {
        let e = {
          [null != r ? r : n]: t
        };
        return {
          matched: void 0 === i || o(i, t, (t, n) => {
            e[t] = n;
          }),
          selections: e
        };
      },
      getSelectionKeys: () => [null != r ? r : n].concat(void 0 === i ? [] : s(i))
    })
  });
}
function v(t) {
  return "number" == typeof t;
}
function b(t) {
  return "string" == typeof t;
}
function w(t) {
  return "bigint" == typeof t;
}
const S = u(y(function (t) {
    return !0;
  })),
  O = S,
  j = t => Object.assign(u(t), {
    startsWith: e => {
      return j(m(t, (n = e, y(t => b(t) && t.startsWith(n)))));
      var n;
    },
    endsWith: e => {
      return j(m(t, (n = e, y(t => b(t) && t.endsWith(n)))));
      var n;
    },
    minLength: e => j(m(t, (t => y(e => b(e) && e.length >= t))(e))),
    maxLength: e => j(m(t, (t => y(e => b(e) && e.length <= t))(e))),
    includes: e => {
      return j(m(t, (n = e, y(t => b(t) && t.includes(n)))));
      var n;
    },
    regex: e => {
      return j(m(t, (n = e, y(t => b(t) && Boolean(t.match(n))))));
      var n;
    }
  }),
  E = j(y(b)),
  K = t => Object.assign(u(t), {
    between: (e, n) => K(m(t, ((t, e) => y(n => v(n) && t <= n && e >= n))(e, n))),
    lt: e => K(m(t, (t => y(e => v(e) && e < t))(e))),
    gt: e => K(m(t, (t => y(e => v(e) && e > t))(e))),
    lte: e => K(m(t, (t => y(e => v(e) && e <= t))(e))),
    gte: e => K(m(t, (t => y(e => v(e) && e >= t))(e))),
    int: () => K(m(t, y(t => v(t) && Number.isInteger(t)))),
    finite: () => K(m(t, y(t => v(t) && Number.isFinite(t)))),
    positive: () => K(m(t, y(t => v(t) && t > 0))),
    negative: () => K(m(t, y(t => v(t) && t < 0)))
  }),
  x = K(y(v)),
  A = t => Object.assign(u(t), {
    between: (e, n) => A(m(t, ((t, e) => y(n => w(n) && t <= n && e >= n))(e, n))),
    lt: e => A(m(t, (t => y(e => w(e) && e < t))(e))),
    gt: e => A(m(t, (t => y(e => w(e) && e > t))(e))),
    lte: e => A(m(t, (t => y(e => w(e) && e <= t))(e))),
    gte: e => A(m(t, (t => y(e => w(e) && e >= t))(e))),
    positive: () => A(m(t, y(t => w(t) && t > 0))),
    negative: () => A(m(t, y(t => w(t) && t < 0)))
  }),
  P = A(y(w)),
  T = u(y(function (t) {
    return "boolean" == typeof t;
  })),
  k = u(y(function (t) {
    return "symbol" == typeof t;
  })),
  B = u(y(function (t) {
    return null == t;
  })),
  _ = u(y(function (t) {
    return null != t;
  }));
var N = {
  __proto__: null,
  matcher: t,
  optional: l,
  array: function (...e) {
    return h({
      [t]: () => ({
        match: t => {
          if (!Array.isArray(t)) return {
            matched: !1
          };
          if (0 === e.length) return {
            matched: !0
          };
          const n = e[0];
          let r = {};
          if (0 === t.length) return s(n).forEach(t => {
            r[t] = [];
          }), {
            matched: !0,
            selections: r
          };
          const i = (t, e) => {
            r[t] = (r[t] || []).concat([e]);
          };
          return {
            matched: t.every(t => o(n, t, i)),
            selections: r
          };
        },
        getSelectionKeys: () => 0 === e.length ? [] : s(e[0])
      })
    });
  },
  set: function (...e) {
    return u({
      [t]: () => ({
        match: t => {
          if (!(t instanceof Set)) return {
            matched: !1
          };
          let n = {};
          if (0 === t.size) return {
            matched: !0,
            selections: n
          };
          if (0 === e.length) return {
            matched: !0
          };
          const r = (t, e) => {
              n[t] = (n[t] || []).concat([e]);
            },
            i = e[0];
          return {
            matched: f(t, t => o(i, t, r)),
            selections: n
          };
        },
        getSelectionKeys: () => 0 === e.length ? [] : s(e[0])
      })
    });
  },
  map: function (...e) {
    return u({
      [t]: () => ({
        match: t => {
          if (!(t instanceof Map)) return {
            matched: !1
          };
          let n = {};
          if (0 === t.size) return {
            matched: !0,
            selections: n
          };
          const r = (t, e) => {
            n[t] = (n[t] || []).concat([e]);
          };
          if (0 === e.length) return {
            matched: !0
          };
          var i;
          if (1 === e.length) throw new Error(`\`P.map\` wasn't given enough arguments. Expected (key, value), received ${null == (i = e[0]) ? void 0 : i.toString()}`);
          const [s, c] = e;
          return {
            matched: g(t, (t, e) => {
              const n = o(s, e, r),
                i = o(c, t, r);
              return n && i;
            }),
            selections: n
          };
        },
        getSelectionKeys: () => 0 === e.length ? [] : [...s(e[0]), ...s(e[1])]
      })
    });
  },
  intersection: m,
  union: d,
  not: function (e) {
    return u({
      [t]: () => ({
        match: t => ({
          matched: !o(e, t, () => {})
        }),
        getSelectionKeys: () => [],
        matcherType: "not"
      })
    });
  },
  when: y,
  select: p,
  any: S,
  _: O,
  string: E,
  number: x,
  bigint: P,
  boolean: T,
  symbol: k,
  nullish: B,
  nonNullable: _,
  instanceOf: function (t) {
    return u(y(function (t) {
      return e => e instanceof t;
    }(t)));
  },
  shape: function (t) {
    return u(y(a(t)));
  }
};
const W = {
  matched: !1,
  value: void 0
};
function $(t) {
  return new z(t, W);
}
class z {
  constructor(t, e) {
    this.input = void 0, this.state = void 0, this.input = t, this.state = e;
  }
  with(...t) {
    if (this.state.matched) return this;
    const e = t[t.length - 1],
      r = [t[0]];
    let i;
    3 === t.length && "function" == typeof t[1] ? i = t[1] : t.length > 2 && r.push(...t.slice(1, t.length - 1));
    let s = !1,
      c = {};
    const a = (t, e) => {
        s = !0, c[t] = e;
      },
      u = !r.some(t => o(t, this.input, a)) || i && !Boolean(i(this.input)) ? W : {
        matched: !0,
        value: e(s ? n in c ? c[n] : c : this.input, this.input)
      };
    return new z(this.input, u);
  }
  when(t, e) {
    if (this.state.matched) return this;
    const n = Boolean(t(this.input));
    return new z(this.input, n ? {
      matched: !0,
      value: e(this.input, this.input)
    } : W);
  }
  otherwise(t) {
    return this.state.matched ? this.state.value : t(this.input);
  }
  exhaustive() {
    if (this.state.matched) return this.state.value;
    let t;
    try {
      t = JSON.stringify(this.input);
    } catch (e) {
      t = this.input;
    }
    throw new Error(`Pattern matching error: no pattern matches value ${t}`);
  }
  run() {
    return this.exhaustive();
  }
  returnType() {
    return this;
  }
}
export { N as P, N as Pattern, a as isMatching, $ as match };
