"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CROWD_USDT_LP_STAKE_OPPORTUNITY_V2_1 = void 0;
exports.CROWD_USDT_LP_STAKE_OPPORTUNITY_V2_1 = [{
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountA',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountB',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'liquidity',
    type: 'uint256'
  }],
  name: 'AddedLiquidity',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'previousAdmin',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newAdmin',
    type: 'address'
  }],
  name: 'AdminChanged',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'beacon',
    type: 'address'
  }],
  name: 'BeaconUpgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'totalFee',
    type: 'uint256'
  }],
  name: 'FeeDeducted',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint8',
    name: 'version',
    type: 'uint8'
  }],
  name: 'Initialized',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'InvestedByLP',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'InvestedByToken',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'InvestedByTokenAOrTokenB',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountA',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountB',
    type: 'uint256'
  }],
  name: 'InvestedByTokenATokenB',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }],
  name: 'Left',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Paused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'Refund',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'liquidity',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountA',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountB',
    type: 'uint256'
  }],
  name: 'RemovedLiquidity',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'feePercentage',
    type: 'uint256'
  }],
  name: 'SetFee',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'feeTo',
    type: 'address'
  }],
  name: 'SetFeeTo',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'pair',
    type: 'address'
  }],
  name: 'SetPairFactory',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'router',
    type: 'address'
  }],
  name: 'SetRouter',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'swapContract',
    type: 'address'
  }],
  name: 'SetSwapContact',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'tokenA',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'tokenB',
    type: 'address'
  }],
  name: 'SetTokens',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'Staked',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'fromToken',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'toToken',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountIn',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountOut',
    type: 'uint256'
  }],
  name: 'Swapped',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Unpaused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'rewards',
    type: 'uint256'
  }],
  name: 'Unstaked',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'Upgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'receiver',
    type: 'address'
  }],
  name: 'WithdrawnFunds',
  type: 'event'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'coinWrapper',
  outputs: [{
    internalType: 'contract IWETH',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'feeStruct',
  outputs: [{
    internalType: 'address payable',
    name: 'feeTo',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'addLiquidityFee',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'removeLiquidityFee',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'stakeFee',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'unstakeFee',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'dexFee',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'aggregatorFee',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amountLP',
    type: 'uint256'
  }],
  name: 'investByLP',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'contract IERC20Upgradeable',
    name: '_token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    components: [{
      internalType: 'bytes4',
      name: 'selector',
      type: 'bytes4'
    }, {
      internalType: 'bytes[]',
      name: 'params',
      type: 'bytes[]'
    }, {
      internalType: 'bool',
      name: 'isReplace',
      type: 'bool'
    }, {
      internalType: 'uint8',
      name: 'index',
      type: 'uint8'
    }, {
      internalType: 'uint16',
      name: 'flag',
      type: 'uint16'
    }, {
      internalType: 'address',
      name: '_pairForCalculationDesireAmountIn',
      type: 'address'
    }],
    internalType: 'struct OpportunityV2.DexDescriptor',
    name: '_dexDescriptorB',
    type: 'tuple'
  }, {
    components: [{
      internalType: 'bytes4',
      name: 'selector',
      type: 'bytes4'
    }, {
      internalType: 'bytes[]',
      name: 'params',
      type: 'bytes[]'
    }, {
      internalType: 'bool',
      name: 'isReplace',
      type: 'bool'
    }, {
      internalType: 'uint8',
      name: 'index',
      type: 'uint8'
    }, {
      internalType: 'uint16',
      name: 'flag',
      type: 'uint16'
    }, {
      internalType: 'address',
      name: '_pairForCalculationDesireAmountIn',
      type: 'address'
    }],
    internalType: 'struct OpportunityV2.DexDescriptor',
    name: '_dexDescriptorA',
    type: 'tuple'
  }, {
    internalType: 'uint256',
    name: '_deadline',
    type: 'uint256'
  }],
  name: 'investByToken',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    components: [{
      internalType: 'bytes4',
      name: 'selector',
      type: 'bytes4'
    }, {
      internalType: 'bytes[]',
      name: 'params',
      type: 'bytes[]'
    }, {
      internalType: 'bool',
      name: 'isReplace',
      type: 'bool'
    }, {
      internalType: 'uint8',
      name: 'index',
      type: 'uint8'
    }, {
      internalType: 'uint16',
      name: 'flag',
      type: 'uint16'
    }, {
      internalType: 'address',
      name: '_pairForCalculationDesireAmountIn',
      type: 'address'
    }],
    internalType: 'struct OpportunityV2.DexDescriptor',
    name: '_dexDescriptor',
    type: 'tuple'
  }, {
    internalType: 'uint256',
    name: '_addLiquidityDeadline',
    type: 'uint256'
  }],
  name: 'investByTokenA',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amountA',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_amountB',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_addLiquidityDeadline',
    type: 'uint256'
  }],
  name: 'investByTokenATokenB',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    components: [{
      internalType: 'bytes4',
      name: 'selector',
      type: 'bytes4'
    }, {
      internalType: 'bytes[]',
      name: 'params',
      type: 'bytes[]'
    }, {
      internalType: 'bool',
      name: 'isReplace',
      type: 'bool'
    }, {
      internalType: 'uint8',
      name: 'index',
      type: 'uint8'
    }, {
      internalType: 'uint16',
      name: 'flag',
      type: 'uint16'
    }, {
      internalType: 'address',
      name: '_pairForCalculationDesireAmountIn',
      type: 'address'
    }],
    internalType: 'struct OpportunityV2.DexDescriptor',
    name: '_dexDescriptor',
    type: 'tuple'
  }, {
    internalType: 'uint256',
    name: '_addLiquidityDeadline',
    type: 'uint256'
  }],
  name: 'investByTokenB',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountAMin',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountBMin',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256'
    }, {
      internalType: 'address payable',
      name: 'receiverAccount',
      type: 'address'
    }],
    internalType: 'struct OpportunityV2.RemoveLiqDescriptor',
    name: '_removeLiqDescriptor',
    type: 'tuple'
  }],
  name: 'leave',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pair',
  outputs: [{
    internalType: 'contract IERC20Upgradeable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pairFactoryContract',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pause',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'paused',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'proxiableUUID',
  outputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setAddLiquidityFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setAggregatorFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_coinWrapper',
    type: 'address'
  }],
  name: 'setCoinWrapper',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setDexFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address payable',
    name: '_feeTo',
    type: 'address'
  }],
  name: 'setFeeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_pairFactoryContract',
    type: 'address'
  }],
  name: 'setPairFactoryContract',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setRemoveLiquidityFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setStakeFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_tokenA',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_tokenB',
    type: 'address'
  }],
  name: 'setTokenAandTokenB',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setUnstakeFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'tokenA',
  outputs: [{
    internalType: 'contract IERC20Upgradeable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'tokenB',
  outputs: [{
    internalType: 'contract IERC20Upgradeable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'unpause',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }],
  name: 'upgradeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  name: 'upgradeToAndCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    internalType: 'address payable',
    name: '_receiver',
    type: 'address'
  }],
  name: 'withdrawFunds',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  stateMutability: 'payable',
  type: 'receive'
}];
