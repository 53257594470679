"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBalancesAndAllowances = exports.getBalances = void 0;
const utils_1 = require("ethers/lib/utils");
const utils_2 = require("./utils");
const bytecode_json_1 = require("./bytecode.json");
async function getBalances(provider_, tokens, account) {
  const provider = utils_2.toProvider(provider_);
  const inputData = utils_1.defaultAbiCoder.encode(['address[]', 'address'], [tokens, account]);
  const bytecode = bytecode_json_1.MultiTokenBalanceGetter.concat(inputData.slice(2));
  const encodedReturnData = await provider.call({
    data: bytecode
  });
  const [blockNumber, decodedReturnData] = utils_1.defaultAbiCoder.decode(['uint256', 'uint256[]'], encodedReturnData);
  const balances = {};
  for (let i = 0; i < tokens.length; i++) {
    balances[tokens[i]] = decodedReturnData[i];
  }
  return [blockNumber.toNumber(), balances];
}
exports.getBalances = getBalances;
async function getBalancesAndAllowances(provider_, tokens, owner, spender) {
  const provider = utils_2.toProvider(provider_);
  const inputData = utils_1.defaultAbiCoder.encode(['address[]', 'address', 'address'], [tokens, owner, spender]);
  const bytecode = bytecode_json_1.MultiTokenBalanceAndAllowanceGetter.concat(inputData.slice(2));
  const encodedReturnData = await provider.call({
    data: bytecode
  });
  const [blockNumber, decodedReturnData] = utils_1.defaultAbiCoder.decode(['uint256', 'uint256[2][]'], encodedReturnData);
  const balancesAndAllowances = {};
  for (let i = 0; i < tokens.length; i++) {
    const [balance, allowance] = decodedReturnData[i];
    balancesAndAllowances[tokens[i]] = {
      balance,
      allowance
    };
  }
  return [blockNumber.toNumber(), balancesAndAllowances];
}
exports.getBalancesAndAllowances = getBalancesAndAllowances;