"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.YOUR_INVEST = void 0;
exports.YOUR_INVEST = [{
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'hasStaked',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }],
  name: 'getUserTotalStakedAmount',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }],
  name: 'getUserStakingRecords',
  outputs: [{
    components: [{
      internalType: 'uint256',
      name: 'id',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'planId',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'reward',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'paidAmount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'startTime',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'endTime',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'lastWithdrawalTime',
      type: 'uint256'
    }, {
      internalType: 'bool',
      name: 'archived',
      type: 'bool'
    }],
    internalType: 'struct LockableStakingRewards.Stake[]',
    name: '',
    type: 'tuple[]'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_account',
    type: 'address'
  }],
  name: 'balanceOf',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_account',
    type: 'address'
  }],
  name: 'earned',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'getReserves',
  outputs: [{
    internalType: 'uint112',
    name: '_reserve0',
    type: 'uint112'
  }, {
    internalType: 'uint112',
    name: '_reserve1',
    type: 'uint112'
  }, {
    internalType: 'uint32',
    name: '_blockTimestampLast',
    type: 'uint32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'eligibleUsers',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'totalSupply',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_account',
    type: 'address'
  }],
  name: 'getBalance',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_account',
    type: 'address'
  }],
  name: 'getReward',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_account',
    type: 'address'
  }],
  name: 'getStakeTime',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }],
  name: 'getUserInfo',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'balance',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'lpStakeholders',
  outputs: [{
    internalType: 'uint256',
    name: 'userRewardPerTokenPaid',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'lpRewards',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'userLpBalance',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: 'exist',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }],
  name: 'getUserInfo',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'stakeholders',
  outputs: [{
    internalType: 'uint256',
    name: 'balance',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'stakeTime',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: 'exist',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'userInfo',
  outputs: [{
    internalType: 'uint256',
    name: 'lpBalance',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'distributedReward',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: 'exist',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'rewardPerHour',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'unstakeFee',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'investorIndex',
  outputs: [{
    internalType: 'uint256',
    name: 'investorShare',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'released',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'balance',
    type: 'uint256'
  }, {
    internalType: 'enum PresaleTypes.VestingType',
    name: 'vestingType',
    type: 'uint8'
  }, {
    internalType: 'enum PresaleTypes.InvestorState',
    name: 'state',
    type: 'uint8'
  }, {
    internalType: 'bool',
    name: 'exist',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}];
