"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNISWAP_V3 = void 0;
exports.UNISWAP_V3 = [{
  inputs: [{
    internalType: 'address',
    name: '_factory',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_WETH9',
    type: 'address'
  }],
  stateMutability: 'nonpayable',
  type: 'constructor'
}, {
  inputs: [],
  name: 'WETH9',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'bytes',
      name: 'path',
      type: 'bytes'
    }, {
      internalType: 'address',
      name: 'recipient',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountIn',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountOutMinimum',
      type: 'uint256'
    }],
    internalType: 'struct ISwapRouter.ExactInputParams',
    name: 'params',
    type: 'tuple'
  }],
  name: 'exactInput',
  outputs: [{
    internalType: 'uint256',
    name: 'amountOut',
    type: 'uint256'
  }],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'address',
      name: 'tokenIn',
      type: 'address'
    }, {
      internalType: 'address',
      name: 'tokenOut',
      type: 'address'
    }, {
      internalType: 'uint24',
      name: 'fee',
      type: 'uint24'
    }, {
      internalType: 'address',
      name: 'recipient',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountIn',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountOutMinimum',
      type: 'uint256'
    }, {
      internalType: 'uint160',
      name: 'sqrtPriceLimitX96',
      type: 'uint160'
    }],
    internalType: 'struct ISwapRouter.ExactInputSingleParams',
    name: 'params',
    type: 'tuple'
  }],
  name: 'exactInputSingle',
  outputs: [{
    internalType: 'uint256',
    name: 'amountOut',
    type: 'uint256'
  }],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'bytes',
      name: 'path',
      type: 'bytes'
    }, {
      internalType: 'address',
      name: 'recipient',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountOut',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountInMaximum',
      type: 'uint256'
    }],
    internalType: 'struct ISwapRouter.ExactOutputParams',
    name: 'params',
    type: 'tuple'
  }],
  name: 'exactOutput',
  outputs: [{
    internalType: 'uint256',
    name: 'amountIn',
    type: 'uint256'
  }],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'address',
      name: 'tokenIn',
      type: 'address'
    }, {
      internalType: 'address',
      name: 'tokenOut',
      type: 'address'
    }, {
      internalType: 'uint24',
      name: 'fee',
      type: 'uint24'
    }, {
      internalType: 'address',
      name: 'recipient',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountOut',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountInMaximum',
      type: 'uint256'
    }, {
      internalType: 'uint160',
      name: 'sqrtPriceLimitX96',
      type: 'uint160'
    }],
    internalType: 'struct ISwapRouter.ExactOutputSingleParams',
    name: 'params',
    type: 'tuple'
  }],
  name: 'exactOutputSingle',
  outputs: [{
    internalType: 'uint256',
    name: 'amountIn',
    type: 'uint256'
  }],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [],
  name: 'factory',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bytes[]',
    name: 'data',
    type: 'bytes[]'
  }],
  name: 'multicall',
  outputs: [{
    internalType: 'bytes[]',
    name: 'results',
    type: 'bytes[]'
  }],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [],
  name: 'refundETH',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'value',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'deadline',
    type: 'uint256'
  }, {
    internalType: 'uint8',
    name: 'v',
    type: 'uint8'
  }, {
    internalType: 'bytes32',
    name: 'r',
    type: 'bytes32'
  }, {
    internalType: 'bytes32',
    name: 's',
    type: 'bytes32'
  }],
  name: 'selfPermit',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'nonce',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'expiry',
    type: 'uint256'
  }, {
    internalType: 'uint8',
    name: 'v',
    type: 'uint8'
  }, {
    internalType: 'bytes32',
    name: 'r',
    type: 'bytes32'
  }, {
    internalType: 'bytes32',
    name: 's',
    type: 'bytes32'
  }],
  name: 'selfPermitAllowed',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'nonce',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'expiry',
    type: 'uint256'
  }, {
    internalType: 'uint8',
    name: 'v',
    type: 'uint8'
  }, {
    internalType: 'bytes32',
    name: 'r',
    type: 'bytes32'
  }, {
    internalType: 'bytes32',
    name: 's',
    type: 'bytes32'
  }],
  name: 'selfPermitAllowedIfNecessary',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'value',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'deadline',
    type: 'uint256'
  }, {
    internalType: 'uint8',
    name: 'v',
    type: 'uint8'
  }, {
    internalType: 'bytes32',
    name: 'r',
    type: 'bytes32'
  }, {
    internalType: 'bytes32',
    name: 's',
    type: 'bytes32'
  }],
  name: 'selfPermitIfNecessary',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amountMinimum',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: 'recipient',
    type: 'address'
  }],
  name: 'sweepToken',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amountMinimum',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: 'recipient',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'feeBips',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: 'feeRecipient',
    type: 'address'
  }],
  name: 'sweepTokenWithFee',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'int256',
    name: 'amount0Delta',
    type: 'int256'
  }, {
    internalType: 'int256',
    name: 'amount1Delta',
    type: 'int256'
  }, {
    internalType: 'bytes',
    name: '_data',
    type: 'bytes'
  }],
  name: 'uniswapV3SwapCallback',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'amountMinimum',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: 'recipient',
    type: 'address'
  }],
  name: 'unwrapWETH9',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'amountMinimum',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: 'recipient',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'feeBips',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: 'feeRecipient',
    type: 'address'
  }],
  name: 'unwrapWETH9WithFee',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  stateMutability: 'payable',
  type: 'receive'
}];
