import { numberToHex } from '../../utils/encoding/toHex.js';
import { formatProof } from '../../utils/formatters/proof.js';
/**
 * Returns the account and storage values of the specified account including the Merkle-proof.
 *
 * - Docs: https://viem.sh/docs/actions/public/getProof
 * - JSON-RPC Methods:
 *   - Calls [`eth_getProof`](https://eips.ethereum.org/EIPS/eip-1186)
 *
 * @param client - Client to use
 * @param parameters - {@link GetProofParameters}
 * @returns Proof data. {@link GetProofReturnType}
 *
 * @example
 * import { createPublicClient, http } from 'viem'
 * import { mainnet } from 'viem/chains'
 * import { getProof } from 'viem/public'
 *
 * const client = createPublicClient({
 *   chain: mainnet,
 *   transport: http(),
 * })
 * const block = await getProof(client, {
 *  address: '0x...',
 *  storageKeys: ['0x...'],
 * })
 */
export async function getProof(client, {
  address,
  blockNumber,
  blockTag: blockTag_,
  storageKeys
}) {
  const blockTag = blockTag_ ?? 'latest';
  const blockNumberHex = blockNumber !== undefined ? numberToHex(blockNumber) : undefined;
  const proof = await client.request({
    method: 'eth_getProof',
    params: [address, storageKeys, blockNumberHex || blockTag]
  });
  return formatProof(proof);
}
