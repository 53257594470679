"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bscFactoryPools = exports.bscCoins = exports.avalancheFactoryPools = exports.avalancheCoins = exports.polygonFactoryPools = exports.polygonPools = exports.polygonCoins = exports.ethereumPools = exports.ethereumCoin = exports.swap = void 0;
const type = {
  USD: 'usd',
  EUR: 'eur',
  BTC: 'btc',
  ETH: 'eth',
  FTM: 'ftm',
  MATIC: 'matic',
  XDAI: 'xdai',
  AVAX: 'avax',
  ONE: 'one',
  LINK: 'link',
  GOLD: 'gold',
  CRV: 'crv',
  CVX: 'cvx',
  SPELL: 'spell',
  T: 't',
  CRYPTO: 'crypto',
  OTHER: 'other'
};
exports.swap = {
  compound: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 12e5;
    }
  },
  usdt: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 12e5;
    }
  },
  iearn: {
    exchange: function () {
      return 8e5;
    },
    exchange_underlying: function () {
      return 16e5;
    }
  },
  busd: {
    exchange: function () {
      return 8e5;
    },
    exchange_underlying: function () {
      return 16e5;
    }
  },
  susd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 12e5;
    }
  },
  susdv2: {
    exchange: function (e, t) {
      return 3 == e || 3 == t ? 1e6 : 3e5;
    },
    exchange_underlying: function (e, t) {
      return 3 == e || 3 == t ? 1e6 : 3e5;
    }
  },
  pax: {
    exchange: function () {
      return 8e5;
    },
    exchange_underlying: function () {
      return 16e5;
    }
  },
  ren: {
    exchange: function () {
      return 3e5;
    },
    exchange_underlying: function () {
      return 3e5;
    }
  },
  sbtc: {
    exchange: function (e, t) {
      return 2 == e || 2 == t ? 1e6 : 3e5;
    },
    exchange_underlying: function (e, t) {
      return 2 == e || 2 == t ? 1e6 : 3e5;
    }
  },
  hbtc: {
    exchange: function () {
      return 3e5;
    },
    exchange_underlying: function () {
      return 3e5;
    }
  },
  '3pool': {
    exchange: function () {
      return 3e5;
    },
    exchange_underlying: function () {
      return 3e5;
    }
  },
  gusd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  husd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  usdk: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  usdn: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  linkusd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  musd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  rsv: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  tbtc: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  dusd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  pbtc: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  bbtc: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  obtc: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  ust: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  eurs: {
    exchange: function () {
      return 1e6;
    },
    exchange_underlying: function () {
      return 1e6;
    }
  },
  eurt: {
    exchange: function () {
      return 1e6;
    },
    exchange_underlying: function () {
      return 1e6;
    }
  },
  seth: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  aave: {
    exchange: function () {
      return 8e5;
    },
    exchange_underlying: function () {
      return 16e5;
    }
  },
  geist: {
    exchange: function () {
      return 8e5;
    },
    exchange_underlying: function () {
      return 16e5;
    }
  },
  idle: {
    exchange: function () {
      return 8e5;
    },
    exchange_underlying: function () {
      return 16e5;
    }
  },
  steth: {
    exchange: function () {
      return 3e5;
    },
    exchange_underlying: function () {
      return 3e5;
    }
  },
  saave: {
    exchange: function () {
      return 8e5;
    },
    exchange_underlying: function () {
      return 16e5;
    }
  },
  ankreth: {
    exchange: function () {
      return 3e5;
    },
    exchange_underlying: function () {
      return 3e5;
    }
  },
  usdp: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  ib: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 12e5;
    }
  },
  link: {
    exchange: function () {
      return 3e5;
    },
    exchange_underlying: function () {
      return 3e5;
    }
  },
  '2pool': {
    exchange: function () {
      return 3e5;
    },
    exchange_underlying: function () {
      return 3e5;
    }
  },
  fusdt: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  tusd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  frax: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  lusd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  factory: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  busdv2: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  reth: {
    exchange: function () {
      return 3e5;
    },
    exchange_underlying: function () {
      return 3e5;
    }
  },
  alusd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  rai: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  tricrypto: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  tricrypto2: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  atricrypto: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  atricrypto2: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  eursusd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  crveth: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  cvxeth: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  teth: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  spelleth: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  eurtusd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  xautusd: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  atricrypto3: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  },
  mim: {
    exchange: function () {
      return 6e5;
    },
    exchange_underlying: function () {
      return 6e5;
    }
  }
};
exports.ethereumCoin = {
  dai: {
    id: 'dai',
    type: 'usd',
    symbol: 'DAI',
    decimals: 1000000000000000000,
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'dai',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'dai'
  },
  cdai: {
    id: 'cdai',
    type: 'usd',
    symbol: 'cDAI',
    decimals: 100000000,
    address: '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'compound',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'cdai'
  },
  ydai: {
    id: 'ydai',
    type: 'usd',
    symbol: 'yDAI',
    decimals: 1000000000000000000,
    address: '0x16de59092dae5ccf4a1e6439d611fd0653f0bd01',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'ydai'
  },
  adai: {
    id: 'adai',
    type: 'usd',
    symbol: 'aDAI',
    decimals: 1000000000000000000,
    address: '0x028171bCA77440897B824Ca71D1c56caC55b68A3',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'aave',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'adai'
  },
  ycdai: {
    id: 'ycdai',
    type: 'usd',
    symbol: 'ycDAI',
    decimals: 1000000000000000000,
    address: '0x99d1Fa417f94dcD62BfE781a1213c092a47041Bc',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn-fork',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'ycdai'
  },
  cydai: {
    id: 'cydai',
    type: 'usd',
    symbol: 'cyDAI',
    decimals: 100000000,
    address: '0x8e595470Ed749b85C6F7669de83EAe304C2ec68F',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn-cream',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'cydai'
  },
  usdc: {
    id: 'usdc',
    type: 'usd',
    symbol: 'USDC',
    decimals: 1000000,
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'usd-coin',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'usd-coin'
  },
  cusdc: {
    id: 'cusdc',
    type: 'usd',
    symbol: 'cUSDC',
    decimals: 100000000,
    address: '0x39aa39c021dfbae8fac545936693ac917d5e7563',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'compound',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'cusdc'
  },
  yusdc: {
    id: 'yusdc',
    type: 'usd',
    symbol: 'yUSDC',
    decimals: 1000000,
    address: '0xd6ad7a6750a7593e092a9b218d66c0a814a3436e',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'yusdc'
  },
  ausdc: {
    id: 'ausdc',
    type: 'usd',
    symbol: 'aUSDC',
    decimals: 1000000,
    address: '0xBcca60bB61934080951369a648Fb03DF4F96263C',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'aave',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'ausdc'
  },
  ycusdc: {
    id: 'ycusdc',
    type: 'usd',
    symbol: 'ycUSDC',
    decimals: 1000000,
    address: '0x9777d7E2b60bB01759D0E2f8be2095df444cb07E',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn-fork',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'ycusdc'
  },
  cyusdc: {
    id: 'cyusdc',
    type: 'usd',
    symbol: 'cyUSDC',
    decimals: 100000000,
    address: '0x76Eb2FE28b36B3ee97F3Adae0C69606eeDB2A37c',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn-cream',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'cyusdc'
  },
  usdt: {
    id: 'usdt',
    type: 'usd',
    symbol: 'USDT',
    decimals: 1000000,
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'tether',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'tether'
  },
  yusdt: {
    id: 'yusdt',
    type: 'usd',
    symbol: 'yUSDT',
    decimals: 1000000,
    address: '0x83f798e925bcd4017eb265844fddabb448f1707d',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'yusdt'
  },
  ausdt: {
    id: 'ausdt',
    type: 'usd',
    symbol: 'aUSDT',
    decimals: 1000000,
    address: '0x3Ed3B47Dd13EC9a98b44e6204A523E766B225811',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'aave',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'ausdt'
  },
  ycusdt: {
    id: 'ycusdt',
    type: 'usd',
    symbol: 'ycUSDT',
    decimals: 1000000,
    address: '0x1bE5d71F2dA660BFdee8012dDc58D024448A0A59',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn-fork',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'ycusdt'
  },
  cyusdt: {
    id: 'cyusdt',
    type: 'usd',
    symbol: 'cyUSDT',
    decimals: 100000000,
    address: '0x48759F220ED983dB51fA7A8C0D2AAb8f3ce4166a',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn-cream',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'cyusdt'
  },
  tusd: {
    id: 'tusd',
    type: 'usd',
    symbol: 'TUSD',
    decimals: 1000000000000000000,
    address: '0x0000000000085d4780B73119b644AE5ecd22b376',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'tusd'
  },
  ytusd: {
    id: 'ytusd',
    type: 'usd',
    symbol: 'yTUSD',
    decimals: 1000000000000000000,
    address: '0x73a052500105205d34daf004eab301916da8190f',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'ytusd'
  },
  busd: {
    id: 'busd',
    type: 'usd',
    symbol: 'BUSD',
    decimals: 1000000000000000000,
    address: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'busd'
  },
  ybusd: {
    id: 'ybusd',
    type: 'usd',
    symbol: 'yBUSD',
    decimals: 1000000000000000000,
    address: '0x04bC0Ab673d88aE9dbC9DA2380cB6B79C4BCa9aE',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'iearn',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'ybusd'
  },
  susd: {
    id: 'susd',
    type: 'usd',
    symbol: 'sUSD',
    decimals: 1000000000000000000,
    address: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
    isSynth: true,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'susd'
  },
  asusd: {
    id: 'asusd',
    type: 'usd',
    symbol: 'aSUSD',
    decimals: 1000000000000000000,
    address: '0x6C5024Cd4F8A59110119C56f8933403A539555EB',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: 'aave',
    isTradable: true,
    isWrappedCoin: true,
    isFiat: true,
    getCoingeckoId: 'asusd'
  },
  pax: {
    id: 'pax',
    type: 'usd',
    symbol: 'USDPAX',
    decimals: 1000000000000000000,
    address: '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'pax'
  },
  renbtc: {
    id: 'renbtc',
    type: 'btc',
    symbol: 'renBTC',
    decimals: 100000000,
    address: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'renbtc'
  },
  wbtc: {
    id: 'wbtc',
    type: 'btc',
    symbol: 'wBTC',
    decimals: 100000000,
    address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'wrapped-bitcoin',
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'wrapped-bitcoin'
  },
  sbtc: {
    id: 'sbtc',
    type: 'btc',
    symbol: 'sBTC',
    decimals: 1000000000000000000,
    address: '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6',
    isSynth: true,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'sbtc'
  },
  hbtc: {
    id: 'hbtc',
    type: 'btc',
    symbol: 'HBTC',
    decimals: 1000000000000000000,
    address: '0x0316EB71485b0Ab14103307bf65a021042c6d380',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'hbtc'
  },
  gusd: {
    id: 'gusd',
    type: 'usd',
    symbol: 'GUSD',
    decimals: 100,
    address: '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'gusd'
  },
  husd: {
    id: 'husd',
    type: 'usd',
    symbol: 'HUSD',
    decimals: 100000000,
    address: '0xdf574c24545e5ffecb9a659c229253d4111d87e1',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'husd'
  },
  usdk: {
    id: 'usdk',
    type: 'usd',
    symbol: 'USDK',
    decimals: 1000000000000000000,
    address: '0x1c48f86ae57291f7686349f12601910bd8d470bb',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'usdk'
  },
  usdn: {
    id: 'usdn',
    type: 'usd',
    symbol: 'USDN',
    decimals: 1000000000000000000,
    address: '0x674C6Ad92Fd080e4004b2312b45f796a192D27a0',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'usdn'
  },
  linkusd: {
    id: 'linkusd',
    type: 'usd',
    symbol: 'LINKUSD',
    decimals: 1000000000000000000,
    address: '0x0E2EC54fC0B509F445631Bf4b91AB8168230C752',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'linkusd'
  },
  musd: {
    id: 'musd',
    type: 'usd',
    symbol: 'mUSD',
    decimals: 1000000000000000000,
    address: '0xe2f2a5c287993345a840db3b0845fbc70f5935a5',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'musd'
  },
  usd: {
    id: 'usd',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: false,
    coingeckoId: 'dollar',
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'dollar'
  },
  btc: {
    id: 'usd',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: false,
    coingeckoId: 'wrapped-bitcoin',
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'wrapped-bitcoin'
  },
  rsv: {
    id: 'rsv',
    type: 'usd',
    symbol: 'RSV',
    decimals: 1000000000000000000,
    address: '0x196f4727526eA7FB1e17b2071B3d8eAA38486988',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'rsv'
  },
  tbtc: {
    id: 'tbtc',
    type: 'btc',
    symbol: 'tBTC',
    decimals: 1000000000000000000,
    address: '0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'tbtc'
  },
  dusd: {
    id: 'dusd',
    type: 'usd',
    symbol: 'DUSD',
    decimals: 1000000000000000000,
    address: '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'dusd'
  },
  pbtc: {
    id: 'pbtc',
    type: 'btc',
    symbol: 'pBTC',
    decimals: 1000000000000000000,
    address: '0x5228a22e72ccc52d415ecfd199f99d0665e7733b',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'pbtc'
  },
  bbtc: {
    id: 'bbtc',
    type: 'btc',
    symbol: 'BBTC',
    decimals: 100000000,
    address: '0x9be89d2a4cd102d8fecc6bf9da793be995c22541',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'bbtc'
  },
  obtc: {
    id: 'obtc',
    type: 'btc',
    symbol: 'oBTC',
    decimals: 1000000000000000000,
    address: '0x8064d9Ae6cDf087b1bcd5BDf3531bD5d8C537a68',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'obtc'
  },
  ust: {
    id: 'ust',
    type: 'usd',
    symbol: 'UST',
    decimals: 1000000000000000000,
    address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'ust'
  },
  eurs: {
    id: 'eurs',
    type: 'eur',
    symbol: 'EURs',
    decimals: 100,
    address: '0xdb25f211ab05b1c97d595516f45794528a807ad8',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'stasis-eurs',
    check_market: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'stasis-eurs'
  },
  seur: {
    id: 'seur',
    type: 'eur',
    symbol: 'sEUR',
    decimals: 1000000000000000000,
    address: '0xd71ecff9342a5ced620049e616c5035f1db98620',
    isSynth: true,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    check_market: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'seur'
  },
  eth: {
    id: 'eth',
    type: 'eth',
    symbol: 'ETH',
    decimals: 1000000000000000000,
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'eth'
  },
  seth: {
    id: 'seth',
    type: 'eth',
    symbol: 'sETH',
    decimals: 1000000000000000000,
    address: '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb',
    isSynth: true,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'seth'
  },
  steth: {
    id: 'steth',
    type: 'eth',
    symbol: 'stETH',
    decimals: 1000000000000000000,
    address: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'steth'
  },
  ankreth: {
    id: 'ankreth',
    type: 'eth',
    symbol: 'ankrETH',
    decimals: 1000000000000000000,
    address: '0xe95a203b1a91a908f9b9ce46459d101078c2c3cb',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'ankreth'
  },
  usdp: {
    id: 'usdp',
    type: 'usd',
    symbol: 'USDP',
    decimals: 1000000000000000000,
    address: '0x1456688345527bE1f37E9e627DA0837D6f08C925',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'usdp'
  },
  link: {
    id: 'link',
    type: 'link',
    symbol: 'LINK',
    decimals: 1000000000000000000,
    address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'link'
  },
  slink: {
    id: 'slink',
    type: 'link',
    symbol: 'sLINK',
    decimals: 1000000000000000000,
    address: '0xbbc455cb4f1b9e4bfc4b73970d360c8f032efee6',
    isSynth: true,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'slink'
  },
  tricrv: {
    id: 'tricrv',
    type: 'usd',
    symbol: '3Crv',
    decimals: 1000000000000000000,
    address: '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490',
    isSynth: false,
    isLpToken: true,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'lp-3pool-curve',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'lp-3pool-curve'
  },
  sbtccrv: {
    id: 'sbtccrv',
    type: 'btc',
    symbol: 'sbtcCrv',
    decimals: 1000000000000000000,
    address: '0x075b1bb99792c9E1041bA13afEf80C91a1e70fB3',
    isSynth: false,
    isLpToken: true,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'sbtccrv'
  },
  frax: {
    id: 'frax',
    type: 'usd',
    symbol: 'FRAX',
    decimals: 1000000000000000000,
    address: '0x853d955acef822db058eb8505911ed77f175b99e',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'frax'
  },
  lusd: {
    id: 'lusd',
    type: 'usd',
    symbol: 'LUSD',
    decimals: 1000000000000000000,
    address: '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'lusd'
  },
  reth: {
    id: 'reth',
    type: 'eth',
    symbol: 'rETH',
    decimals: 1000000000000000000,
    address: '0x9559aaa82d9649c7a7b220e7c461d2e74c9a3593',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'reth'
  },
  alusd: {
    id: 'alusd',
    type: 'usd',
    symbol: 'alUSD',
    decimals: 1000000000000000000,
    address: '0xbc6da0fe9ad5f3b0d58160288917aa56653660e9',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'alusd'
  },
  weth: {
    id: 'weth',
    type: 'eth',
    symbol: 'WETH',
    decimals: 1000000000000000000,
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'weth',
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'weth'
  },
  mim: {
    id: 'mim',
    type: 'usd',
    symbol: 'MIM',
    decimals: 1000000000000000000,
    address: '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'mim'
  },
  crv: {
    id: 'crv',
    type: 'crv',
    symbol: 'CRV',
    decimals: 1000000000000000000,
    address: '0xd533a949740bb3306d119cc777fa900ba034cd52',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'curve-dao-token',
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'curve-dao-token'
  },
  eurt: {
    id: 'eurt',
    type: 'eur',
    symbol: 'EURT',
    decimals: 1000000,
    address: '0xC581b735A1688071A1746c968e0798D642EDE491',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'tether-eurt',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'tether-eurt'
  },
  rai: {
    id: 'rai',
    type: 'other',
    symbol: 'RAI',
    decimals: 1000000000000000000,
    address: '0x03ab458634910aad20ef5f1c8ee96f1d6ac54919',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'rai',
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'rai'
  },
  cvx: {
    id: 'cvx',
    type: 'cvx',
    symbol: 'CVX',
    decimals: 1000000000000000000,
    address: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'convex-finance',
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'convex-finance'
  },
  xaut: {
    id: 'xaut',
    type: 'gold',
    symbol: 'XAUt',
    decimals: 1000000,
    address: '0x68749665ff8d2d112fa859aa293f07a622782f38',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'tether-gold',
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'tether-gold'
  },
  spell: {
    id: 'spell',
    type: 'spell',
    symbol: 'SPELL',
    decimals: 1000000000000000000,
    address: '0x090185f2135308bad17527004364ebcc2d37e5f6',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'spell-token',
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'spell-token'
  },
  t: {
    id: 't',
    type: 't',
    symbol: 'T',
    decimals: 1000000000000000000,
    address: '0xCdF7028ceAB81fA0C6971208e83fa7872994beE5',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'threshold-network-token',
    isWrappedCoin: false,
    isFiat: false,
    getCoingeckoId: 'threshold-network-token'
  }
};
exports.ethereumPools = [{
  dataIndex: 0,
  id: 'compound',
  name: 'Compound',
  pageMetaData: {
    title: 'Compounded',
    description: 'A curve.fi portal for swapping cDAI/cUSDC'
  },
  lpTokenInfo: {
    name: 'cCurve',
    symbol: 'cCrv'
  },
  coingeckoInfo: {
    id: 'compound',
    symbol: 'COMP',
    referenceAssetId: 'dollar'
  },
  assets: 'cDAI+cUSDC',
  coins: [exports.ethereumCoin.cdai, exports.ethereumCoin.cusdc],
  underlyingCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc],
  isLendingPool: !0,
  addresses: {
    swap: '0xA2B47E3D5c44877cca798226B7B8118F9BFb7A56',
    lpToken: '0x845838DF265Dcd2c412A1Dc9e959c7d08537f8a2',
    gauge: '0x7ca5b0a2910B33e9759DC7dDB0413949071D7575',
    deposit: '0xeB21209ae4C2c9FF2a86ACA31E123764A3B6Bc06'
  },
  hasAMultiplier: !1,
  isOldPool: !0
}, {
  dataIndex: 1,
  id: 'usdt',
  name: 'USDT',
  pageMetaData: {
    title: 'Tethered',
    description: 'A curve.fi Tethered portal for swapping cDAI/cUSDC/USDT'
  },
  lpTokenInfo: {
    name: 'tCurve',
    symbol: 'tCrv'
  },
  coingeckoInfo: {
    id: 'tether',
    symbol: 'USDT',
    referenceAssetId: 'dollar'
  },
  assets: 'cDAI+cUSDC+USDT',
  coins: [exports.ethereumCoin.cdai, exports.ethereumCoin.cusdc, exports.ethereumCoin.usdt],
  underlyingCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  isLendingPool: !0,
  addresses: {
    swap: '0x52EA46506B9CC5Ef470C5bf89f17Dc28bB35D85C',
    lpToken: '0x9fC689CCaDa600B6DF723D9E47D84d76664a1F23',
    gauge: '0xBC89cd85491d81C6AD2954E6d0362Ee29fCa8F53',
    deposit: '0xac795D2c97e60DF6a99ff1c814727302fD747a80'
  },
  hasAMultiplier: !1,
  isOldPool: !0
}, {
  dataIndex: 5,
  id: 'pax',
  name: 'PAX',
  lpTokenInfo: {
    name: 'pCurve',
    symbol: 'pCrv'
  },
  coingeckoInfo: {
    id: 'paxos-standard',
    symbol: 'PAX',
    referenceAssetId: 'dollar'
  },
  assets: 'ycDAI+ycUSDC+ycUSDT+PAX',
  coins: [exports.ethereumCoin.ycdai, exports.ethereumCoin.ycusdc, exports.ethereumCoin.ycusdt, exports.ethereumCoin.pax],
  underlyingCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt, exports.ethereumCoin.pax],
  isLendingPool: !0,
  addresses: {
    swap: '0x06364f10B501e868329afBc005b3492902d6C763',
    lpToken: '0xD905e2eaeBe188fc92179b6350807D8bd91Db0D8',
    gauge: '0x64E3C23bfc40722d3B649844055F1D51c1ac041d',
    deposit: '0xA50cCc70b6a011CffDdf45057E39679379187287'
  },
  hasAMultiplier: !1,
  isOldPool: !0
}, {
  dataIndex: 2,
  id: 'iearn',
  idAlias: 'y',
  name: 'Y',
  pageMetaData: {
    title: 'Yield',
    description: 'A curve.fi yTokens portal for swapping DAI/USDC/USDT/TUSD'
  },
  lpTokenInfo: {
    name: 'yCurve',
    symbol: 'yCrv'
  },
  coingeckoInfo: {
    id: 'yearn-finance',
    symbol: 'YFI',
    referenceAssetId: 'dollar'
  },
  assets: 'yDAI+yUSDC+yUSDT+yTUSD',
  coins: [exports.ethereumCoin.ydai, exports.ethereumCoin.yusdc, exports.ethereumCoin.yusdt, exports.ethereumCoin.ytusd],
  underlyingCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt, exports.ethereumCoin.tusd],
  isLendingPool: !0,
  addresses: {
    swap: '0x45F783CCE6B7FF23B2ab2D70e416cdb7D6055f51',
    lpToken: '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
    gauge: '0xFA712EE4788C042e2B7BB55E6cb8ec569C4530c1',
    deposit: '0xbBC81d23Ea2c3ec7e56D39296F0cbB648873a5d3',
    stakingRewards: '0x0001FB050Fe7312791bF6475b96569D83F695C9f'
  },
  hasAMultiplier: !1,
  isOldPool: !0
}, {
  dataIndex: 3,
  id: 'busd',
  name: 'BUSD',
  pageMetaData: {
    title: 'bUSD',
    description: 'A curve.fi portal for swapping BUSD and other stablecoins'
  },
  lpTokenInfo: {
    name: 'bCurve',
    symbol: 'bCrv'
  },
  coingeckoInfo: {
    id: 'binance-usd',
    symbol: 'BUSD',
    referenceAssetId: 'dollar'
  },
  assets: 'yDAI+yUSDC+yUSDT+yBUSD',
  coins: [exports.ethereumCoin.ydai, exports.ethereumCoin.yusdc, exports.ethereumCoin.yusdt, exports.ethereumCoin.ybusd],
  underlyingCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt, exports.ethereumCoin.busd],
  isLendingPool: !0,
  addresses: {
    swap: '0x79a8C46DeA5aDa233ABaFFD40F3A0A2B1e5A4F27',
    lpToken: '0x3B3Ac5386837Dc563660FB6a0937DFAa5924333B',
    gauge: '0x69Fb7c45726cfE2baDeE8317005d3F94bE838840',
    deposit: '0xb6c057591E073249F2D9D88Ba59a46CFC9B59EdB'
  },
  hasAMultiplier: !1,
  isOldPool: !0
}, {
  dataIndex: 4,
  id: 'susdv2',
  name: 'sUSD',
  lpTokenInfo: {
    name: 'sCurve',
    symbol: 'sCrv'
  },
  coingeckoInfo: {
    id: 'nusd',
    symbol: 'SUSD',
    referenceAssetId: 'dollar'
  },
  assets: 'DAI+USDC+USDT+sUSD',
  coins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt, exports.ethereumCoin.susd],
  additionalRewards: [{
    name: 'SNX',
    amountDataKey: 'snxRewards',
    rewardTokenCoingeckoId: 'havven'
  }],
  addresses: {
    swap: '0xA5407eAE9Ba41422680e2e00537571bcC53efBfD',
    lpToken: '0xC25a3A3b969415c80451098fa907EC722572917F',
    gauge: '0xA90996896660DEcC6E997655E065b23788857849',
    deposit: '0xFCBa3E75865d2d561BE8D220616520c171F12851'
  },
  hasAMultiplier: !1,
  isOldPool: !0
}, {
  dataIndex: 7,
  id: 'ren',
  name: 'ren',
  lpTokenInfo: {
    name: 'renCurve',
    symbol: 'renCrv'
  },
  coingeckoInfo: {
    id: 'renbtc',
    symbol: 'RENBTC',
    referenceAssetId: 'bitcoin'
  },
  assets: 'renBTC+wBTC',
  coins: [exports.ethereumCoin.renbtc, exports.ethereumCoin.wbtc],
  referenceAsset: type.BTC,
  addresses: {
    swap: '0x93054188d876f558f4a66B2EF1d97d16eDf0895B',
    lpToken: '0x49849C98ae39Fff122806C06791Fa73784FB3675',
    gauge: '0xB1F2cdeC61db658F091671F5f199635aEF202CAC',
    adapter: '0x73aB2Bd10aD10F7174a1AD5AFAe3ce3D991C5047'
  },
  hasAMultiplier: !1
}, {
  dataIndex: 8,
  id: 'sbtc',
  name: 'sbtc',
  lpTokenInfo: {
    name: 'sbtcCurve',
    symbol: 'sbtcCrv'
  },
  coingeckoInfo: {
    id: 'sbtc',
    symbol: 'SBTC',
    referenceAssetId: 'bitcoin'
  },
  assets: 'renBTC+wBTC+sBTC',
  coins: [exports.ethereumCoin.renbtc, exports.ethereumCoin.wbtc, exports.ethereumCoin.sbtc],
  referenceAsset: type.BTC,
  oldAdditionalRewards: [{
    name: 'SNX',
    rewardTokenCoingeckoId: 'havven'
  }],
  addresses: {
    swap: '0x7fC77b5c7614E1533320Ea6DDc2Eb61fa00A9714',
    lpToken: '0x075b1bb99792c9E1041bA13afEf80C91a1e70fB3',
    gauge: '0x705350c4BcD35c9441419DdD5d2f097d7a55410F',
    stakingRewards: '0x13C1542A468319688B89E323fe9A3Be3A90EBb27',
    adapter: '0xAEade605D01FE9a8e9C4B3AA0130A90d62167029'
  },
  hasAMultiplier: !1
}, {
  dataIndex: 9,
  id: 'hbtc',
  name: 'hbtc',
  lpTokenInfo: {
    name: 'hbtcCurve',
    symbol: 'hbtcCrv'
  },
  coingeckoInfo: {
    id: 'huobi-btc',
    symbol: 'HBTC',
    referenceAssetId: 'bitcoin'
  },
  assets: 'hBTC+wBTC',
  coins: [exports.ethereumCoin.hbtc, exports.ethereumCoin.wbtc],
  referenceAsset: type.BTC,
  addresses: {
    swap: '0x4CA9b3063Ec5866A4B82E437059D2C43d1be596F',
    lpToken: '0xb19059ebb43466C323583928285a49f558E572Fd',
    gauge: '0x4c18E409Dc8619bFb6a1cB56D114C3f592E0aE79'
  },
  hasAMultiplier: !1
}, {
  dataIndex: 10,
  id: '3pool',
  name: '3pool',
  lpTokenInfo: {
    name: '3poolCurve',
    symbol: '3poolCrv'
  },
  assets: 'DAI+USDC+USDT',
  coins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  coingeckoInfo: {
    referenceAssetId: 'dollar'
  },
  addresses: {
    swap: '0xbebc44782c7db0a1a60cb6fe97d0b483032ff1c7',
    lpToken: '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490',
    gauge: '0xbFcF63294aD7105dEa65aA58F8AE5BE2D9d0952A'
  },
  hasAMultiplier: !1
}, {
  dataIndex: 11,
  id: 'gusd',
  name: 'gusd',
  lpTokenInfo: {
    name: 'gusdCurve',
    symbol: 'gusdCrv'
  },
  coingeckoInfo: {
    id: 'gemini-dollar',
    symbol: 'GUSD',
    referenceAssetId: 'dollar'
  },
  assets: 'GUSD+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.gusd, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0x4f062658EaAF2C1ccf8C8e36D6824CDf41167956',
    lpToken: '0xD2967f45c4f384DEEa880F807Be904762a3DeA07',
    gauge: '0xC5cfaDA84E902aD92DD40194f0883ad49639b023',
    deposit: '0x64448B78561690B70E17CBE8029a3e5c1bB7136e'
  }
}, {
  dataIndex: 12,
  id: 'husd',
  name: 'husd',
  lpTokenInfo: {
    name: 'husdCurve',
    symbol: 'husdCrv'
  },
  coingeckoInfo: {
    id: 'husd',
    symbol: 'HUSD',
    referenceAssetId: 'dollar'
  },
  assets: 'HUSD+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.husd, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0x3eF6A01A0f81D6046290f3e2A8c5b843e738E604',
    lpToken: '0x5B5CFE992AdAC0C9D48E05854B2d91C73a003858',
    gauge: '0x2db0E83599a91b508Ac268a6197b8B14F5e72840',
    deposit: '0x09672362833d8f703D5395ef3252D4Bfa51c15ca'
  }
}, {
  dataIndex: 13,
  id: 'usdk',
  name: 'usdk',
  lpTokenInfo: {
    name: 'usdkCurve',
    symbol: 'usdkCrv'
  },
  coingeckoInfo: {
    id: 'usdk',
    symbol: 'USDK',
    referenceAssetId: 'dollar'
  },
  assets: 'USDK+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.usdk, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0x3E01dD8a5E1fb3481F0F589056b428Fc308AF0Fb',
    lpToken: '0x97E2768e8E73511cA874545DC5Ff8067eB19B787',
    gauge: '0xC2b1DF84112619D190193E48148000e3990Bf627',
    deposit: '0xF1f85a74AD6c64315F85af52d3d46bF715236ADc'
  }
}, {
  dataIndex: 14,
  id: 'usdn',
  name: 'usdn',
  lpTokenInfo: {
    name: 'usdnCurve',
    symbol: 'usdnCrv'
  },
  coingeckoInfo: {
    id: 'neutrino',
    symbol: 'USDN',
    referenceAssetId: 'dollar'
  },
  assets: 'USDN+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.usdn, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0x0f9cb53Ebe405d49A0bbdBD291A65Ff571bC83e1',
    lpToken: '0x4f3E8F405CF5aFC05D68142F3783bDfE13811522',
    gauge: '0xF98450B5602fa59CC66e1379DFfB6FDDc724CfC4',
    deposit: '0x094d12e5b541784701FD8d65F11fc0598FBC6332'
  }
}, {
  dataIndex: 15,
  id: 'linkusd',
  name: 'linkusd',
  lpTokenInfo: {
    name: 'linkusdCurve',
    symbol: 'linkusdCrv'
  },
  coingeckoInfo: {
    id: 'linkusd',
    symbol: 'LINKUSD',
    referenceAssetId: 'dollar'
  },
  assets: 'LINKUSD+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.linkusd, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  isRiskier: !0,
  hasNoGauge: !0,
  addresses: {
    swap: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
    lpToken: '0x6D65b498cb23deAba52db31c93Da9BFFb340FB8F',
    deposit: '0x1de7f0866e2c4adAC7b457c58Cc25c8688CDa1f2'
  },
  riskLevel: 3
}, {
  dataIndex: 16,
  id: 'musd',
  name: 'musd',
  lpTokenInfo: {
    name: 'musdCurve',
    symbol: 'musdCrv'
  },
  coingeckoInfo: {
    id: 'musd',
    symbol: 'MUSD',
    referenceAssetId: 'dollar'
  },
  assets: 'musd+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.musd, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  additionalRewards: [{
    name: 'MTA',
    amountDataKey: 'mtaRewards',
    rewardTokenCoingeckoId: 'meta'
  }],
  addresses: {
    swap: '0x8474DdbE98F5aA3179B3B3F5942D724aFcdec9f6',
    lpToken: '0x1AEf73d49Dedc4b1778d0706583995958Dc862e6',
    gauge: '0x5f626c30EC1215f4EdCc9982265E8b1F411D1352',
    deposit: '0x803A2B40c5a9BB2B86DD630B274Fa2A9202874C2'
  }
}, {
  dataIndex: 17,
  id: 'rsv',
  name: 'rsv',
  lpTokenInfo: {
    name: 'rsvCurve',
    symbol: 'rsvCrv'
  },
  coingeckoInfo: {
    id: 'reserve',
    symbol: 'RSV',
    referenceAssetId: 'dollar'
  },
  assets: 'rsv+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.rsv, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  additionalRewards: [{
    name: 'RSR',
    amountDataKey: 'rsrRewards',
    rewardTokenCoingeckoId: 'reserve-rights-token'
  }],
  addresses: {
    swap: '0xC18cC39da8b11dA8c3541C598eE022258F9744da',
    lpToken: '0xC2Ee6b0334C261ED60C72f6054450b61B8f18E35',
    gauge: '0x4dC4A289a8E33600D8bD4cf5F6313E43a37adec7',
    deposit: '0xBE175115BF33E12348ff77CcfEE4726866A0Fbd5'
  }
}, {
  dataIndex: 18,
  id: 'tbtc',
  name: 'tbtc',
  lpTokenInfo: {
    name: 'tbtcCurve',
    symbol: 'tbtcCrv'
  },
  coingeckoInfo: {
    id: 'tbtc',
    symbol: 'TBTC',
    referenceAssetId: 'bitcoin'
  },
  assets: 'tbtc+sbtcCrv',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.tbtc, exports.ethereumCoin.sbtccrv],
  metaCoins: [exports.ethereumCoin.renbtc, exports.ethereumCoin.wbtc, exports.ethereumCoin.sbtc],
  referenceAsset: type.BTC,
  oldAdditionalRewards: [{
    name: 'KEEP',
    rewardTokenCoingeckoId: 'keep-network'
  }],
  addresses: {
    swap: '0xC25099792E9349C7DD09759744ea681C7de2cb66',
    lpToken: '0x64eda51d3Ad40D56b9dFc5554E06F94e1Dd786Fd',
    gauge: '0x6828bcF74279eE32f2723eC536c22c51Eed383C6',
    deposit: '0xaa82ca713D94bBA7A89CEAB55314F9EfFEdDc78c'
  }
}, {
  dataIndex: 19,
  id: 'dusd',
  name: 'dusd',
  lpTokenInfo: {
    name: 'dusdCurve',
    symbol: 'dusdCrv'
  },
  coingeckoInfo: {
    id: 'defidollar',
    symbol: 'DUSD',
    referenceAssetId: 'dollar'
  },
  assets: 'dusd+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.dusd, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  additionalRewards: [{
    name: 'DFD',
    amountDataKey: 'dfdRewards',
    rewardTokenCoingeckoId: 'defidollar-dao'
  }],
  addresses: {
    swap: '0x8038C01A0390a8c547446a0b2c18fc9aEFEcc10c',
    lpToken: '0x3a664Ab939FD8482048609f652f9a0B0677337B9',
    gauge: '0xAEA6c312f4b3E04D752946d329693F7293bC2e6D',
    deposit: '0x61E10659fe3aa93d036d099405224E4Ac24996d0'
  }
}, {
  dataIndex: 20,
  id: 'pbtc',
  name: 'pbtc',
  lpTokenInfo: {
    name: 'pbtcCurve',
    symbol: 'pbtcCrv'
  },
  coingeckoInfo: {
    id: 'ptokens-btc',
    symbol: 'PBTC',
    referenceAssetId: 'bitcoin'
  },
  assets: 'pbtc+sbtcCrv',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.pbtc, exports.ethereumCoin.sbtccrv],
  metaCoins: [exports.ethereumCoin.renbtc, exports.ethereumCoin.wbtc, exports.ethereumCoin.sbtc],
  referenceAsset: type.BTC,
  additionalRewards: [{
    name: 'PNT',
    amountDataKey: 'pntRewards',
    rewardTokenAddress: '0x89Ab32156e46F46D02ade3FEcbe5Fc4243B9AAeD',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'pnetwork'
  }],
  addresses: {
    swap: '0x7F55DDe206dbAD629C080068923b36fe9D6bDBeF',
    lpToken: '0xDE5331AC4B3630f94853Ff322B66407e0D6331E8',
    gauge: '0xd7d147c6Bb90A718c3De8C0568F9B560C79fa416',
    deposit: '0x11F419AdAbbFF8d595E7d5b223eee3863Bb3902C'
  },
  gaugeVersion: 2
}, {
  dataIndex: 21,
  id: 'bbtc',
  name: 'bbtc',
  lpTokenInfo: {
    name: 'bbtcCurve',
    symbol: 'bbtcCrv'
  },
  coingeckoInfo: {
    referenceAssetId: 'bitcoin'
  },
  assets: 'bbtc+sbtcCrv',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.bbtc, exports.ethereumCoin.sbtccrv],
  metaCoins: [exports.ethereumCoin.renbtc, exports.ethereumCoin.wbtc, exports.ethereumCoin.sbtc],
  referenceAsset: type.BTC,
  addresses: {
    swap: '0x071c661B4DeefB59E2a3DdB20Db036821eeE8F4b',
    lpToken: '0x410e3E86ef427e30B9235497143881f717d93c2A',
    gauge: '0xdFc7AdFa664b08767b735dE28f9E84cd30492aeE',
    deposit: '0xC45b2EEe6e09cA176Ca3bB5f7eEe7C47bF93c756'
  },
  gaugeVersion: 2
}, {
  dataIndex: 22,
  id: 'obtc',
  name: 'obtc',
  lpTokenInfo: {
    name: 'obtcCurve',
    symbol: 'obtcCrv'
  },
  coingeckoInfo: {
    id: 'boringdao-btc',
    symbol: 'OBTC',
    referenceAssetId: 'bitcoin'
  },
  assets: 'obtc+sbtcCrv',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.obtc, exports.ethereumCoin.sbtccrv],
  metaCoins: [exports.ethereumCoin.renbtc, exports.ethereumCoin.wbtc, exports.ethereumCoin.sbtc],
  referenceAsset: type.BTC,
  additionalRewards: [{
    name: 'BOR',
    amountDataKey: 'borRewards',
    rewardTokenAddress: '0xbc19712feb3a26080ebf6f2f7849b417fdd792ca',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'boringdao'
  }],
  addresses: {
    swap: '0xd81dA8D904b52208541Bade1bD6595D8a251F8dd',
    lpToken: '0x2fE94ea3d5d4a175184081439753DE15AeF9d614',
    gauge: '0x11137B10C210b579405c21A07489e28F3c040AB1',
    deposit: '0xd5BCf53e2C81e1991570f33Fa881c49EEa570C8D'
  },
  gaugeVersion: 2
}, {
  dataIndex: 23,
  id: 'ust',
  name: 'ust',
  lpTokenInfo: {
    name: 'ustCurve',
    symbol: 'ustCrv'
  },
  coingeckoInfo: {
    id: 'terrausd',
    symbol: 'UST',
    referenceAssetId: 'dollar'
  },
  assets: 'ust+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.ust, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0x890f4e345B1dAED0367A877a1612f86A1f86985f',
    lpToken: '0x94e131324b6054c0D789b190b2dAC504e4361b53',
    gauge: '0x3B7020743Bc2A4ca9EaF9D0722d42E20d6935855',
    deposit: '0xB0a0716841F2Fc03fbA72A891B8Bb13584F52F2d'
  },
  gaugeVersion: 2
}, {
  dataIndex: 24,
  id: 'eurs',
  name: 'eurs',
  lpTokenInfo: {
    name: 'eursCurve',
    symbol: 'eursCrv'
  },
  coingeckoInfo: {
    id: 'stasis-eurs',
    symbol: 'EURS',
    referenceAssetId: 'stasis-eurs'
  },
  assets: 'eurs+seur',
  coins: [exports.ethereumCoin.eurs, exports.ethereumCoin.seur],
  referenceAsset: type.EUR,
  oldAdditionalRewards: [{
    name: 'SNX',
    rewardTokenAddress: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'havven'
  }],
  addresses: {
    swap: '0x0Ce6a5fF5217e38315f87032CF90686C96627CAA',
    lpToken: '0x194eBd173F6cDacE046C53eACcE9B953F28411d1',
    gauge: '0x90Bb609649E0451E5aD952683D64BD2d1f245840'
  },
  gaugeVersion: 2
}, {
  dataIndex: 25,
  id: 'seth',
  name: 'seth',
  lpTokenInfo: {
    name: 'sethCurve',
    symbol: 'eCrv'
  },
  coingeckoInfo: {
    id: 'seth',
    symbol: 'SETH',
    referenceAssetId: 'ethereum'
  },
  assets: 'eth+seth',
  coins: [exports.ethereumCoin.eth, exports.ethereumCoin.seth],
  referenceAsset: type.ETH,
  addresses: {
    swap: '0xc5424b857f758e906013f3555dad202e4bdb4567',
    lpToken: '0xA3D87FffcE63B53E0d54fAa1cc983B7eB0b74A9c',
    gauge: '0x3C0FFFF15EA30C35d7A85B85c0782D6c94e1d238'
  },
  gaugeVersion: 2
}, {
  dataIndex: 26,
  id: 'aave',
  name: 'aave',
  lpTokenInfo: {
    name: 'aaveCurve',
    symbol: 'a3Crv'
  },
  coingeckoInfo: {
    id: 'aave',
    symbol: 'AAVE',
    referenceAssetId: 'dollar'
  },
  assets: 'aDAI+aUSDC+aUSDT',
  coins: [exports.ethereumCoin.adai, exports.ethereumCoin.ausdc, exports.ethereumCoin.ausdt],
  underlyingCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  isLendingPool: !0,
  isModernLendingPool: !0,
  addresses: {
    swap: '0xDeBF20617708857ebe4F679508E7b7863a8A8EeE',
    lpToken: '0xFd2a8fA60Abd58Efe3EeE34dd494cD491dC14900',
    gauge: '0xd662908ADA2Ea1916B3318327A97eB18aD588b5d'
  },
  gaugeVersion: 2,
  additionalRewards: [{
    name: 'STKAAVE',
    amountDataKey: 'aaveRewards',
    rewardTokenAddress: '0x4da27a545c0c5b758a6ba100e3a049001de870f5',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'aave'
  }]
}, {
  dataIndex: 27,
  id: 'steth',
  name: 'steth',
  lpTokenInfo: {
    name: 'stethCurve',
    symbol: 'stethCrv'
  },
  coingeckoInfo: {
    id: 'staked-ether',
    symbol: 'STETH',
    referenceAssetId: 'ethereum'
  },
  assets: 'eth+steth',
  coins: [exports.ethereumCoin.eth, exports.ethereumCoin.steth],
  referenceAsset: type.ETH,
  additionalRewards: [{
    name: 'LDO',
    amountDataKey: 'ldoRewards',
    rewardTokenAddress: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'lido-dao'
  }],
  addresses: {
    swap: '0xDC24316b9AE028F1497c275EB9192a3Ea0f67022',
    lpToken: '0x06325440D014e39736583c165C2963BA99fAf14E',
    gauge: '0x182B723a58739a9c974cFDB385ceaDb237453c28'
  },
  gaugeVersion: 2
}, {
  dataIndex: 28,
  id: 'saave',
  name: 'saave',
  lpTokenInfo: {
    name: 'saaveCurve',
    symbol: 'saCrv'
  },
  coingeckoInfo: {
    id: 'aave',
    symbol: 'AAVE',
    referenceAssetId: 'dollar'
  },
  assets: 'aDAI+asUSD',
  coins: [exports.ethereumCoin.adai, exports.ethereumCoin.asusd],
  underlyingCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.susd],
  isLendingPool: !0,
  isModernLendingPool: !0,
  addresses: {
    swap: '0xEB16Ae0052ed37f479f7fe63849198Df1765a733',
    lpToken: '0x02d341CcB60fAaf662bC0554d13778015d1b285C',
    gauge: '0x462253b8F74B72304c145DB0e4Eebd326B22ca39'
  },
  gaugeVersion: 2,
  additionalRewards: [{
    key: 'STKAAVES',
    name: 'STKAAVE',
    amountDataKey: 'saaveRewards',
    rewardTokenAddress: '0x4da27a545c0c5b758a6ba100e3a049001de870f5',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'aave'
  }]
}, {
  dataIndex: 29,
  id: 'ankreth',
  name: 'ankreth',
  lpTokenInfo: {
    name: 'ankrethCurve',
    symbol: 'aethCrv'
  },
  coingeckoInfo: {
    id: 'ankreth',
    symbol: 'AETH',
    referenceAssetId: 'ethereum'
  },
  assets: 'eth+ankreth',
  coins: [exports.ethereumCoin.eth, exports.ethereumCoin.ankreth],
  referenceAsset: type.ETH,
  additionalRewards: [{
    name: 'ANKR',
    amountDataKey: 'ankrRewards',
    rewardTokenAddress: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'ankr'
  }, {
    name: 'ONX',
    amountDataKey: 'onxRewards',
    rewardTokenAddress: '0xe0ad1806fd3e7edf6ff52fdb822432e847411033',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'onx-finance'
  }],
  addresses: {
    swap: '0xA96A65c051bF88B4095Ee1f2451C2A9d43F53Ae2',
    lpToken: '0xaA17A236F2bAdc98DDc0Cf999AbB47D47Fc0A6Cf',
    gauge: '0x6d10ed2cf043e6fcf51a0e7b4c2af3fa06695707'
  },
  gaugeVersion: 2
}, {
  dataIndex: 30,
  id: 'usdp',
  name: 'usdp',
  lpTokenInfo: {
    name: 'usdpCurve',
    symbol: 'usdpCrv'
  },
  coingeckoInfo: {
    id: 'usdp',
    symbol: 'USDP',
    referenceAssetId: 'dollar'
  },
  assets: 'usdp+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.usdp, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  additionalRewards: [{
    name: 'DUCK',
    amountDataKey: 'duckRewards',
    rewardTokenCoingeckoId: 'unit-protocol-duck',
    rewardTokenAddress: '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5',
    rewardTokenDecimals: 18
  }],
  addresses: {
    swap: '0x42d7025938bEc20B69cBae5A77421082407f053A',
    lpToken: '0x7Eb40E450b9655f4B3cC4259BCC731c63ff55ae6',
    gauge: '0x055be5DDB7A925BfEF3417FC157f53CA77cA7222',
    deposit: '0x3c8cAee4E09296800f8D29A68Fa3837e2dae4940'
  },
  gaugeVersion: 2
}, {
  dataIndex: 31,
  id: 'ib',
  name: 'ironbank',
  lpTokenInfo: {
    name: 'ibCurve',
    symbol: 'ib3Crv'
  },
  coingeckoInfo: {
    id: 'cream-2',
    symbol: 'CREAM',
    referenceAssetId: 'dollar'
  },
  assets: 'cyDAI+cyUSDC+cyUSDT',
  coins: [exports.ethereumCoin.cydai, exports.ethereumCoin.cyusdc, exports.ethereumCoin.cyusdt],
  underlyingCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  isLendingPool: !0,
  isModernLendingPool: !0,
  addresses: {
    swap: '0x2dded6Da1BF5DBdF597C45fcFaa3194e53EcfeAF',
    lpToken: '0x5282a4eF67D9C33135340fB3289cc1711c13638C',
    gauge: '0xF5194c3325202F456c95c1Cf0cA36f8475C1949F'
  },
  gaugeVersion: 2
}, {
  dataIndex: 32,
  id: 'link',
  name: 'link',
  lpTokenInfo: {
    name: 'linkCurve',
    symbol: 'linkCrv'
  },
  coingeckoInfo: {
    id: 'chainlink',
    symbol: 'LINK',
    referenceAssetId: 'chainlink'
  },
  referenceAsset: type.LINK,
  assets: 'LINK+sLINK',
  coins: [exports.ethereumCoin.link, exports.ethereumCoin.slink],
  addresses: {
    swap: '0xF178C0b5Bb7e7aBF4e12A4838C7b7c5bA2C623c0',
    lpToken: '0xcee60cfa923170e4f8204ae08b4fa6a3f5656f3a',
    gauge: '0xfd4d8a17df4c27c1dd245d153ccf4499e806c87d'
  },
  gaugeVersion: 2
}, {
  dataIndex: 33,
  id: 'tusd',
  name: 'tusd',
  lpTokenInfo: {
    name: 'tusdCurve',
    symbol: 'tusdCrv'
  },
  coingeckoInfo: {
    referenceAssetId: 'dollar'
  },
  assets: 'tusd+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.tusd, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0xecd5e75afb02efa118af914515d6521aabd189f1',
    lpToken: '0xecd5e75afb02efa118af914515d6521aabd189f1',
    gauge: '0x359FD5d6417aE3D8D6497d9B2e7A890798262BA4',
    deposit: '0xA79828DF1850E8a3A3064576f380D90aECDD3359'
  },
  gaugeVersion: 2
}, {
  dataIndex: 34,
  id: 'frax',
  name: 'frax',
  lpTokenInfo: {
    name: 'fraxCurve',
    symbol: 'fraxCrv'
  },
  coingeckoInfo: {
    id: 'frax',
    symbol: 'FRAX',
    referenceAssetId: 'dollar'
  },
  assets: 'frax+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.frax, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
    lpToken: '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
    deposit: '0xA79828DF1850E8a3A3064576f380D90aECDD3359',
    gauge: '0x72e158d38dbd50a483501c24f792bdaaa3e7d55c'
  },
  // additionalRewards: [
  //   ((a = {
  //     name: 'FXS',
  //     amountDataKey: 'fxsRewards',
  //     rewardTokenCoingeckoId: 'frax-share',
  //   }),
  //   s(a, 'amountDataKey', 'lqtyRewards'),
  //   s(a, 'rewardTokenAddress', '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0'),
  //   s(a, 'rewardTokenDecimals', 18),
  //   a),
  // ],
  gaugeVersion: 2
}, {
  dataIndex: 35,
  id: 'lusd',
  name: 'lusd',
  lpTokenInfo: {
    name: 'lusdCurve',
    symbol: 'lusdCrv'
  },
  coingeckoInfo: {
    id: 'liquity-usd',
    symbol: 'LUSD',
    referenceAssetId: 'dollar'
  },
  assets: 'lusd+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.lusd, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0xEd279fDD11cA84bEef15AF5D39BB4d4bEE23F0cA',
    lpToken: '0xEd279fDD11cA84bEef15AF5D39BB4d4bEE23F0cA',
    deposit: '0xA79828DF1850E8a3A3064576f380D90aECDD3359',
    gauge: '0x9b8519a9a00100720ccdc8a120fbed319ca47a14'
  },
  oldAdditionalRewards: [{
    name: 'LQTY',
    amountDataKey: 'lqtyRewards',
    rewardTokenCoingeckoId: 'liquity',
    rewardTokenAddress: '0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d',
    rewardTokenDecimals: 18
  }],
  gaugeVersion: 2
}, {
  dataIndex: 36,
  id: 'busdv2',
  name: 'busdv2',
  lpTokenInfo: {
    name: 'busdCurve',
    symbol: 'busdCrv'
  },
  coingeckoInfo: {
    id: 'binance-usd',
    symbol: 'BUSD',
    referenceAssetId: 'dollar'
  },
  assets: 'busd+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.busd, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0x4807862AA8b2bF68830e4C8dc86D0e9A998e085a',
    lpToken: '0x4807862AA8b2bF68830e4C8dc86D0e9A998e085a',
    gauge: '0xd4b22fedca85e684919955061fdf353b9d38389b',
    deposit: '0xA79828DF1850E8a3A3064576f380D90aECDD3359'
  },
  gaugeVersion: 2
}, {
  dataIndex: 37,
  id: 'reth',
  name: 'reth',
  lpTokenInfo: {
    name: 'rethCurve',
    symbol: 'rethCrv'
  },
  coingeckoInfo: {
    id: 'reth',
    symbol: 'RETH',
    referenceAssetId: 'ethereum'
  },
  assets: 'eth+reth',
  coins: [exports.ethereumCoin.eth, exports.ethereumCoin.reth],
  referenceAsset: type.ETH,
  addresses: {
    swap: '0xF9440930043eb3997fc70e1339dBb11F341de7A8',
    lpToken: '0x53a901d48795C58f485cBB38df08FA96a24669D5',
    gauge: '0x824F13f1a2F29cFEEa81154b46C0fc820677A637'
  },
  gaugeVersion: 4
  // additionalRewards: [
  //   ((i = {
  //     name: 'FIS',
  //     amountDataKey: 'fisRewards',
  //     rewardTokenCoingeckoId: 'stafi',
  //   }),
  //   s(i, 'amountDataKey', 'fisRewards'),
  //   s(i, 'rewardTokenAddress', '0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d'),
  //   s(i, 'rewardTokenDecimals', 18),
  //   i),
  // ],
}, {
  dataIndex: 38,
  id: 'alusd',
  name: 'alusd',
  lpTokenInfo: {
    name: 'alusdCurve',
    symbol: 'alusdCrv'
  },
  coingeckoInfo: {
    id: 'alchemix-usd',
    symbol: 'alUSD',
    referenceAssetId: 'dollar'
  },
  assets: 'alusd+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.alusd, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0x43b4FdFD4Ff969587185cDB6f0BD875c5Fc83f8c',
    lpToken: '0x43b4FdFD4Ff969587185cDB6f0BD875c5Fc83f8c',
    gauge: '0x9582C4ADACB3BCE56Fea3e590F05c3ca2fb9C477',
    deposit: '0xA79828DF1850E8a3A3064576f380D90aECDD3359'
  },
  // additionalRewards: [
  //   ((u = {
  //     name: 'ALCX',
  //     amountDataKey: 'alcxRewards',
  //     rewardTokenCoingeckoId: 'alchemix',
  //   }),
  //   s(u, 'amountDataKey', 'alcxRewards'),
  //   s(u, 'rewardTokenAddress', '0xdbdb4d16eda451d0503b854cf79d55697f90c8df'),
  //   s(u, 'rewardTokenDecimals', 18),
  //   u),
  // ],
  gaugeVersion: 4
}, {
  dataIndex: 37,
  id: 'tricrypto',
  name: 'tricrypto',
  lpTokenInfo: {
    name: '3CrvCrypto',
    symbol: '3CrvCrypto'
  },
  assets: 'usdt+weth+wbtc',
  coins: [exports.ethereumCoin.usdt, exports.ethereumCoin.wbtc, exports.ethereumCoin.weth],
  allowTradingEth: !0,
  addresses: {
    swap: '0x80466c64868E1ab14a1Ddf27A676C3fcBE638Fe5',
    lpToken: '0xcA3d75aC011BF5aD07a98d02f18225F9bD9A6BDF',
    deposit: '0x331aF2E331bd619DefAa5DAc6c038f53FCF9F785',
    gauge: '0x6955a55416a06839309018A8B0cB72c4DDC11f15',
    migrator: '0xD10d54830714003575d9f472d62268A29C902E5A'
  },
  gaugeVersion: 4,
  isGaugeKilled: !0,
  cryptoPool: !0,
  referenceAsset: type.CRYPTO
}, {
  dataIndex: 38,
  id: 'mim',
  name: 'mim',
  lpTokenInfo: {
    name: 'mimCurve',
    symbol: 'mimCrv'
  },
  coingeckoInfo: {
    referenceAssetId: 'dollar'
  },
  assets: 'mim+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.mim, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0x5a6A4D54456819380173272A5E8E9B9904BdF41B',
    lpToken: '0x5a6A4D54456819380173272A5E8E9B9904BdF41B',
    deposit: '0xA79828DF1850E8a3A3064576f380D90aECDD3359',
    gauge: '0xd8b712d29381748dB89c36BCa0138d7c75866ddF'
  },
  additionalRewards: [{
    name: 'SPELL',
    amountDataKey: 'spellRewards',
    rewardTokenCoingeckoId: 'spell-token',
    rewardTokenAddress: '0x090185f2135308bad17527004364ebcc2d37e5f6',
    rewardTokenDecimals: 18
  }],
  gaugeVersion: 2
}, {
  dataIndex: 39,
  id: 'tricrypto2',
  name: 'tricrypto2',
  lpTokenInfo: {
    name: '3CrvCrypto2',
    symbol: '3CrvCrypto2'
  },
  assets: 'usdt+weth+wbtc',
  coins: [exports.ethereumCoin.usdt, exports.ethereumCoin.wbtc, exports.ethereumCoin.weth],
  allowTradingEth: !0,
  addresses: {
    swap: '0xD51a44d3FaE010294C616388b506AcdA1bfAAE46',
    lpToken: '0xc4AD29ba4B3c580e6D59105FFf484999997675Ff',
    deposit: '0x3993d34e7e99Abf6B6f367309975d1360222D446',
    gauge: '0xDeFd8FdD20e0f34115C7018CCfb655796F6B2168',
    migrator: '0x0ce658f9bc3af831271199578449810023dba703'
  },
  gaugeVersion: 4,
  cryptoPool: !0,
  referenceAsset: type.CRYPTO
}, {
  dataIndex: 40,
  id: 'eurt',
  name: 'eurt',
  lpTokenInfo: {
    name: 'eurtCurve',
    symbol: 'eurtCrv'
  },
  coingeckoInfo: {
    id: 'tether-eurt',
    symbol: 'EURT',
    referenceAssetId: 'stasis-eurs'
  },
  assets: 'eurt+seur',
  coins: [exports.ethereumCoin.eurt, exports.ethereumCoin.seur],
  referenceAsset: type.EUR,
  addresses: {
    swap: '0xfd5db7463a3ab53fd211b4af195c5bccc1a03890',
    lpToken: '0xfd5db7463a3ab53fd211b4af195c5bccc1a03890',
    gauge: '0xe8060Ad8971450E624d5289A10017dD30F5dA85F'
  },
  gaugeVersion: 4
}, {
  dataIndex: 41,
  id: 'eurtusd',
  name: 'eurtusd',
  referenceAsset: type.CRYPTO,
  isForexMetaPool: !0,
  lpTokenInfo: {
    name: 'eurUsdCurve',
    symbol: 'eurUsdCrv'
  },
  coingeckoInfo: {
    id: 'tether-eurt',
    symbol: 'EURt',
    referenceAssetId: 'tether-eurt'
  },
  isMetaPool: !0,
  cryptoPool: !0,
  assets: 'EURt+3pool',
  coins: [exports.ethereumCoin.eurt, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0x9838eCcC42659FA8AA7daF2aD134b53984c9427b',
    lpToken: '0x3b6831c0077a1e44ED0a21841C3bC4dC11bCE833',
    gauge: '0x4Fd86Ce7Ecea88F7E0aA78DC12625996Fb3a04bC',
    deposit: '0x5D0F47B32fDd343BfA74cE221808e2abE4A53827'
  },
  gaugeVersion: 4
}, {
  dataIndex: 42,
  id: 'eursusd',
  name: 'eursusd',
  referenceAsset: type.CRYPTO,
  cryptoPool: !0,
  isPlainCryptoPool: !0,
  lpTokenInfo: {
    name: 'eurUsdCurve',
    symbol: 'eurUsdCrv'
  },
  coingeckoInfo: {
    id: 'stasis-eurs',
    symbol: 'EURs',
    referenceAssetId: 'stasis-eurs'
  },
  assets: 'EURs+USDC',
  coins: [exports.ethereumCoin.usdc, exports.ethereumCoin.eurs],
  addresses: {
    swap: '0x98a7F18d4E56Cfe84E3D081B40001B3d5bD3eB8B',
    lpToken: '0x3D229E1B4faab62F621eF2F6A610961f7BD7b23B',
    gauge: '0x65CA7Dc5CB661fC58De57B1E1aF404649a27AD35'
  }
}, {
  dataIndex: 42,
  id: 'crveth',
  name: 'crveth',
  referenceAsset: type.CRYPTO,
  cryptoPool: !0,
  isPlainCryptoPool: !0,
  lpTokenInfo: {
    name: 'CrvEthCurve',
    symbol: 'CrvEthCrv'
  },
  coingeckoInfo: {
    id: 'curve-dao-token',
    symbol: 'CRV',
    referenceAssetId: 'curve-dao-token'
  },
  assets: 'CRV+ETH',
  useAssetsStringForDisplayPurposes: !0,
  coins: [exports.ethereumCoin.weth, exports.ethereumCoin.crv],
  coinsInPlaceReplacements: [exports.ethereumCoin.eth],
  addresses: {
    swap: '0x8301AE4fc9c624d1D396cbDAa1ed877821D7C511',
    lpToken: '0xEd4064f376cB8d68F770FB1Ff088a3d0F3FF5c4d',
    gauge: '0x1cEBdB0856dd985fAe9b8fEa2262469360B8a3a6'
  },
  allowTradingEth: !0
}, {
  dataIndex: 38,
  id: 'rai',
  name: 'rai',
  lpTokenInfo: {
    name: 'raiCurve',
    symbol: 'raiCrv'
  },
  coingeckoInfo: {
    id: 'rai',
    symbol: 'RAI',
    referenceAssetId: 'dollar'
  },
  assets: 'RAI+3pool',
  isMetaPool: !0,
  coins: [exports.ethereumCoin.rai, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0x618788357D0EBd8A37e763ADab3bc575D54c2C7d',
    lpToken: '0x6BA5b4e438FA0aAf7C1bD179285aF65d13bD3D90',
    deposit: '0xcB636B81743Bb8a7F1E355DEBb7D33b07009cCCC',
    gauge: '0x66ec719045bBD62db5eBB11184c18237D3Cc2E62'
  },
  isRiskier: !0,
  riskLevel: 3,
  isStablePoolWithSpecialUnpeggedAssets: !0
}, {
  dataIndex: 42,
  id: 'cvxeth',
  name: 'cvxeth',
  referenceAsset: type.CRYPTO,
  cryptoPool: !0,
  isPlainCryptoPool: !0,
  lpTokenInfo: {
    name: 'CvxEthCurve',
    symbol: 'CvxEthCrv'
  },
  coingeckoInfo: {
    id: 'convex-finance',
    symbol: 'CVX'
  },
  assets: 'CVX+ETH',
  useAssetsStringForDisplayPurposes: !0,
  coins: [exports.ethereumCoin.weth, exports.ethereumCoin.cvx],
  coinsInPlaceReplacements: [exports.ethereumCoin.eth],
  addresses: {
    swap: '0xB576491F1E6e5E62f1d8F26062Ee822B40B0E0d4',
    lpToken: '0x3A283D9c08E8b55966afb64C515f5143cf907611',
    gauge: '0x7E1444BA99dcdFfE8fBdb42C02F0005D14f13BE1'
  },
  allowTradingEth: !0
}, {
  dataIndex: 43,
  id: 'xautusd',
  name: 'xautusd',
  referenceAsset: type.CRYPTO,
  isForexMetaPool: !0,
  lpTokenInfo: {
    name: 'xautUsdCurve',
    symbol: 'xautUsdCrv'
  },
  coingeckoInfo: {
    id: 'tether-gold',
    symbol: 'XAUt',
    referenceAssetId: 'tether-gold'
  },
  isMetaPool: !0,
  cryptoPool: !0,
  assets: 'XAUt+3pool',
  coins: [exports.ethereumCoin.xaut, exports.ethereumCoin.tricrv],
  metaCoins: [exports.ethereumCoin.dai, exports.ethereumCoin.usdc, exports.ethereumCoin.usdt],
  addresses: {
    swap: '0xAdCFcf9894335dC340f6Cd182aFA45999F45Fc44',
    lpToken: '0x8484673cA7BfF40F82B041916881aeA15ee84834',
    gauge: '0x1B3E14157ED33F60668f2103bCd5Db39a1573E5B',
    deposit: '0xc5FA220347375ac4f91f9E4A4AAb362F22801504'
  },
  gaugeVersion: 4
}, {
  dataIndex: 44,
  id: 'spelleth',
  name: 'spelleth',
  referenceAsset: type.CRYPTO,
  cryptoPool: !0,
  isPlainCryptoPool: !0,
  lpTokenInfo: {
    name: 'SpellEthCurve',
    symbol: 'SpellEthCrv'
  },
  coingeckoInfo: {
    id: 'spell-token',
    symbol: 'SPELL'
  },
  assets: 'SPELL+ETH',
  useAssetsStringForDisplayPurposes: !0,
  coins: [exports.ethereumCoin.weth, exports.ethereumCoin.spell],
  coinsInPlaceReplacements: [exports.ethereumCoin.eth],
  addresses: {
    swap: '0x98638FAcf9a3865cd033F36548713183f6996122',
    lpToken: '0x8282BD15dcA2EA2bDf24163E8f2781B30C43A2ef',
    gauge: '0x08380a4999Be1a958E2abbA07968d703C7A3027C'
  },
  allowTradingEth: !0
}, {
  dataIndex: 45,
  id: 'teth',
  name: 'teth',
  referenceAsset: type.CRYPTO,
  cryptoPool: !0,
  isPlainCryptoPool: !0,
  lpTokenInfo: {
    name: 'TEthCurve',
    symbol: 'TEthCrv'
  },
  coingeckoInfo: {
    id: 'threshold-network-token',
    symbol: 'T'
  },
  assets: 'T+ETH',
  useAssetsStringForDisplayPurposes: !0,
  coins: [exports.ethereumCoin.weth, exports.ethereumCoin.t],
  coinsInPlaceReplacements: [exports.ethereumCoin.eth],
  addresses: {
    swap: '0x752eBeb79963cf0732E9c0fec72a49FD1DEfAEAC',
    lpToken: '0xCb08717451aaE9EF950a2524E33B6DCaBA60147B',
    gauge: '0x6070fBD4E608ee5391189E7205d70cc4A274c017'
  },
  allowTradingEth: !0
}];
exports.polygonCoins = {
  matic: {
    id: 'matic',
    type: type.MATIC,
    symbol: 'MATIC',
    decimals: 1e18,
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
  },
  wmatic: {
    id: 'wmatic',
    type: type.MATIC,
    symbol: 'WMATIC',
    decimals: 1e18,
    address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    coingeckoId: 'wmatic'
  },
  dai: {
    id: 'dai',
    coingeckoId: 'dai',
    type: type.USD,
    symbol: 'DAI',
    decimals: 1e18,
    address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063'
  },
  adai: {
    id: 'adai',
    coingeckoId: 'aave-dai',
    type: type.USD,
    symbol: 'aDAI',
    decimals: 1e18,
    address: '0x27F8D03b3a2196956ED754baDc28D73be8830A6e',
    wrappedCoinType: 'aave'
  },
  usdc: {
    id: 'usdc',
    coingeckoId: 'usd-coin',
    type: type.USD,
    symbol: 'USDC',
    decimals: 1e6,
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
  },
  ausdc: {
    id: 'ausdc',
    coingeckoId: 'aave-usdc',
    type: type.USD,
    symbol: 'aUSDC',
    decimals: 1e6,
    address: '0x1a13F4Ca1d028320A707D99520AbFefca3998b7F',
    wrappedCoinType: 'aave'
  },
  usdt: {
    id: 'usdt',
    coingeckoId: 'tether',
    type: type.USD,
    symbol: 'USDT',
    decimals: 1e6,
    address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f'
  },
  ausdt: {
    id: 'ausdt',
    coingeckoId: 'aave-usdt',
    type: type.USD,
    symbol: 'aUSDT',
    decimals: 1e6,
    address: '0x60D55F02A771d515e077c9C2403a1ef324885CeC',
    wrappedCoinType: 'aave'
  },
  wbtc: {
    id: 'wbtc',
    coingeckoId: 'wrapped-bitcoin',
    type: type.BTC,
    symbol: 'wBTC',
    decimals: 1e8,
    address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6'
  },
  weth: {
    id: 'weth',
    coingeckoId: 'ethereum',
    type: type.ETH,
    symbol: 'WETH',
    decimals: 1e18,
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619'
  },
  amwbtc: {
    id: 'amwbtc',
    coingeckoId: 'wrapped-bitcoin',
    type: type.BTC,
    symbol: 'amWBTC',
    decimals: 1e8,
    address: '0x5c2ed810328349100A66B82b78a1791B101C9D61',
    wrappedCoinType: 'aave'
  },
  amweth: {
    id: 'amweth',
    coingeckoId: 'ethereum',
    type: type.ETH,
    symbol: 'amWETH',
    decimals: 1e18,
    address: '0x28424507fefb6f7f8E9D3860F56504E4e5f5f390',
    wrappedCoinType: 'aave'
  },
  renbtc: {
    id: 'renbtc',
    coingeckoId: 'renbtc',
    type: type.BTC,
    symbol: 'renBTC',
    decimals: 1e8,
    address: '0xDBf31dF14B66535aF65AaC99C32e9eA844e14501'
  },
  atricrv: {
    id: 'atricrv',
    coingeckoId: 'lp-3pool-curve',
    type: type.USD,
    symbol: 'a3Crv',
    decimals: 1e18,
    address: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
    isLpToken: !0
  },
  eurt: {
    id: 'eurt',
    coingeckoId: 'tether-eurt',
    type: type.EUR,
    symbol: 'EURt',
    decimals: 1e6,
    address: '0x7BDF330f423Ea880FF95fC41A280fD5eCFD3D09f'
  },
  eurs: {
    id: 'eurs',
    coingeckoId: 'stasis-eurs',
    type: type.EUR,
    symbol: 'EURS',
    decimals: 100,
    address: '0xE111178A87A3BFf0c8d18DECBa5798827539Ae99'
  },
  deusdc: {
    id: 'deusdc',
    address: '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa',
    usdPrice: 1.0009256161930364,
    decimals2: '6',
    decimals: 1000000,
    symbol: 'deUSDC',
    poolBalance: '615597206470'
  },
  am3crv: {
    id: 'am3crv',
    address: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
    usdPrice: 1.0518996714646422,
    decimals2: '18',
    decimals: 1000000000000000000,
    symbol: 'am3CRV',
    poolBalance: '1535946918797903862901410'
  }
};
exports.polygonPools = [{
  dataIndex: 0,
  id: 'aave',
  name: 'aave',
  lpTokenInfo: {
    name: 'aaveCurve',
    symbol: 'a3Crv'
  },
  coingeckoInfo: {
    id: 'aave',
    symbol: 'AAVE'
  },
  assets: 'aDAI+aUSDC+aUSDT',
  coins: [exports.polygonCoins.adai, exports.polygonCoins.ausdc, exports.polygonCoins.ausdt],
  underlyingCoins: [exports.polygonCoins.dai, exports.polygonCoins.usdc, exports.polygonCoins.usdt],
  isLendingPool: !0,
  isModernLendingPool: !0,
  addresses: {
    swap: '0x445FE580eF8d70FF569aB36e80c647af338db351',
    lpToken: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
    gauge: '0x19793B454D3AfC7b454F206Ffe95aDE26cA6912c',
    old_gauge: '0xe381C25de995d62b453aF8B931aAc84fcCaa7A62'
  },
  gaugeVersion: 3,
  additionalRewards: [{
    key: 'CRV3POOL',
    name: 'CRV',
    amountDataKey: 'crvRewards3Pool',
    rewardTokenAddress: '0x172370d5cd63279efa6d502dab29171933a610af',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'curve-dao-token',
    rewardContract: '0xC48f4653dd6a9509De44c92beb0604BEA3AEe714'
  }, {
    key: 'WMATIC3POOL',
    name: 'WMATIC',
    rewardTokenCoingeckoId: 'matic-network',
    rewardTokenDecimals: 18,
    rewardTokenAddress: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    rewardContract: '0xC48f4653dd6a9509De44c92beb0604BEA3AEe714'
  }],
  hasNoCRVRewards: !0
}, {
  dataIndex: 1,
  id: 'atricrypto',
  name: 'atricrypto',
  lpTokenInfo: {
    name: '3CrvCrypto',
    symbol: '3CrvCrypto'
  },
  assets: 'adai+ausdc+ausdt+aeth+abtc',
  coins: [exports.polygonCoins.adai, exports.polygonCoins.ausdc, exports.polygonCoins.ausdt, exports.polygonCoins.amwbtc, exports.polygonCoins.amweth],
  addresses: {
    swap: '0x751B1e21756bDbc307CBcC5085c042a0e9AaEf36',
    lpToken: '0x8096ac61db23291252574D49f036f0f9ed8ab390',
    deposit: '0x3FCD5De6A9fC8A99995c406c77DDa3eD7E406f81',
    gauge: '0xb0a366b987d77b5eD5803cBd95C80bB6DEaB48C0',
    migrator: '0xAf0B9422eFFB6941B9D79FdE5576Ea2F8AF046E4'
  },
  cryptoPool: !0,
  referenceAsset: type.CRYPTO,
  isFakePlainPool: !0,
  gaugeVersion: 3,
  additionalRewards: [{
    key: 'CRVATRICRYPTO',
    name: 'CRV',
    amountDataKey: 'crvRewardsaTriCrypto',
    rewardTokenAddress: '0x172370d5cd63279efa6d502dab29171933a610af',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'curve-dao-token',
    rewardContract: '0x060e386eCfBacf42Aa72171Af9EFe17b3993fC4F'
  }, {
    key: 'WMATICATRICRYPTO',
    name: 'WMATIC',
    amountDataKey: 'maticRewards',
    rewardTokenCoingeckoId: 'matic-network',
    rewardTokenDecimals: 18,
    rewardTokenAddress: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    rewardContract: '0x060e386eCfBacf42Aa72171Af9EFe17b3993fC4F'
  }],
  hasNoCRVRewards: !0
}, {
  dataIndex: 2,
  id: 'ren',
  name: 'ren',
  lpTokenInfo: {
    name: 'renCurve',
    symbol: 'renCrv'
  },
  coingeckoInfo: {
    id: 'renbtc',
    symbol: 'RENBTC',
    referenceAssetId: 'bitcoin'
  },
  assets: 'awBTC+renBTC',
  coins: [exports.polygonCoins.amwbtc, exports.polygonCoins.renbtc],
  underlyingCoins: [exports.polygonCoins.wbtc, exports.polygonCoins.renbtc],
  isLendingPool: !0,
  referenceAsset: type.BTC,
  isModernLendingPool: !0,
  addresses: {
    swap: '0xC2d95EEF97Ec6C17551d45e77B590dc1F9117C67',
    lpToken: '0xf8a57c1d3b9629b77b6726a042ca48990A84Fb49',
    gauge: '0xffbACcE0CC7C19d46132f1258FC16CF6871D153c',
    old_gauge: '0x93b14f53528a59e655d8ce39bdba443dcddddc4c'
  },
  gaugeVersion: 3,
  additionalRewards: [{
    key: 'CRVREN',
    name: 'CRV',
    amountDataKey: 'crvRewardsRen',
    rewardTokenAddress: '0x172370d5cd63279efa6d502dab29171933a610af',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'curve-dao-token',
    rewardContract: '0x488E6ef919C2bB9de535C634a80afb0114DA8F62'
  }, {
    key: 'WMATICREN',
    name: 'WMATIC',
    amountDataKey: 'maticRewards',
    rewardTokenCoingeckoId: 'matic-network',
    rewardTokenDecimals: 18,
    rewardTokenAddress: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    rewardContract: '0x488E6ef919C2bB9de535C634a80afb0114DA8F62'
  }],
  hasNoCRVRewards: !0
}, {
  dataIndex: 4,
  id: 'atricrypto2',
  name: 'atricrypto2',
  lpTokenInfo: {
    name: '3CrvCrypto2',
    symbol: '3CrvCrypto2'
  },
  assets: 'adai+ausdc+ausdt+aeth+abtc',
  coins: [exports.polygonCoins.adai, exports.polygonCoins.ausdc, exports.polygonCoins.ausdt, exports.polygonCoins.amwbtc, exports.polygonCoins.amweth],
  addresses: {
    swap: '0x92577943c7aC4accb35288aB2CC84D75feC330aF',
    lpToken: '0xbece5d20A8a104c54183CC316C8286E3F00ffC71',
    deposit: '0x3Fa8ebd5d16445b42e0b6A54678718C94eA99aBC',
    gauge: '0x9bd996Db02b3f271c6533235D452a56bc2Cd195a',
    migrator: '0x321CDba34da09Cf57C709F75DBb42bA209cFe6Ac'
  },
  cryptoPool: !0,
  referenceAsset: type.CRYPTO,
  isFakePlainPool: !0,
  gaugeVersion: 3,
  additionalRewards: [],
  hasNoCRVRewards: !0
}, {
  dataIndex: 5,
  id: 'atricrypto3',
  name: 'atricrypto3',
  lpTokenInfo: {
    name: '3CrvCrypto3',
    symbol: '3CrvCrypto3'
  },
  assets: 'adai+ausdc+ausdt+aeth+abtc',
  coins: [exports.polygonCoins.adai, exports.polygonCoins.ausdc, exports.polygonCoins.ausdt, exports.polygonCoins.amwbtc, exports.polygonCoins.amweth],
  addresses: {
    swap: '0x92215849c439E1f8612b6646060B4E3E5ef822cC',
    lpToken: '0xdAD97F7713Ae9437fa9249920eC8507e5FbB23d3',
    deposit: '0x1d8b86e3D88cDb2d34688e87E72F388Cb541B7C8',
    gauge: '0x3B6B158A76fd8ccc297538F454ce7B4787778c7C'
  },
  cryptoPool: !0,
  referenceAsset: type.CRYPTO,
  isFakePlainPool: !0,
  gaugeVersion: 3,
  additionalRewards: [],
  hasNoCRVRewards: !0
}, {
  dataIndex: 6,
  id: 'eurtusd',
  name: 'eurtusd',
  referenceAsset: type.CRYPTO,
  isForexMetaPool: !0,
  lpTokenInfo: {
    name: 'eurtUsdCurve',
    symbol: 'eurtUsdCrv'
  },
  coingeckoInfo: {
    id: 'tether-eurt',
    symbol: 'EURt'
  },
  isMetaPool: !0,
  cryptoPool: !0,
  assets: 'EURt+a3pool',
  coins: [exports.polygonCoins.eurt, exports.polygonCoins.atricrv],
  metaCoins: [exports.polygonCoins.dai, exports.polygonCoins.usdc, exports.polygonCoins.usdt],
  addresses: {
    swap: '0xB446BF7b8D6D4276d0c75eC0e3ee8dD7Fe15783A',
    lpToken: '0x600743B1d8A96438bD46836fD34977a00293f6Aa',
    gauge: '0x40c0e9376468b4f257d15F8c47E5D0C646C28880',
    deposit: '0x225FB4176f0E20CDb66b4a3DF70CA3063281E855'
  },
  additionalRewards: [{
    key: 'CRVEURT',
    name: 'CRV',
    amountDataKey: 'crvRewards3Pool',
    rewardTokenAddress: '0x172370d5cd63279efa6d502dab29171933a610af',
    rewardTokenDecimals: 18,
    rewardTokenCoingeckoId: 'curve-dao-token',
    rewardContract: '0xAF78381216a8eCC7Ad5957f3cD12a431500E0B0D'
  }],
  gaugeVersion: 3,
  hasNoCRVRewards: !0
}, {
  dataIndex: 6,
  id: 'eursusd',
  name: 'eursusd',
  referenceAsset: type.CRYPTO,
  isForexMetaPool: !0,
  lpTokenInfo: {
    name: 'eursUsdCurve',
    symbol: 'eursUsdCrv'
  },
  coingeckoInfo: {
    id: 'stasis-eurs',
    symbol: 'EURS'
  },
  isMetaPool: !0,
  cryptoPool: !0,
  assets: 'EURS+a3pool',
  coins: [exports.polygonCoins.eurs, exports.polygonCoins.atricrv],
  metaCoins: [exports.polygonCoins.dai, exports.polygonCoins.usdc, exports.polygonCoins.usdt],
  addresses: {
    swap: '0x9b3d675FDbe6a0935E8B7d1941bc6f78253549B7',
    lpToken: '0x7BD9757FbAc089d60DaFF1Fa6bfE3BC99b0F5735',
    gauge: '0x40c0e9376468b4f257d15F8c47E5D0C646C28880',
    deposit: '0x4DF7eF55E99a56851187822d96B4E17D98A47DeD'
  },
  hasNoGauge: !0
}];
exports.polygonFactoryPools = [{
  dataIndex: null,
  id: 'factory-v2-111',
  name: 'Factory: deBridge-USDC',
  pageMetaData: {
    title: 'Factory: deBridge-USDC',
    description: 'A curve.fi portal for swapping undefined'
  },
  lpTokenInfo: {
    name: 'aaveCurve',
    symbol: 'a3Crv'
  },
  coingeckoInfo: {
    referenceAssetId: 'dollar'
  },
  useAssetsStringForDisplayPurposes: false,
  coins: [exports.polygonCoins.deusdc, exports.polygonCoins.am3crv],
  coinsInPlaceReplacements: [],
  metaCoins: [exports.polygonCoins.dai, exports.polygonCoins.usdc, exports.polygonCoins.usdt],
  referenceAsset: 'usd',
  isLendingPool: false,
  isModernLendingPool: false,
  isMetaPool: true,
  allowTradingEth: false,
  isRiskier: false,
  isGaugeKilled: false,
  additionalRewards: [],
  oldAdditionalRewards: [],
  hasNoGauge: true,
  isPendingGaugeVoteToStartCrvRewards: false,
  expectedCrvRewardsStart: null,
  addresses: {
    swap: '0xDa43Bfd7Ecc6835AA6f1761ced30b986A574c0d2',
    lpToken: '0xDa43Bfd7Ecc6835AA6f1761ced30b986A574c0d2',
    deposit: '0x5ab5C56B9db92Ba45a0B46a207286cD83C15C939',
    stakingRewards: null,
    adapter: null,
    migrator: null
  },
  hasAMultiplier: true,
  isOldPool: false,
  hasNoCRVRewards: false,
  poolType: 'stable',
  isFakePlainPool: false,
  isForexMetaPool: false,
  isPlainCryptoPool: false,
  isStablePoolWithSpecialUnpeggedAssets: false,
  coinsAddresses: ['0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa', '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171'],
  decimals: ['6', '18', '0', '0'],
  underlyingDecimals: ['6', '18', '6', '6', '0', '0', '0', '0'],
  assetType: '0',
  implementationAddress: '0x4fb93D7d320E8A263F22f62C2059dFC2A8bCbC4c',
  symbol: 'deUSDC-3P3CRV-f',
  assetTypeName: 'usd',
  apy: 0.11409699134185614,
  apyWeekly: 0,
  volume: 321178.6731771694,
  isFactoMetaPoolAndTwoLevelsDeep: [exports.polygonCoins.dai, exports.polygonCoins.usdc, exports.polygonCoins.usdt],
  isFactoryOnlyPool: true,
  factoryVersion: 2,
  totalSupply: '1417114574733619704499367',
  usdTotal: 2231829.072483341,
  containsSynthCoin: false,
  containsAaveCoin: false,
  containsCompoundCoin: false,
  containsYearnBasedCoin: false
}];
exports.avalancheCoins = {
  deusdc: {
    id: 'deusdc',
    address: '0x28690ec942671aC8d9Bc442B667EC338eDE6dFd3',
    usdPrice: 1.000054657258821,
    decimals: '6',
    symbol: 'deUSDC',
    poolBalance: '963719752313'
  },
  av3crv: {
    id: 'av3crv',
    address: '0x1337BedC9D22ecbe766dF105c9623922A27963EC',
    usdPrice: 1.0191963670047817,
    decimals: '18',
    symbol: 'av3CRV',
    poolBalance: '2043260066945207969777984'
  },
  daidote: {
    id: 'dai',
    type: 'usd',
    symbol: 'DAI.e',
    decimals: 1000000000000000000,
    address: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'dai',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'dai'
  },
  usdcdote: {
    id: 'usdc',
    type: 'usd',
    symbol: 'USDC.e',
    decimals: 1000000,
    address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'usd-coin',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'usd-coin'
  },
  usdtdote: {
    id: 'usdt',
    type: 'usd',
    symbol: 'USDT.e',
    decimals: 1000000,
    address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'tether',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'tether'
  }
};
exports.avalancheFactoryPools = [{
  dataIndex: null,
  id: 'factory-v2-61',
  name: 'Factory: deBridge-USDC',
  pageMetaData: {
    title: 'Factory: deBridge-USDC',
    description: 'A curve.fi portal for swapping undefined'
  },
  lpTokenInfo: {
    name: 'aaveCurve',
    symbol: 'a3Crv'
  },
  coingeckoInfo: {
    referenceAssetId: 'dollar'
  },
  useAssetsStringForDisplayPurposes: false,
  coins: [exports.avalancheCoins.deusdc, exports.avalancheCoins.av3crv],
  coinsInPlaceReplacements: [],
  metaCoins: [exports.avalancheCoins.daidote, exports.avalancheCoins.usdcdote, exports.avalancheCoins.usdtdote],
  referenceAsset: 'usd',
  isLendingPool: false,
  isModernLendingPool: false,
  isMetaPool: true,
  allowTradingEth: false,
  isRiskier: false,
  isGaugeKilled: false,
  additionalRewards: [],
  oldAdditionalRewards: [],
  hasNoGauge: true,
  isPendingGaugeVoteToStartCrvRewards: false,
  expectedCrvRewardsStart: null,
  addresses: {
    swap: '0xd39016475200ab8957e9C772C949Ef54bDA69111',
    lpToken: '0xd39016475200ab8957e9C772C949Ef54bDA69111',
    deposit: '0x001E3BA199B4FF4B5B6e97aCD96daFC0E2e4156e',
    stakingRewards: null,
    adapter: null,
    migrator: null
  },
  hasAMultiplier: true,
  isOldPool: false,
  hasNoCRVRewards: false,
  poolType: 'stable',
  isFakePlainPool: false,
  isForexMetaPool: false,
  isPlainCryptoPool: false,
  isStablePoolWithSpecialUnpeggedAssets: false,
  coinsAddresses: ['0x28690ec942671aC8d9Bc442B667EC338eDE6dFd3', '0x1337BedC9D22ecbe766dF105c9623922A27963EC'],
  decimals: ['6', '18', '0', '0'],
  underlyingDecimals: ['6', '18', '6', '6', '0', '0', '0', '0'],
  assetType: '0',
  implementationAddress: '0xA237034249290De2B07988Ac64b96f22c0E76fE0',
  symbol: 'deUSDС-3CRV-f',
  assetTypeName: 'usd',
  apy: 7.541502721984861,
  apyWeekly: 2.0204919659237097,
  volume: 11789.505665629957,
  isFactoMetaPoolAndTwoLevelsDeep: [{
    id: 'dai',
    type: 'usd',
    symbol: 'DAI.e',
    decimals: 1000000000000000000,
    address: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'dai',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'dai'
  }, {
    id: 'usdc',
    type: 'usd',
    symbol: 'USDC.e',
    decimals: 1000000,
    address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'usd-coin',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'usd-coin'
  }, {
    id: 'usdt',
    type: 'usd',
    symbol: 'USDT.e',
    decimals: 1000000,
    address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    isSynth: false,
    isLpToken: false,
    wrappedCoinType: null,
    isTradable: true,
    coingeckoId: 'tether',
    isWrappedCoin: false,
    isFiat: true,
    getCoingeckoId: 'tether'
  }],
  isFactoryOnlyPool: true,
  factoryVersion: 2,
  totalSupply: '2000495413429267207027590',
  usdTotal: 3046255.6636694362,
  containsSynthCoin: false,
  containsAaveCoin: false,
  containsCompoundCoin: false,
  containsYearnBasedCoin: false
}];
exports.bscCoins = {
  deusdc: {
    id: 'deusdc',
    address: '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa',
    usdPrice: 1.000054657258821,
    decimals2: '6',
    decimals: 1000000,
    symbol: 'deUSDC',
    poolBalance: '963719752313'
  },
  a3eps: {
    id: 'a3eps',
    address: '0x1337BedC9D22ecbe766dF105c9623922A27963EC',
    decimals2: '18',
    decimals: 1000000000000000000,
    symbol: '3EPS'
  },
  busd: {
    id: 'busd',
    type: 'usd',
    symbol: 'BUSD',
    decimals: 1000000000000000000,
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
  },
  usdc: {
    id: 'usdc',
    type: 'usd',
    symbol: 'USDC',
    decimals: 1000000000000000000,
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
  },
  usdt: {
    id: 'usdt',
    type: 'usd',
    symbol: 'USDT',
    decimals: 1000000000000000000,
    address: '0x55d398326f99059fF775485246999027B3197955'
  }
};
exports.bscFactoryPools = [{
  poolIndex: 4,
  addresses: {
    swap: '0x5A7d2F9595eA00938F3B5BA1f97a85274f20b96c',
    lpToken: '0xEAaD1b47283aB31A7ae92Dc156c963584D35120D',
    deposit: '0x5A7d2F9595eA00938F3B5BA1f97a85274f20b96c',
    stakingRewards: null,
    adapter: null,
    migrator: null
  },
  address: '0x5A7d2F9595eA00938F3B5BA1f97a85274f20b96c',
  epsVersion: 2,
  poolType: 'meta',
  balance: 0,
  apy: 5.21164155,
  seeded: true,
  hasNative: false,
  apyFormatted: '5.21%',
  isFactoryOnlyPool: true,
  isLendingPool: true,
  epsApy: 0,
  virtualPrice: 0,
  isStableCoin: 1,
  volume: 124101,
  totalVol: 8988492,
  name: 'deBridge-USDC',
  tokenImp: '0x4C29159AF03aca9a8381abC9BccB64B2Ec9a10B2',
  poolImp: '0xeA0CbeB9E0083E9d7b920019d0bfDB39b37d3E2F',
  lpPid: 1,
  tvl: '',
  lpToken: {
    address: '0xEAaD1b47283aB31A7ae92Dc156c963584D35120D',
    symbol: 'deusdc-3ep',
    decimals: 18,
    rawBalance: 0,
    balance: 0,
    totalSupply: '2397974884385094753043011'
  },
  rewards: [],
  coins: [exports.bscCoins.deusdc, exports.bscCoins.a3eps],
  underlyingCoins: [exports.bscCoins.deusdc, exports.bscCoins.busd, exports.bscCoins.usdc, exports.bscCoins.usdt],
  metaCoins: [],
  a: '200',
  fees: ['4000000', '5000000000']
}];
