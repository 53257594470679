"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CROWD_USDT_LP_STAKE_OPPORTUNITY = void 0;
exports.CROWD_USDT_LP_STAKE_OPPORTUNITY = [{
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountA',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountB',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'liquidity',
    type: 'uint256'
  }],
  name: 'AddedLiquidity',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'previousAdmin',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newAdmin',
    type: 'address'
  }],
  name: 'AdminChanged',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'beacon',
    type: 'address'
  }],
  name: 'BeaconUpgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'totalFee',
    type: 'uint256'
  }],
  name: 'FeeDeducted',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint8',
    name: 'version',
    type: 'uint8'
  }],
  name: 'Initialized',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'InvestedByLP',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'InvestedByToken',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'InvestedByTokenAOrTokenB',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountA',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountB',
    type: 'uint256'
  }],
  name: 'InvestedByTokenATokenB',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }],
  name: 'Left',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Paused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'liquidity',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountA',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountB',
    type: 'uint256'
  }],
  name: 'RemovedLiquidity',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'Staked',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'fromToken',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'toToken',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountIn',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountOut',
    type: 'uint256'
  }],
  name: 'Swapped',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Unpaused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'rewards',
    type: 'uint256'
  }],
  name: 'Unstaked',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'Upgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'receiver',
    type: 'address'
  }],
  name: 'WithdrawnFunds',
  type: 'event'
}, {
  inputs: [],
  name: 'addLiquidityFee',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'feeTo',
  outputs: [{
    internalType: 'address payable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_tokenCrowd',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_tokenUsdt',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_pairCrowdUsdt',
    type: 'address'
  }, {
    internalType: 'address payable',
    name: '_feeTo',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_addLiquidityFee',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_removeLiquidityFee',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_stakeFee',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_unstakeFee',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: '_swapContract',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_router',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_stakingLP',
    type: 'address'
  }],
  name: 'initialize',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amountLP',
    type: 'uint256'
  }],
  name: 'investByLP',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'contract IERC20Upgradeable',
    name: '_token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_secondAmount',
    type: 'uint256'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amountADesired',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountBDesired',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountAMin',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountBMin',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256'
    }],
    internalType: 'struct Opportunity.AddLiqDescriptor',
    name: '_addLiqDescriptor',
    type: 'tuple'
  }, {
    internalType: 'bytes',
    name: '_swapDataToB',
    type: 'bytes'
  }, {
    internalType: 'bytes',
    name: '_swapDataToA',
    type: 'bytes'
  }],
  name: 'investByToken',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'contract IERC20Upgradeable',
    name: '_token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_secondAmount',
    type: 'uint256'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amountADesired',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountBDesired',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountAMin',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountBMin',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256'
    }],
    internalType: 'struct Opportunity.AddLiqDescriptor',
    name: '_addLiqDescriptor',
    type: 'tuple'
  }, {
    internalType: 'bytes',
    name: '_swapData',
    type: 'bytes'
  }],
  name: 'investByTokenAOrTokenB',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'contract IERC20Upgradeable',
    name: '_token',
    type: 'address'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amountADesired',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountBDesired',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountAMin',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountBMin',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256'
    }],
    internalType: 'struct Opportunity.AddLiqDescriptor',
    name: '_addLiqDescriptor',
    type: 'tuple'
  }],
  name: 'investByTokenATokenB',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountAMin',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amountBMin',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256'
    }, {
      internalType: 'address payable',
      name: 'receiverAccount',
      type: 'address'
    }],
    internalType: 'struct Opportunity.RemoveLiqDescriptor',
    name: '_removeLiqDescriptor',
    type: 'tuple'
  }],
  name: 'leave',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pair',
  outputs: [{
    internalType: 'contract IERC20Upgradeable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pause',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'paused',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'proxiableUUID',
  outputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'removeLiquidityFee',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'router',
  outputs: [{
    internalType: 'contract IUniswapV2Router02',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setAddLiquidityFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address payable',
    name: '_feeTo',
    type: 'address'
  }],
  name: 'setFeeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_pair',
    type: 'address'
  }],
  name: 'setPair',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setRemoveLiquidityFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_address',
    type: 'address'
  }],
  name: 'setRouter',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setStakeFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_address',
    type: 'address'
  }],
  name: 'setStakingLP',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_address',
    type: 'address'
  }],
  name: 'setSwapContract',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_tokenA',
    type: 'address'
  }],
  name: 'setTokenA',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_tokenB',
    type: 'address'
  }],
  name: 'setTokenB',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setUnstakeFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'stakeFee',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'stakingLP',
  outputs: [{
    internalType: 'contract IStakingLP',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'swapContract',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'tokenA',
  outputs: [{
    internalType: 'contract IERC20Upgradeable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'tokenB',
  outputs: [{
    internalType: 'contract IERC20Upgradeable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'unpause',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'unstakeFee',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }],
  name: 'upgradeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  name: 'upgradeToAndCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    internalType: 'address payable',
    name: '_receiver',
    type: 'address'
  }],
  name: 'withdrawFunds',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}];
