import { Pipe, PipeTransform } from '@angular/core';
import { NumberType, formatNumber } from '@uniswap/conedison/format.js';

@Pipe({
  name: 'AmountToDisplay'
})
export class AmountToDisplay implements PipeTransform {
  transform(data: number): string {
    return formatNumber(data, NumberType.FiatTokenPrice);
  }
}
