"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseNetworkService = void 0;
const providers_1 = require("@ethersproject/providers");
const contracts_1 = require("@ethersproject/contracts");
const abi_1 = require("@ethersproject/abi");
const networks_1 = require("./networks");
const gas_price_holder_1 = require("./gas-price-holder");
const ethers_1 = require("ethers");
class BaseNetworkService {
  constructor(chainId, urls, websocketRpcUrls = [], logger) {
    if (!urls || urls.length === 0) {
      throw new Error(`Urls is not defined`);
    }
    this.name = networks_1.Networks[chainId].toLowerCase();
    this.chainId = chainId;
    this.rpcUrls = urls;
    this.websocketRpcUrls = websocketRpcUrls;
    this.providers = [];
    this.websocketProviders = [];
    this.logger = logger;
    this.rpcUrls.forEach(url => {
      this.providers.push(new providers_1.StaticJsonRpcProvider(url, {
        chainId: this.chainId,
        name: this.name
      }));
    });
    this.websocketRpcUrls.forEach(url => {
      this.websocketProviders.push(new providers_1.WebSocketProvider(url, {
        chainId: this.chainId,
        name: this.name
      }));
    });
  }
  getRandomIndex(length) {
    return Math.floor(Math.random() * length);
  }
  getChainId() {
    return this.chainId;
  }
  getRpcUrl() {
    return this.rpcUrls[this.getRandomIndex(this.rpcUrls.length)];
  }
  getWebsocketRpcUrl() {
    if (this.websocketRpcUrls.length == 0) {
      throw new Error(`Not provided WebsocketRpcUrl for ${this.name} network`);
    }
    return this.websocketRpcUrls[this.getRandomIndex(this.websocketRpcUrls.length)];
  }
  getProvider() {
    const index = this.getRandomIndex(this.providers.length);
    // this.logger.debug(
    //   `Use this provider [index: ${index}/${this.providers.length}, url: ${this.rpcUrls[index]}]`,
    // );
    return this.providers[index];
  }
  getWebsocketProvider() {
    if (this.websocketProviders.length == 0) {
      throw new Error(`Not provided WebsocketRpcUrl for ${this.name} network`);
    }
    const index = this.getRandomIndex(this.websocketProviders.length);
    // this.logger.debug(
    //   `Use this websocket provider [index: ${index}/${this.websocketProviders.length}, url: ${this.websocketRpcUrls[index]}]`,
    // );
    return this.websocketProviders[index];
  }
  getGasPrice() {
    const gasPrice = gas_price_holder_1.GasPriceHolder.Instance.getGasPrice(this.chainId);
    if (gasPrice) {
      return Promise.resolve(gasPrice);
    }
    return this.getProvider().getGasPrice();
  }
  getTransactionCount(userAddress) {
    return this.getProvider().getTransactionCount(userAddress);
  }
  getCurrentBlockNumber() {
    return this.getProvider().getBlockNumber();
  }
  getBlock(blockNumber) {
    return this.getProvider().getBlock(blockNumber);
  }
  getTransaction(transactionHash) {
    return this.getProvider().getTransaction(transactionHash);
  }
  getTransactionReceipt(transactionHash) {
    return this.getProvider().getTransactionReceipt(transactionHash);
  }
  getContract(address, abi) {
    return new contracts_1.Contract(address, abi, this.getProvider());
  }
  logFetcher(type) {
    let fetcher;
    switch (type) {
      case 'BY_PROVIDER':
        const that = this;
        fetcher = {
          fetch: async (contractAddress, abi, eventSignature, topics, fromBlockNumber, tillBlockNumber) => {
            const eventTopic = eventSignature ? ethers_1.ethers.utils.id(eventSignature) : null;
            const contract = that.getContract(contractAddress, abi);
            const filter = {
              address: contractAddress,
              topics: [eventTopic, topics[1], topics[2], topics[3]]
            };
            const events = await contract.queryFilter(filter, fromBlockNumber, tillBlockNumber);
            const results = [];
            for (let event_ of events) {
              const blockTimeStamp = (await that.getProvider().getBlock(event_.blockNumber)).timestamp;
              const timestamp = event_.timeStamp ?? blockTimeStamp;
              results.push({
                blockNumber: event_.blockNumber,
                transactionHash: event_.transactionHash,
                timeStamp: timestamp,
                args: event_.args
              });
            }
            return results;
          }
        };
        break;
      case 'BY_EXTERNAL_API':
        fetcher = {
          fetch: this.fetchLogsByCallingApi
        };
        break;
    }
    return fetcher;
  }
  async fetchLogsByCallingProvider(contractAddress, abi, eventSignature, topics, fromBlockNumber, tillBlockNumber) {
    const eventName = eventSignature.split('(')[0];
    const contract = this.getContract(contractAddress, abi);
    const filter = contract.filters[eventName](topics[1], topics[2], topics[3]);
    const events = await contract.queryFilter(filter, fromBlockNumber, tillBlockNumber);
    return events.map(event => {
      return {
        blockNumber: event.blockNumber,
        transactionHash: event.transactionHash,
        args: event.args
      };
    });
  }
  async fetchTransactionReceipt(transactionHash) {
    const receipt = await this.getProvider().getTransactionReceipt(transactionHash);
    if (receipt.status !== 1) {
      this.logger.error(`An error occurred while getting transaction receipt [${transactionHash}] on Polygon, error ${JSON.stringify(receipt)}`);
      return;
    }
    return receipt;
  }
  decodeTransactionInput(ABI, data) {
    for (const abiElement of ABI) {
      if (abiElement.type === 'event' || abiElement.type === 'constructor') {
        continue;
      }
      try {
        const method = abiElement.name;
        const ifc = new abi_1.Interface([]);
        const values = ifc.decodeFunctionData(abi_1.FunctionFragment.fromObject(abiElement), data);
        return {
          method: method,
          values: values
        };
      } catch (err) {}
    }
  }
  decodeEventInput(ABI, log) {
    try {
      const ifc = new abi_1.Interface(ABI);
      return ifc.parseLog(log);
    } catch (err) {}
  }
}
exports.BaseNetworkService = BaseNetworkService;
