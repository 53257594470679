"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CROWDSWAP = void 0;
exports.CROWDSWAP = [{
  inputs: [{
    components: [{
      internalType: 'uint256',
      name: 'flag',
      type: 'uint256'
    }, {
      internalType: 'address',
      name: 'adr',
      type: 'address'
    }],
    internalType: 'struct Crowdswap.DexAddress[]',
    name: 'dexAddresses',
    type: 'tuple[]'
  }],
  stateMutability: 'nonpayable',
  type: 'constructor'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'contract IERC20',
    name: '_destToken',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountOut',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'fee',
    type: 'uint256'
  }],
  name: 'SwapSucceedEvent',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'receiver',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'WithdrawBaseTokenSucceedEvent',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'contract IERC20',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'receiver',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'WithdrawTokenSucceedEvent',
  type: 'event'
}, {
  stateMutability: 'nonpayable',
  type: 'fallback'
}, {
  inputs: [{
    internalType: 'uint8',
    name: 'dexFlag',
    type: 'uint8'
  }, {
    internalType: 'address',
    name: 'dexAddress',
    type: 'address'
  }],
  name: 'addDexchange',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'dexchanges',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'getBalance',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'getFeePercentage',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_apeSwap',
    type: 'address'
  }],
  name: 'setApeSwapRouter',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_balancer',
    type: 'address'
  }],
  name: 'setBalancerExchangeProxy',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_bancor',
    type: 'address'
  }],
  name: 'setBancorContractRegistry',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_crowdAddress',
    type: 'address'
  }],
  name: 'setCrowdswapRouter',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'int256',
    name: '_feePercentage',
    type: 'int256'
  }],
  name: 'setFeePercentage',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_kyber',
    type: 'address'
  }],
  name: 'setKyberNetworkProxy',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_pancake',
    type: 'address'
  }],
  name: 'setPancakeRouter',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_quickSwap',
    type: 'address'
  }],
  name: 'setQuickswapV2Router02',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_sushiAddress',
    type: 'address'
  }],
  name: 'setSushiswapV2Router02',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_uniswapAddress',
    type: 'address'
  }],
  name: 'setUniswapV2Router02',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_uniswapAddress',
    type: 'address'
  }],
  name: 'setUniswapV3Router',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'contract IERC20',
    name: '_fromToken',
    type: 'address'
  }, {
    internalType: 'contract IERC20',
    name: '_destToken',
    type: 'address'
  }, {
    internalType: 'address payable',
    name: '_receiver',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amountIn',
    type: 'uint256'
  }, {
    internalType: 'uint8',
    name: '_dexFlag',
    type: 'uint8'
  }, {
    internalType: 'bytes',
    name: '_data',
    type: 'bytes'
  }],
  name: 'swap',
  outputs: [{
    internalType: 'uint256',
    name: 'returnAmount',
    type: 'uint256'
  }],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'withdrawAllBaseToken',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_token',
    type: 'address'
  }],
  name: 'withdrawAllToken',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }],
  name: 'withdrawBaseToken',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }],
  name: 'withdrawToken',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  stateMutability: 'payable',
  type: 'receive'
}];
