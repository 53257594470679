"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TokenDescription = void 0;
const tokens_1 = require("../tokens");
const abis_1 = require("../abis");
const pancake_master_chef_v2_1 = require("../abis/pancake-master-chef-v2");
const dexchanges_1 = require("../dexchanges");
class OpportunitiesHolder {
  constructor() {
    this._opportunityListArray = {};
  }
  get Opportunities() {
    return this._opportunityListArray;
  }
  get OpportunitiesData() {
    const TokenListBySymbol = tokens_1.TokensHolder.TokenListBySymbol;
    return {
      FarmOpportunities: {
        crowdStakeAbi: abis_1.LPSTAKINGREWARDS,
        beefyStakeAbi: abis_1.LPmiMaticUSDCSTAKING,
        pancakeStakeAbi: pancake_master_chef_v2_1.MASTERCHEFV2,
        crowdContractAbi: abis_1.CROWD_USDT_LP_STAKE_OPPORTUNITY,
        crowdContractAbiV2: abis_1.CROWD_USDT_LP_STAKE_OPPORTUNITY_V2,
        crowdContractAbiV2_1: abis_1.CROWD_USDT_LP_STAKE_OPPORTUNITY_V2_1,
        beefyContractAbi: abis_1.miMATIC_USDC_LP_STAKE_OPPORTUNITY,
        pancakeContractAbi: abis_1.CAKE_WBNB_LP_STAKE_OPPORTUNITY,
        stakeRewardAbi: abis_1.STAKINGREWARDS,
        lockedStakeRewardAbi: abis_1.LOCKED_STAKING_REWARDS
      },
      ETF: {
        name: 'ETF',
        opportunityType: 'etf',
        etfList: {
          ['BSC_ETF']: {
            chainId: 56,
            contractAddress: '0xAc4250372368e7d8a253C191bA317c03b222d571',
            nftAddress: '0x31550d9c579c4fF3A77fa0fdd59aEBF37E5f0a5d',
            investDexes: [],
            withdrawDexes: [],
            investPage: '',
            stakePage: '',
            observable: false,
            opportunityAdapterGasLimit: 800000,
            code: 0x52
          }
        },
        contractAbi: abis_1.ETFProxy,
        nftAbi: abis_1.ETFReceipt
      },
      PRESALE: {
        name: 'PRESALE',
        opportunityType: 'private-sale',
        networks: {
          137: {
            contractAddress: '0x7cBA7D6985BeD27C246a0395a7FC685d199FB0Bb',
            adapterContractAddress: '0xa5a1EdD75e8B98Be70C8c2f131427D0490B27f69',
            factoryAddress: '0xc4124959E9d471CF092eBCfc01eAd9C98Af43B8e',
            fractalContract: '0xa73084a9F71e1A4183Cf7A4Bf3cEDbDF46BeF61E',
            initCodeHash: '0xb40bd5ad61e5dcc3a5510a2a9faaebe8db0d4b59cfdb7db7e4316bbbdd28f4b0',
            wToken: TokenListBySymbol.POLYGON_MAINNET['USDC'],
            middleToken: TokenListBySymbol.POLYGON_MAINNET['USDC'],
            vestingType: 1,
            firstSwapDexes: [dexchanges_1.Dexchanges.Quickswap.name, dexchanges_1.Dexchanges.UniswapV3.name, dexchanges_1.Dexchanges.Sushiswap.name, dexchanges_1.Dexchanges.Apeswap.name, dexchanges_1.Dexchanges.Radioshack.name, dexchanges_1.Dexchanges.Crowdswap.name, dexchanges_1.Dexchanges.Curve.name]
          },
          56: {
            contractAddress: '0xA206E9dB17BEb17582e23eC6931fA4cbA048C398',
            adapterContractAddress: '0x4E9c69b021473397eb38A2cF5AAF75FAd9De177D',
            factoryAddress: '0xe78617aEf526d33192A7D11d5B37C723c0Fa42fD',
            fractalContract: '0x91562c86174656976E1a58f4eD02942Ac5a34e77',
            initCodeHash: '0xb40bd5ad61e5dcc3a5510a2a9faaebe8db0d4b59cfdb7db7e4316bbbdd28f4b0',
            wToken: TokenListBySymbol.BSCMAIN['DEUSDC'],
            middleToken: TokenListBySymbol.BSCMAIN['DEUSDC'],
            vestingType: 1,
            firstSwapDexes: [dexchanges_1.Dexchanges.Pancake.name, dexchanges_1.Dexchanges.Sushiswap.name, dexchanges_1.Dexchanges.Apeswap.name, dexchanges_1.Dexchanges.Biswap.name, dexchanges_1.Dexchanges.Babyswap.name, dexchanges_1.Dexchanges.Radioshack.name, dexchanges_1.Dexchanges.Crowdswap.name, dexchanges_1.Dexchanges.Curve.name]
          },
          1: {
            contractAddress: '0xF04f4fA3FE1F4A65b21b2929112eD2195e6E470a',
            adapterContractAddress: '0xC5D6955AE0822898A327Bd009B69BDF12aEe2628',
            factoryAddress: '0x1d38181AE8892bd015727D0E3246B4dDDf339806',
            initCodeHash: '0xb40bd5ad61e5dcc3a5510a2a9faaebe8db0d4b59cfdb7db7e4316bbbdd28f4b0',
            wToken: TokenListBySymbol.MAINNET['USDC'],
            middleToken: TokenListBySymbol.MAINNET['USDC'],
            vestingType: 0,
            firstSwapDexes: [dexchanges_1.Dexchanges.Kyber.name, dexchanges_1.Dexchanges.Sushiswap.name, dexchanges_1.Dexchanges.UniswapV3.name, dexchanges_1.Dexchanges.UniswapV2.name, dexchanges_1.Dexchanges.Balancer.name, dexchanges_1.Dexchanges.Bancor.name, dexchanges_1.Dexchanges.Kyber.name, dexchanges_1.Dexchanges.Curve.name, dexchanges_1.Dexchanges.Crypto.name, dexchanges_1.Dexchanges.Radioshack.name]
          }
        },
        contractAbi: abis_1.PrivatesaleOpportunity,
        poolAbi1: abis_1.IconxOpportunity,
        poolAbi2: abis_1.IconxOpportunity2,
        factoryAbi: abis_1.PresaleFactory,
        investPage: 'opportunity/private-sale',
        stakePage: 'opportunity/private-sale-details',
        observable: false,
        opportunityAdapterGasLimit: 800000,
        code: 0x51,
        signMessage: 'I have read and agree to the CrowdSale terms & conditions',
        termsOfService: `Terms of Access:

    PLEASE READ THESE TERMS OF ACCESS CAREFULLY. UPON YOUR APPROVAL OF THE RELEVANT BLOCKCHAIN TRANSACTION (ALSO KNOWN AS TERMS-APPROVING TRANSACTION) OR BY USING OR ACCESSING OUR PLATFORM, AS DEFINED BELOW, YOU AGREE TO BE LEGALLY BOUND BY THESE TERMS OF ACCESS AND ALL TERMS, POLICIES AND AGREEMENTS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE WITH THESE TERMS, PLEASE ABSTAIN FROM APPROVING THE TERMS-APPROVING TRANSACTION AND DO NOT USE OR ACCESS OUR PLATFORM. WE RESERVE THE RIGHT AT OUR SOLE DISCRETION, TO CHANGE, MODIFY, ADD OR REMOVE ANY PORTIONS OF THESE TERMS OF ACCESS AT ANY TIME. 
      
      WE DO NOT ACT AS YOUR BROKER-DEALER, INTERMEDIARY, UNDERWRITER,AGENT OR ANY KIND OF ADVISOR, INCLUDING COMMODITY TRADING ADVISOR.

      1. Acceptance and Eligibility 
      
      a. This is a contract between you and Smart DeFi Limited, a private company incorporated in the British Virgin Islands. References in these Terms to “CrowdSwap”, “we”, “our” or “us”, are to Smart DeFi Limited and references to “you”, “your”, “customer” or “user” are to the person with whom CrowdSwap enters into these Terms. For the purpose of these Terms, the term “you” and “your” shall include you as an individual and the relevant entity, when you are accepting these Terms on behalf of such entity and/or represent the such entity. 
            
      b. These Terms apply to your use and access to the Crowd-Sale Functionality (as defined below) of the Platform, relevant graphic user interfaces as well as several promotional and identity verification services that may be provided by us from time to time. Upon your approval of the relevant terms-approval blockchain transaction or requesting access to the Crowd-Sale Functionality, you agree to be legally bound by and to comply with these Terms, general Terms of Service, Privacy Policy and all additional terms, policies and agreements incorporated herein by reference and amended from time to time at our sole discretion. 
      
      c. By accepting these Terms, you represent and warrant that you have read, understood and accept these Terms and that you also have had the possibility to obtain advice related to these Terms from an attorney, legal advisor or any other relevant professional. 
      
      d. The Crowd-Sale Functionality, related graphic user interfaces, our side services and their usage are intended solely for i) individuals who are 18 or older and ii) entities that have the full legal capacity to contract, power and authority to enter into these Terms, and both individuals and entities shall satisfy the criteria described below in these Terms. 
      
      e. If you are an individual, you represent and warrant that you: (i) are of legal age to form a binding contract (at least 18 years old under the law of the British Virgin Islands); (ii) have not previously been suspended or removed from using the Platform, the Crowd-Sale Functionality, related graphic user interfaces or any of our services; (iii) are not identified as an individual who is restricted or barred from conducting banking or financial transactions, in any jurisdiction or under any applicable laws; (iv) are not placed on any list of suspicious persons banned from travelling or conducting business or financial transactions in any jurisdiction; (v) are not a citizen, a permanent resident or located at any Restricted Regions (as specified below); and (vi) have full power and authority to agree to these Terms.  
      
      f. If you represent an entity and accept these Terms on behalf of this entity, you represent and warrant that this entity: (i) is duly registered, validly existing and is in good standing under the Laws of its incorporation, (ii) has the full legal capacity to contract, power and authority to execute and deliver these Terms, and to perform its obligations hereunder, (iii) has not previously been suspended or removed from using the Platform, the Crowd-Sale Functionality, related graphic user interfaces or any of our services; (iv) is not established at, having its seat of incorporation at or carrying day-to-day management from any of the Restricted Regions; and (v) is not in violation of its current certificate of incorporation or bylaws, any material statute, rule, or regulation applicable to this entity.  
      
      g. If you accept these Terms on behalf of an entity, you represent to us that you are duly authorised to bind that business or entity to these Terms, and that entity accepts these Terms. You shall be fully liable for all activities conducted by your employees, agents while using the Crowd-Sale Functionality. You hereby confirm that the performance and consummation of the transactions contemplated by these Terms do not and will not: (i) violate any material judgment, statute, rule, or regulation applicable to the entity; (ii) result in the acceleration of any material debt or contract to which the entity is a party or by which it is bound; or (iii) result in the creation or imposition of any lien on any property, asset or revenue of the entity or the suspension, forfeiture, or non-renewal of any material permit, license or authorisation applicable to the entity, its business or operations.  
      
      h. In addition to the above, you must have the necessary equipment and the associated telecommunication service subscriptions to access the internet. Access to the Crowd-Sale Functionality may become degraded or unavailable during times of significant volatility or volume. This could result in the inability to access any services, supported by decentralised Protocols, Gateways, liquidity pools, including Crowd-Sale Pools (as defined below), smart contracts and may also lead to technical support-related time delays. We shall not be liable for any losses resulting from or arising out of any such delays.  
      
      2. Definitions  
      
      a. “Affiliate” shall mean with respect to any party, any person or entity that directly or indirectly, now or hereafter owns or controls, is owned or controlled by, or is under common control or ownership with that party, where “control” is defined as directly or indirectly possessing the power to direct or cause the direction of the management and policies of an entity or person, whether through ownership of voting securities, by contract or otherwise.  
      
      b. “Gateways” shall mean decentralised gateways (also known as bridges) between different blockchain networks and related smart contracts.  
      
      c. “Law” shall mean any federal, national, state, local statutes, laws, regulations, treaties, ordinances, rules, regulatory guidance, judgments, directives, orders, decrees and determinations of (or agreements with), and mandatory written direction from (or agreements with), a governmental authority, in each case as the same may be amended and in effect from time to time.  
      
      d. “Platform” shall mean the platform that provides the graphic user interface to interact with decentralised Crowd-Sale Protocols, decentralised swap, aggregating, routing and/or cross-chain liquidity Protocols, Gateways, related liquidity pools and smart contracts.  
      
      e. “Harmful Code” shall mean any computer instructions, circuitry, or other means whose purpose or intended effect is to (i) disrupt, disable, harm, or otherwise impede in any manner the operation of any component of the System (sometimes referred to as “viruses” or “worms), (ii) disable or impair in any way the operation of any component of the System, or (iii) permit unauthorised access to any component of the System (sometimes referred to as “traps”, “back doors”, “access codes” or “trap door” devices), and any other harmful, malicious, hidden, or self-replicating procedures, refer purchasers of your Tokens to us for settling or making claims related to any matters connected to the sale of Tokens. You acknowledge, agree, and understand that we are not a party to the relationship, transactions or any dealings between you and purchasers of your Tokens. We do not introduce you to the purchasers or vice versa or select your Tokens for our users. 
       
      i. It is your sole responsibility to ensure that the Crowd-Sale Functionality you intend to use meet your requirements. You hereby acknowledge and agree that (i) the Crowd-Sale Functionality has not been designed to meet any individual requirements; (ii) the operation of the Crowd-Sale Functionality may from time to time encounter technical or other problems and may not necessarily continue uninterrupted or without errors.  
       
      j. In addition, we may provide you with side services, including promotional and identity verification services, in relation to the promotion of your Tokens and/or verification of purchasers of your Tokens. We will notify you of applicable terms and conditions for our side services and fees for such side services.  
      
      4. Marketing and Technical Cooperation  
      
      a. We may provide you with technical assistance and guidance related to the Crowd-Sale Functionality and creation of Crowd-Sale Pools in the scope determined by us at our sole discretion. You hereby agree to follow any technical guidance and advice provided by us.  
      
      b. Both parties (we and you) hereby agree to engage in joint marketing and promotion of the Platform, the Token and collaboration between parties as further agreed by the parties in detail, provided that any related marketing strategies and plans shall be approved by both parties in advance.  
      
      c. Both parties agree to cooperate in the creation of cross-branded marketing and promotional materials, including, but not limited to, newsletters, brochures, flyers, banners, posts on social media platforms, videos, graphics, provided that each party shall obtain prior approval from the second party before uploading or making public of any created materials.  
      
      d. You hereby acknowledge and agree that we would engage in joint marketing and promotion of the Token based on your representation and warranties as set in these Terms and based on the information and assurances provided by you on the legal qualification of your Token. any such determination with respect to your interaction with such Protocols, liquidity pools and smart contracts.  
      
      6. Fees. Taxes  
      
      a. In consideration of your access to the Crowd-Sale Functionality, you shall pay us ten percent (10%) of the total proceeds from the sale of your Tokens through Crowd-Sale Pools (“Functionality Fee”), provided that the parties (we and you) may agree in writing to set the different amount of Functionality Fee. The Functionality Fee shall be due and payable immediately upon completion of the sale of your Tokens and prior to transferring such proceeds to you. You acknowledge and agree that the Crowd-Sale Protocol may be designed in the manner to deduct the Functionality Fee from the total proceeds from the sale of your Tokens.  
      
      b. You shall be aware that blockchain networks, distributed ledgers or any decentralised networks and/or their designated third-party suppliers may take fees or commissions incurred by your activities within these blockchain networks, ledgers or networks, including but not limited to, transactions fees. For example, the Ethereum blockchain network or Binance Smart Chain blockchain network may require you to pay a fee, commonly known as the gas fee, for the computational resources required to perform a transaction on the corresponding blockchain network. We are not and will never be responsible for commissions of any blockchain networks, ledgers or decentralised networks or any third parties even accessed the Crowd-Sale Functionality or the Platform.  
      
      c. You acknowledge and agree that we have no control over: (a) any transactions via blockchain networks, including the Ethereum blockchain network and Binance Smart Chain blockchain network; (b) the method of payment of an applicable gas fee; or (c) any of your actual payments of gas fees. Accordingly, while interacting with Crowd-Sale Protocols you shall ensure that you have a sufficient balance of ETH (native core token of the Ethereum blockchain network), BNB (native core token of the Binance Smart Chain blockchain network) or any other native core token of a supported blockchain network kept at your blockchain wallet to complete any transaction on the respective blockchain network.  
      
      d. You shall be fully responsible for the taxes from the sale of your Tokens through Crowd-Sale Pools. If such taxes are due from us, we shall be responsible for payment to the government agency, however, you shall reimburse us for any such charges, excluding any taxes on the income or assets of us within two (2) days from the day you are notified by us. 
      
      7. Identity Verification  
      
      a. You hereby acknowledge and agree that your access to the Crowd-Sale Functionality is subject to the “know your client” (KYC) procedure in the scope designated by us. You shall provide us or our contractor that performs identity verification (“KYC Provider”) information and documents as requested by us or our KYC Provider within fifteen (15) business days from the date of such request.  
      
      b. For the purpose of your identification as an individual or identification of your entity’s directors and ultimate beneficial owners (when you act on behalf of an entity), we or our KYC Provider may request from you the following information and documents: first name, last name, address, telephone number, e-mail address, date of birth, taxpayer identification number, a government identification, and, if applicable, information regarding your bank account (such as the name of the bank, the account type, routing number or SWIFT, and account number), driving license or any other national ID, internal or international passport, bank statement, utility bill, tax document and/or other documents that we consider necessary for your identification. We or our KYC provider may request video identification or the selfie with a specified ID or passport from you or your entity’s directors and ultimate beneficial owners.  
      
      c. When you act on behalf of an entity, in addition, we or our KYC provider may request the following information and documents: business name of the entity, registry code or registration number and the date of registration; ID of the shareholders (same as for the identification of individuals), the ID of the director(s) and/or members of the management board (same as for the identification of individuals), ID’s of the representatives (same as for the identification of individuals), proof of the registered office/seat of the entity, ID’s of the beneficial owners (same as for the identification of individuals), proof of the registered office/seat of the entity, certificate of good standing, proof of representation and powers, articles of association and/or other information and documents that we or our KYC Provider consider necessary.  
      
      d. The documents submitted to us or our KYC Provider (other than driving license, national ID, internal or international passport) should be issued no more than three months prior to the date of identification and should list the name (entity name) and the current address of the individual or the entity. By providing us or our KYC Provider with this or any other information that may be required, you confirm that the information is accurate and authentic.  
      
      e. You hereby permit us and our KYC Provider to keep a record of the information provided for the KYC procedure.  

      f. You hereby authorise us and our KYC Provider to make inquiries, whether directly or through third parties, that we and our KYC Provider consider necessary to verify the identity of you as an individual, your entity and its directors and ultimate beneficial owners, to ensure enforcement of the geographic restrictions or protect us against fraud or other financial crime, and to take action we reasonably deem necessary based on the results of such inquiries.  
      
      g. We shall have the right to verify your, your entity’s and its directors’ and ultimate beneficial owners’ identity on an ongoing basis. We reserve the right to request up-to-date documents from you, even though you, your entity or its directors and ultimate beneficial owners’ identity have passed identity verification in the past.  
      
      h. You hereby provide us and our KYC Provider the right to store and process your, your entity’s and involved directors’, employees’ or ultimate beneficial owners’ personal data provided to us and our KYC Provider in compliance with the applicable law on personal data protection. We will not disclose any submitted identifying information, except if the disclosure is required under laws or in relation to a decision of the governmental authority.
      
      8. Your Representations and Warranties
      
      You hereby represent and warrant, as of the date of your acceptance of these Terms, and throughout the whole term of these Terms, that:

      a. you and any of your Affiliates will not offer, promise, grant rights in or pay, directly or indirectly, to Tokens holders any dividends, distribute any shares, stocks, debentures, bonds or any other assets, will not offer, promise, distribute or share any profits or revenues, either directly or through decentralised pools;
      
      b. you and any of your Affiliates will not offer, promise or grant, directly or indirectly, to Tokens holders any kind of the right to convert the Token into shares of your entity or entity’s Affiliates, any kind of ownership in the System or your any intellectual property, products, software, projects or other your equity or equity of your entity or such entity’s Affiliates.`
      }
    };
  }
  FillAllOpportunities(opportunities) {
    this._opportunityListArray = {};
    opportunities.forEach(item => {
      this._opportunityListArray[item.name] = item;
    });
  }
}
exports.TokenDescription = {
  USDT: 'This token is a fully collateralized US dollar stablecoin. USDT is issued by regulated financial institutions, backed by fully reserved assets, redeemable on a 1:1 basis for US dollars.',
  USDC: 'This token is a fully collateralized US dollar stablecoin. USDC is issued by regulated financial institutions, backed by fully reserved assets, redeemable on a 1:1 basis for US dollars.',
  MIMATIC: "This token is a stable coin collateralized by your MATIC holdings. It's powered by Qi Dao, a protocol that enables any cryptocurrency community to create stablecoins backed by their native tokens.",
  WBNB: 'BNB Coin is a cryptocurrency that is used primarily to pay transaction and trading fees on the BSC network.',
  CAKE: 'The PancakeSwap token, CAKE, is a BEP20 token that originally launched on Binance Smart Chain. The main function of CAKE is to incentivize the liquidity provision to the PancakeSwap platform.',
  BUSD: 'BUSD is a 1:1 USD-backed stablecoin approved by the New York State Department of Financial Services (NYDFS) and issued in partnership with Paxos.',
  WOM: 'The WOM (word-of-mouth) Protocol is a blockchain-based marketing technology that gives brands, content creators, publishers, and social networks a way to monetize word-of-mouth recommendations on any app or platform.',
  RACA: "RACA is a Metaverse platform for game studios. In July 2021, RACA released its first web 3.0 product, a collection of PFP (profile picture style NFTs) for Maye Musk (Elon's mother).",
  INJ: 'Injective is an open interoperable smart contracts platform optimized for decentralized finance applications.',
  WMX: 'Wombex is a yield aggregation and veToken accumulation protocol built on top of Wombat.',
  ETH: 'Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.'
};
exports.default = new OpportunitiesHolder();
