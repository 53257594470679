"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GasPriceHolder = void 0;
const ethers_1 = require("ethers");
class GasPriceHolder {
  constructor() {
    this.gasses = {};
  }
  async CacheGasPricesForAllNetworks() {
    const gasUrl = GasPriceHolder.NetworkServiceURL + '/api/v1/gas';
    const response = await fetch(gasUrl);
    if (response.status == 200) {
      const data = await response.json();
      this.gasses = data;
    }
  }
  getGasPrice(chainId) {
    const gasForChain = this.gasses[chainId];
    if (gasForChain) {
      return ethers_1.BigNumber.from(gasForChain.gas);
    }
    return undefined;
  }
}
exports.GasPriceHolder = GasPriceHolder;
GasPriceHolder.Instance = new GasPriceHolder();
