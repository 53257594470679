"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LPmiMaticUSDCSTAKING = void 0;
exports.LPmiMaticUSDCSTAKING = [{
  inputs: [{
    internalType: 'contract IStrategy',
    name: '_strategy',
    type: 'address'
  }, {
    internalType: 'string',
    name: '_name',
    type: 'string'
  }, {
    internalType: 'string',
    name: '_symbol',
    type: 'string'
  }, {
    internalType: 'uint256',
    name: '_approvalDelay',
    type: 'uint256'
  }],
  stateMutability: 'nonpayable',
  type: 'constructor'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'owner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'spender',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'value',
    type: 'uint256'
  }],
  name: 'Approval',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'NewStratCandidate',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'from',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'to',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'value',
    type: 'uint256'
  }],
  name: 'Transfer',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'UpgradeStrat',
  type: 'event'
}, {
  inputs: [{
    internalType: 'address',
    name: 'owner',
    type: 'address'
  }, {
    internalType: 'address',
    name: 'spender',
    type: 'address'
  }],
  name: 'allowance',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'approvalDelay',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'spender',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'approve',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'available',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'balance',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'balanceOf',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'decimals',
  outputs: [{
    internalType: 'uint8',
    name: '',
    type: 'uint8'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'spender',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'subtractedValue',
    type: 'uint256'
  }],
  name: 'decreaseAllowance',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }],
  name: 'deposit',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'depositAll',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'earn',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'getPricePerFullShare',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_token',
    type: 'address'
  }],
  name: 'inCaseTokensGetStuck',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'spender',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'addedValue',
    type: 'uint256'
  }],
  name: 'increaseAllowance',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'name',
  outputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_implementation',
    type: 'address'
  }],
  name: 'proposeStrat',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'renounceOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'stratCandidate',
  outputs: [{
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'proposedTime',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'strategy',
  outputs: [{
    internalType: 'contract IStrategy',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'symbol',
  outputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'totalSupply',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'recipient',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'transfer',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'sender',
    type: 'address'
  }, {
    internalType: 'address',
    name: 'recipient',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'transferFrom',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'upgradeStrat',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'want',
  outputs: [{
    internalType: 'contract IERC20',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_shares',
    type: 'uint256'
  }],
  name: 'withdraw',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'withdrawAll',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}];
