"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconxOpportunity2 = void 0;
exports.IconxOpportunity2 = [{
  inputs: [{
    internalType: 'address',
    name: '_owner',
    type: 'address'
  }, {
    internalType: 'string',
    name: '_presaleName',
    type: 'string'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'month',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'value',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.Ruleset[]',
    name: '_rulesetList',
    type: 'tuple[]'
  }, {
    internalType: 'uint256[]',
    name: '_spots',
    type: 'uint256[]'
  }, {
    internalType: 'contract IERC20',
    name: '_investToken',
    type: 'address'
  }, {
    internalType: 'contract IERC20',
    name: '_presaleToken',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_operator',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_presaleProxy',
    type: 'address'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'price',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.PriceStage[]',
    name: '_priceStages',
    type: 'tuple[]'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'startDate',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'tgeDate',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'endDate',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'hardCap',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'softCap',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'minInvest',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'firstHoursBestPrice',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'presaleTokenDecimal',
      type: 'uint256'
    }, {
      internalType: 'enum PresaleTypes.VestingState',
      name: 'vestingState',
      type: 'uint8'
    }],
    internalType: 'struct PresaleTypes.PoolParams',
    name: '_poolParams',
    type: 'tuple'
  }],
  stateMutability: 'nonpayable',
  type: 'constructor'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'PurchasePreSaleToken',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'TokensReleased',
  type: 'event'
}, {
  inputs: [],
  name: 'ONE_MONTH',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_account',
    type: 'address'
  }],
  name: 'buyTicket',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'getIncentiveStruct',
  outputs: [{
    internalType: 'enum PresaleTypes.IncentiveState',
    name: '',
    type: 'uint8'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'percentage',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.IncentiveStruct[]',
    name: '',
    type: 'tuple[]'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'percentage',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.IncentiveStruct[]',
    name: '',
    type: 'tuple[]'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'getPoolDetail',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'startDate',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'tgeDate',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'endDate',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'hardCap',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'softCap',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'minInvest',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'firstHoursBestPrice',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'presaleTokenDecimal',
      type: 'uint256'
    }, {
      internalType: 'enum PresaleTypes.VestingState',
      name: 'vestingState',
      type: 'uint8'
    }],
    internalType: 'struct PresaleTypes.PoolParams',
    name: '',
    type: 'tuple'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'price',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.PriceStage[]',
    name: '',
    type: 'tuple[]'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'price',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.PriceStage',
    name: '',
    type: 'tuple'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'month',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'value',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.Ruleset[]',
    name: '',
    type: 'tuple[]'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_investor',
    type: 'address'
  }],
  name: 'getReleasable',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'getTicket',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'getTickets',
  outputs: [{
    internalType: 'uint256[]',
    name: '',
    type: 'uint256[]'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'incentiveState',
  outputs: [{
    internalType: 'enum PresaleTypes.IncentiveState',
    name: '',
    type: 'uint8'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'investToken',
  outputs: [{
    internalType: 'contract IERC20',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'investedAmount',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'investorIndex',
  outputs: [{
    internalType: 'uint256',
    name: 'investorShare',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'released',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'balance',
    type: 'uint256'
  }, {
    internalType: 'enum PresaleTypes.VestingType',
    name: 'vestingType',
    type: 'uint8'
  }, {
    internalType: 'enum PresaleTypes.InvestorState',
    name: 'state',
    type: 'uint8'
  }, {
    internalType: 'bool',
    name: 'exist',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'investors',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_investor',
    type: 'address'
  }],
  name: 'nextUserRelease',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'operator',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'poolParams',
  outputs: [{
    internalType: 'uint256',
    name: 'startDate',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'tgeDate',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'endDate',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'hardCap',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'softCap',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'minInvest',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'firstHoursBestPrice',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'presaleTokenDecimal',
    type: 'uint256'
  }, {
    internalType: 'enum PresaleTypes.VestingState',
    name: 'vestingState',
    type: 'uint8'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'presaleName',
  outputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'presaleProxy',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'presaleToken',
  outputs: [{
    internalType: 'contract IERC20',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'presaleTokenIncentiveStructList',
  outputs: [{
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'percentage',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'priceStage',
  outputs: [{
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'price',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'priceStages',
  outputs: [{
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'price',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: '_account',
    type: 'address'
  }],
  name: 'purchasePreSaleToken',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'release',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'rulesetList',
  outputs: [{
    internalType: 'uint256',
    name: 'month',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'value',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'enum PresaleTypes.IncentiveState',
    name: '_incentiveState',
    type: 'uint8'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'percentage',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.IncentiveStruct[]',
    name: '_stableTokenIncentiveStructList',
    type: 'tuple[]'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'percentage',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.IncentiveStruct[]',
    name: '_presaleTokenIncentiveStructList',
    type: 'tuple[]'
  }],
  name: 'setIncentiveStruct',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_operator',
    type: 'address'
  }],
  name: 'setOperator',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'contract IERC20',
    name: 'presaleToken_',
    type: 'address'
  }],
  name: 'setPresaleToken',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'contract_',
    type: 'address'
  }],
  name: 'setProxyContract',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'uint256',
      name: 'month',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'value',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.Ruleset[]',
    name: '_rulesetList',
    type: 'tuple[]'
  }],
  name: 'setRulesetList',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'setStateToFailure',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_date',
    type: 'uint256'
  }],
  name: 'setTgeDate',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'spots',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'stableTokenIncentiveStructList',
  outputs: [{
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'percentage',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'state',
  outputs: [{
    internalType: 'enum PresaleTypes.PresaleState',
    name: '',
    type: 'uint8'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'totalInvestorShare',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'totalOperatorShare',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'updatePool',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'withdrawInvestedToken',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'withdrawInvestmentsByBeneficiary',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}];
