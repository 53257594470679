import { versionedHashVersionKzg } from '../constants/kzg.js';
import { BaseError } from './base.js';
export class BlobSizeTooLargeError extends BaseError {
  constructor({
    maxSize,
    size
  }) {
    super('Blob size is too large.', {
      metaMessages: [`Max: ${maxSize} bytes`, `Given: ${size} bytes`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'BlobSizeTooLargeError'
    });
  }
}
export class EmptyBlobError extends BaseError {
  constructor() {
    super('Blob data must not be empty.');
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'EmptyBlobError'
    });
  }
}
export class InvalidVersionedHashSizeError extends BaseError {
  constructor({
    hash,
    size
  }) {
    super(`Versioned hash "${hash}" size is invalid.`, {
      metaMessages: ['Expected: 32', `Received: ${size}`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidVersionedHashSizeError'
    });
  }
}
export class InvalidVersionedHashVersionError extends BaseError {
  constructor({
    hash,
    version
  }) {
    super(`Versioned hash "${hash}" version is invalid.`, {
      metaMessages: [`Expected: ${versionedHashVersionKzg}`, `Received: ${version}`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidVersionedHashVersionError'
    });
  }
}
