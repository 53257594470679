"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CrowdToken = void 0;
const sdk_1 = require("@crowdswap/sdk");
class CrowdToken extends sdk_1.Token {
  constructor(chainId, address, decimals, symbol, name, price, balance, asset) {
    super(chainId, address, decimals, symbol, name);
    price ? this.price = price : this.price = '0';
    balance ? this.balance = balance : this.balance = '0';
    asset ? this.value = asset : this.value = '0';
  }
}
exports.CrowdToken = CrowdToken;
