"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Conversion = void 0;
const utils_1 = require("ethers/lib/utils");
const ethers_1 = require("ethers");
const sdk_1 = require("@crowdswap/sdk");
class Conversion {
  // input -> input * 10^decimal
  static convertStringToDecimal(input, decimal = 18) {
    // prevent fractional component exceeds decimals error
    input = Conversion.adjustFraction(input, decimal);
    return (0, utils_1.parseUnits)(input, decimal);
  }
  // input * 10^decimal -> input
  static convertStringFromDecimal(input, decimal = 18) {
    return (0, utils_1.formatUnits)(input.toString(), decimal);
  }
  static toSafeBigNumberByAddingFraction(input) {
    return (0, utils_1.parseUnits)(input, input.indexOf(Conversion.fractionPoint) < 0 ? 0 : input.length - input.indexOf(Conversion.fractionPoint) - 1);
  }
  static toSafeBigNumberByRemovingFraction(input) {
    input = input.toString();
    const dotPosition = input.indexOf(Conversion.fractionPoint);
    return ethers_1.BigNumber.from(input.substring(0, dotPosition < 0 ? input.length : dotPosition));
  }
  static toRawBigNumber(input) {
    return '0x' + sdk_1.JSBI.BigInt(input).toString(16);
  }
  static adjustFraction(input, fractionLength = 2, extraZero = 2) {
    if (input === undefined) {
      return;
    }
    input = input.toString();
    let pointIndex = input.indexOf(Conversion.fractionPoint);
    if (pointIndex < 0) {
      input = input.concat('.00');
      pointIndex = input.indexOf(Conversion.fractionPoint);
    }
    while (input.substring(pointIndex, input.length - 1).length < extraZero) {
      input = input.concat('0');
    }
    return input.substring(0, pointIndex + fractionLength + 1);
  }
  static replaceSeparator(input) {
    if (!input) {
      return;
    }
    return input.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
  // converts number to string representation.
  // after the decimal place, rounding if necessary.
  static numFormatter(num) {
    if (num >= 1000 && num < 1000000) {
      return (num / 1000).toFixed(2) + 'K';
    } else if (num >= 1000000 && num < 1000000000) {
      return (num / 1000000).toFixed(2) + 'M';
    } else if (num >= 1000000000) {
      return (num / 1000000000).toFixed(2) + 'B';
    } else if (num < 1000) {
      return num.toFixed(2); // if value < 1000, nothing to do
    }
  }
  // get true when estimation is valid.
  // if less than it's not valid
  static validEstimate(amountIn, amountOut, tokenInPrice, tokenOutPrice) {
    if (+amountIn / +amountOut < 500 * tokenInPrice.toNumber() / tokenOutPrice.toNumber()) {
      return true;
    }
    return false;
  }
}
exports.Conversion = Conversion;
Conversion.fractionPoint = '.';
