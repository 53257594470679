"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MULTICHAIN_ANYCALL_V6 = void 0;
exports.MULTICHAIN_ANYCALL_V6 = [{
  inputs: [{
    internalType: 'address',
    name: '_admin',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_mpc',
    type: 'address'
  }, {
    internalType: 'uint128',
    name: '_premium',
    type: 'uint128'
  }, {
    internalType: 'uint256',
    name: '_mode',
    type: 'uint256'
  }],
  stateMutability: 'nonpayable',
  type: 'constructor'
}, {
  inputs: [],
  name: 'APPMODE_USE_CUSTOM_SRC_FEES',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'FLAG_MERGE_CONFIG_FLAGS',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'FLAG_PAY_FEE_ON_SRC',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'FREE_MODE',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'PERMISSIONLESS_MODE',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'accruedFees',
  outputs: [{
    internalType: 'uint128',
    name: '',
    type: 'uint128'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_to',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: '_data',
    type: 'bytes'
  }, {
    internalType: 'address',
    name: '_fallback',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_toChainID',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_flags',
    type: 'uint256'
  }],
  name: 'anyCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_to',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: '_data',
    type: 'bytes'
  }, {
    internalType: 'address',
    name: '_fallback',
    type: 'address'
  }, {
    internalType: 'string',
    name: '_appID',
    type: 'string'
  }, {
    components: [{
      internalType: 'bytes32',
      name: 'txhash',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'from',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: 'fromChainID',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'nonce',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'flags',
      type: 'uint256'
    }],
    internalType: 'struct AnyCallV6ProxyTest.RequestContext',
    name: '_ctx',
    type: 'tuple'
  }],
  name: 'anyExec',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }],
  name: 'appConfig',
  outputs: [{
    internalType: 'address',
    name: 'app',
    type: 'address'
  }, {
    internalType: 'address',
    name: 'appAdmin',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'appFlags',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }, {
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'appCustomModes',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }],
  name: 'appDefaultModes',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }, {
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'appExecWhitelist',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }, {
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'appHistoryWhitelist',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'appIdentifier',
  outputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '_appID',
    type: 'string'
  }, {
    internalType: 'uint256',
    name: '_toChainID',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_dataLength',
    type: 'uint256'
  }],
  name: 'calcSrcFees',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bytes32',
    name: '_txhash',
    type: 'bytes32'
  }, {
    internalType: 'address',
    name: '_from',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_fromChainID',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_nonce',
    type: 'uint256'
  }],
  name: 'calcUniqID',
  outputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  stateMutability: 'pure',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_account',
    type: 'address'
  }],
  name: 'deposit',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  name: 'execCompleted',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'executionBudget',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'executor',
  outputs: [{
    internalType: 'contract AnyCallExecutor',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '_appID',
    type: 'string'
  }, {
    internalType: 'uint256',
    name: '_toChainID',
    type: 'uint256'
  }],
  name: 'isUseCustomSrcFees',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'minReserveBudget',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'mode',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'mpc',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bytes32',
    name: '_txhash',
    type: 'bytes32'
  }, {
    internalType: 'address',
    name: '_from',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_fromChainID',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_nonce',
    type: 'uint256'
  }],
  name: 'retryExec',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  name: 'retryExecRecords',
  outputs: [{
    internalType: 'address',
    name: 'to',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }, {
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'srcCustomFees',
  outputs: [{
    internalType: 'uint256',
    name: 'baseFees',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'feesPerByte',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'srcDefaultFees',
  outputs: [{
    internalType: 'uint256',
    name: 'baseFees',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'feesPerByte',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }],
  name: 'withdraw',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'withdrawAccruedFees',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}];
