"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatBytes32String = exports.Utf8ErrorFuncs = exports.toUtf8String = exports.toUtf8CodePoints = exports.toUtf8Bytes = exports._toEscapedUtf8String = exports.nameprep = exports.hexDataSlice = exports.hexDataLength = exports.hexZeroPad = exports.hexValue = exports.hexStripZeros = exports.hexConcat = exports.isHexString = exports.hexlify = exports.base64 = exports.base58 = exports.TransactionDescription = exports.LogDescription = exports.Interface = exports.SigningKey = exports.HDNode = exports.defaultPath = exports.isBytesLike = exports.isBytes = exports.zeroPad = exports.stripZeros = exports.concat = exports.arrayify = exports.shallowCopy = exports.resolveProperties = exports.getStatic = exports.defineReadOnly = exports.deepCopy = exports.checkProperties = exports.poll = exports.fetchJson = exports._fetchData = exports.RLP = exports.Logger = exports.checkResultErrors = exports.FormatTypes = exports.ParamType = exports.FunctionFragment = exports.EventFragment = exports.ErrorFragment = exports.ConstructorFragment = exports.Fragment = exports.defaultAbiCoder = exports.AbiCoder = void 0;
exports.Indexed = exports.Utf8ErrorReason = exports.UnicodeNormalizationForm = exports.SupportedAlgorithm = exports.mnemonicToSeed = exports.isValidMnemonic = exports.entropyToMnemonic = exports.mnemonicToEntropy = exports.getAccountPath = exports.verifyTypedData = exports.verifyMessage = exports.recoverPublicKey = exports.computePublicKey = exports.recoverAddress = exports.computeAddress = exports.getJsonWalletAddress = exports.TransactionTypes = exports.serializeTransaction = exports.parseTransaction = exports.accessListify = exports.joinSignature = exports.splitSignature = exports.soliditySha256 = exports.solidityKeccak256 = exports.solidityPack = exports.shuffled = exports.randomBytes = exports.sha512 = exports.sha256 = exports.ripemd160 = exports.keccak256 = exports.computeHmac = exports.commify = exports.parseUnits = exports.formatUnits = exports.parseEther = exports.formatEther = exports.isAddress = exports.getCreate2Address = exports.getContractAddress = exports.getIcapAddress = exports.getAddress = exports._TypedDataEncoder = exports.id = exports.isValidName = exports.namehash = exports.hashMessage = exports.dnsEncode = exports.parseBytes32String = void 0;
var abi_1 = require("@ethersproject/abi");
Object.defineProperty(exports, "AbiCoder", {
  enumerable: true,
  get: function () {
    return abi_1.AbiCoder;
  }
});
Object.defineProperty(exports, "checkResultErrors", {
  enumerable: true,
  get: function () {
    return abi_1.checkResultErrors;
  }
});
Object.defineProperty(exports, "ConstructorFragment", {
  enumerable: true,
  get: function () {
    return abi_1.ConstructorFragment;
  }
});
Object.defineProperty(exports, "defaultAbiCoder", {
  enumerable: true,
  get: function () {
    return abi_1.defaultAbiCoder;
  }
});
Object.defineProperty(exports, "ErrorFragment", {
  enumerable: true,
  get: function () {
    return abi_1.ErrorFragment;
  }
});
Object.defineProperty(exports, "EventFragment", {
  enumerable: true,
  get: function () {
    return abi_1.EventFragment;
  }
});
Object.defineProperty(exports, "FormatTypes", {
  enumerable: true,
  get: function () {
    return abi_1.FormatTypes;
  }
});
Object.defineProperty(exports, "Fragment", {
  enumerable: true,
  get: function () {
    return abi_1.Fragment;
  }
});
Object.defineProperty(exports, "FunctionFragment", {
  enumerable: true,
  get: function () {
    return abi_1.FunctionFragment;
  }
});
Object.defineProperty(exports, "Indexed", {
  enumerable: true,
  get: function () {
    return abi_1.Indexed;
  }
});
Object.defineProperty(exports, "Interface", {
  enumerable: true,
  get: function () {
    return abi_1.Interface;
  }
});
Object.defineProperty(exports, "LogDescription", {
  enumerable: true,
  get: function () {
    return abi_1.LogDescription;
  }
});
Object.defineProperty(exports, "ParamType", {
  enumerable: true,
  get: function () {
    return abi_1.ParamType;
  }
});
Object.defineProperty(exports, "TransactionDescription", {
  enumerable: true,
  get: function () {
    return abi_1.TransactionDescription;
  }
});
var address_1 = require("@ethersproject/address");
Object.defineProperty(exports, "getAddress", {
  enumerable: true,
  get: function () {
    return address_1.getAddress;
  }
});
Object.defineProperty(exports, "getCreate2Address", {
  enumerable: true,
  get: function () {
    return address_1.getCreate2Address;
  }
});
Object.defineProperty(exports, "getContractAddress", {
  enumerable: true,
  get: function () {
    return address_1.getContractAddress;
  }
});
Object.defineProperty(exports, "getIcapAddress", {
  enumerable: true,
  get: function () {
    return address_1.getIcapAddress;
  }
});
Object.defineProperty(exports, "isAddress", {
  enumerable: true,
  get: function () {
    return address_1.isAddress;
  }
});
var base64 = __importStar(require("@ethersproject/base64"));
exports.base64 = base64;
var basex_1 = require("@ethersproject/basex");
Object.defineProperty(exports, "base58", {
  enumerable: true,
  get: function () {
    return basex_1.Base58;
  }
});
var bytes_1 = require("@ethersproject/bytes");
Object.defineProperty(exports, "arrayify", {
  enumerable: true,
  get: function () {
    return bytes_1.arrayify;
  }
});
Object.defineProperty(exports, "concat", {
  enumerable: true,
  get: function () {
    return bytes_1.concat;
  }
});
Object.defineProperty(exports, "hexConcat", {
  enumerable: true,
  get: function () {
    return bytes_1.hexConcat;
  }
});
Object.defineProperty(exports, "hexDataSlice", {
  enumerable: true,
  get: function () {
    return bytes_1.hexDataSlice;
  }
});
Object.defineProperty(exports, "hexDataLength", {
  enumerable: true,
  get: function () {
    return bytes_1.hexDataLength;
  }
});
Object.defineProperty(exports, "hexlify", {
  enumerable: true,
  get: function () {
    return bytes_1.hexlify;
  }
});
Object.defineProperty(exports, "hexStripZeros", {
  enumerable: true,
  get: function () {
    return bytes_1.hexStripZeros;
  }
});
Object.defineProperty(exports, "hexValue", {
  enumerable: true,
  get: function () {
    return bytes_1.hexValue;
  }
});
Object.defineProperty(exports, "hexZeroPad", {
  enumerable: true,
  get: function () {
    return bytes_1.hexZeroPad;
  }
});
Object.defineProperty(exports, "isBytes", {
  enumerable: true,
  get: function () {
    return bytes_1.isBytes;
  }
});
Object.defineProperty(exports, "isBytesLike", {
  enumerable: true,
  get: function () {
    return bytes_1.isBytesLike;
  }
});
Object.defineProperty(exports, "isHexString", {
  enumerable: true,
  get: function () {
    return bytes_1.isHexString;
  }
});
Object.defineProperty(exports, "joinSignature", {
  enumerable: true,
  get: function () {
    return bytes_1.joinSignature;
  }
});
Object.defineProperty(exports, "zeroPad", {
  enumerable: true,
  get: function () {
    return bytes_1.zeroPad;
  }
});
Object.defineProperty(exports, "splitSignature", {
  enumerable: true,
  get: function () {
    return bytes_1.splitSignature;
  }
});
Object.defineProperty(exports, "stripZeros", {
  enumerable: true,
  get: function () {
    return bytes_1.stripZeros;
  }
});
var hash_1 = require("@ethersproject/hash");
Object.defineProperty(exports, "_TypedDataEncoder", {
  enumerable: true,
  get: function () {
    return hash_1._TypedDataEncoder;
  }
});
Object.defineProperty(exports, "dnsEncode", {
  enumerable: true,
  get: function () {
    return hash_1.dnsEncode;
  }
});
Object.defineProperty(exports, "hashMessage", {
  enumerable: true,
  get: function () {
    return hash_1.hashMessage;
  }
});
Object.defineProperty(exports, "id", {
  enumerable: true,
  get: function () {
    return hash_1.id;
  }
});
Object.defineProperty(exports, "isValidName", {
  enumerable: true,
  get: function () {
    return hash_1.isValidName;
  }
});
Object.defineProperty(exports, "namehash", {
  enumerable: true,
  get: function () {
    return hash_1.namehash;
  }
});
var hdnode_1 = require("@ethersproject/hdnode");
Object.defineProperty(exports, "defaultPath", {
  enumerable: true,
  get: function () {
    return hdnode_1.defaultPath;
  }
});
Object.defineProperty(exports, "entropyToMnemonic", {
  enumerable: true,
  get: function () {
    return hdnode_1.entropyToMnemonic;
  }
});
Object.defineProperty(exports, "getAccountPath", {
  enumerable: true,
  get: function () {
    return hdnode_1.getAccountPath;
  }
});
Object.defineProperty(exports, "HDNode", {
  enumerable: true,
  get: function () {
    return hdnode_1.HDNode;
  }
});
Object.defineProperty(exports, "isValidMnemonic", {
  enumerable: true,
  get: function () {
    return hdnode_1.isValidMnemonic;
  }
});
Object.defineProperty(exports, "mnemonicToEntropy", {
  enumerable: true,
  get: function () {
    return hdnode_1.mnemonicToEntropy;
  }
});
Object.defineProperty(exports, "mnemonicToSeed", {
  enumerable: true,
  get: function () {
    return hdnode_1.mnemonicToSeed;
  }
});
var json_wallets_1 = require("@ethersproject/json-wallets");
Object.defineProperty(exports, "getJsonWalletAddress", {
  enumerable: true,
  get: function () {
    return json_wallets_1.getJsonWalletAddress;
  }
});
var keccak256_1 = require("@ethersproject/keccak256");
Object.defineProperty(exports, "keccak256", {
  enumerable: true,
  get: function () {
    return keccak256_1.keccak256;
  }
});
var logger_1 = require("@ethersproject/logger");
Object.defineProperty(exports, "Logger", {
  enumerable: true,
  get: function () {
    return logger_1.Logger;
  }
});
var sha2_1 = require("@ethersproject/sha2");
Object.defineProperty(exports, "computeHmac", {
  enumerable: true,
  get: function () {
    return sha2_1.computeHmac;
  }
});
Object.defineProperty(exports, "ripemd160", {
  enumerable: true,
  get: function () {
    return sha2_1.ripemd160;
  }
});
Object.defineProperty(exports, "sha256", {
  enumerable: true,
  get: function () {
    return sha2_1.sha256;
  }
});
Object.defineProperty(exports, "sha512", {
  enumerable: true,
  get: function () {
    return sha2_1.sha512;
  }
});
var solidity_1 = require("@ethersproject/solidity");
Object.defineProperty(exports, "solidityKeccak256", {
  enumerable: true,
  get: function () {
    return solidity_1.keccak256;
  }
});
Object.defineProperty(exports, "solidityPack", {
  enumerable: true,
  get: function () {
    return solidity_1.pack;
  }
});
Object.defineProperty(exports, "soliditySha256", {
  enumerable: true,
  get: function () {
    return solidity_1.sha256;
  }
});
var random_1 = require("@ethersproject/random");
Object.defineProperty(exports, "randomBytes", {
  enumerable: true,
  get: function () {
    return random_1.randomBytes;
  }
});
Object.defineProperty(exports, "shuffled", {
  enumerable: true,
  get: function () {
    return random_1.shuffled;
  }
});
var properties_1 = require("@ethersproject/properties");
Object.defineProperty(exports, "checkProperties", {
  enumerable: true,
  get: function () {
    return properties_1.checkProperties;
  }
});
Object.defineProperty(exports, "deepCopy", {
  enumerable: true,
  get: function () {
    return properties_1.deepCopy;
  }
});
Object.defineProperty(exports, "defineReadOnly", {
  enumerable: true,
  get: function () {
    return properties_1.defineReadOnly;
  }
});
Object.defineProperty(exports, "getStatic", {
  enumerable: true,
  get: function () {
    return properties_1.getStatic;
  }
});
Object.defineProperty(exports, "resolveProperties", {
  enumerable: true,
  get: function () {
    return properties_1.resolveProperties;
  }
});
Object.defineProperty(exports, "shallowCopy", {
  enumerable: true,
  get: function () {
    return properties_1.shallowCopy;
  }
});
var RLP = __importStar(require("@ethersproject/rlp"));
exports.RLP = RLP;
var signing_key_1 = require("@ethersproject/signing-key");
Object.defineProperty(exports, "computePublicKey", {
  enumerable: true,
  get: function () {
    return signing_key_1.computePublicKey;
  }
});
Object.defineProperty(exports, "recoverPublicKey", {
  enumerable: true,
  get: function () {
    return signing_key_1.recoverPublicKey;
  }
});
Object.defineProperty(exports, "SigningKey", {
  enumerable: true,
  get: function () {
    return signing_key_1.SigningKey;
  }
});
var strings_1 = require("@ethersproject/strings");
Object.defineProperty(exports, "formatBytes32String", {
  enumerable: true,
  get: function () {
    return strings_1.formatBytes32String;
  }
});
Object.defineProperty(exports, "nameprep", {
  enumerable: true,
  get: function () {
    return strings_1.nameprep;
  }
});
Object.defineProperty(exports, "parseBytes32String", {
  enumerable: true,
  get: function () {
    return strings_1.parseBytes32String;
  }
});
Object.defineProperty(exports, "_toEscapedUtf8String", {
  enumerable: true,
  get: function () {
    return strings_1._toEscapedUtf8String;
  }
});
Object.defineProperty(exports, "toUtf8Bytes", {
  enumerable: true,
  get: function () {
    return strings_1.toUtf8Bytes;
  }
});
Object.defineProperty(exports, "toUtf8CodePoints", {
  enumerable: true,
  get: function () {
    return strings_1.toUtf8CodePoints;
  }
});
Object.defineProperty(exports, "toUtf8String", {
  enumerable: true,
  get: function () {
    return strings_1.toUtf8String;
  }
});
Object.defineProperty(exports, "Utf8ErrorFuncs", {
  enumerable: true,
  get: function () {
    return strings_1.Utf8ErrorFuncs;
  }
});
var transactions_1 = require("@ethersproject/transactions");
Object.defineProperty(exports, "accessListify", {
  enumerable: true,
  get: function () {
    return transactions_1.accessListify;
  }
});
Object.defineProperty(exports, "computeAddress", {
  enumerable: true,
  get: function () {
    return transactions_1.computeAddress;
  }
});
Object.defineProperty(exports, "parseTransaction", {
  enumerable: true,
  get: function () {
    return transactions_1.parse;
  }
});
Object.defineProperty(exports, "recoverAddress", {
  enumerable: true,
  get: function () {
    return transactions_1.recoverAddress;
  }
});
Object.defineProperty(exports, "serializeTransaction", {
  enumerable: true,
  get: function () {
    return transactions_1.serialize;
  }
});
Object.defineProperty(exports, "TransactionTypes", {
  enumerable: true,
  get: function () {
    return transactions_1.TransactionTypes;
  }
});
var units_1 = require("@ethersproject/units");
Object.defineProperty(exports, "commify", {
  enumerable: true,
  get: function () {
    return units_1.commify;
  }
});
Object.defineProperty(exports, "formatEther", {
  enumerable: true,
  get: function () {
    return units_1.formatEther;
  }
});
Object.defineProperty(exports, "parseEther", {
  enumerable: true,
  get: function () {
    return units_1.parseEther;
  }
});
Object.defineProperty(exports, "formatUnits", {
  enumerable: true,
  get: function () {
    return units_1.formatUnits;
  }
});
Object.defineProperty(exports, "parseUnits", {
  enumerable: true,
  get: function () {
    return units_1.parseUnits;
  }
});
var wallet_1 = require("@ethersproject/wallet");
Object.defineProperty(exports, "verifyMessage", {
  enumerable: true,
  get: function () {
    return wallet_1.verifyMessage;
  }
});
Object.defineProperty(exports, "verifyTypedData", {
  enumerable: true,
  get: function () {
    return wallet_1.verifyTypedData;
  }
});
var web_1 = require("@ethersproject/web");
Object.defineProperty(exports, "_fetchData", {
  enumerable: true,
  get: function () {
    return web_1._fetchData;
  }
});
Object.defineProperty(exports, "fetchJson", {
  enumerable: true,
  get: function () {
    return web_1.fetchJson;
  }
});
Object.defineProperty(exports, "poll", {
  enumerable: true,
  get: function () {
    return web_1.poll;
  }
});
////////////////////////
// Enums
var sha2_2 = require("@ethersproject/sha2");
Object.defineProperty(exports, "SupportedAlgorithm", {
  enumerable: true,
  get: function () {
    return sha2_2.SupportedAlgorithm;
  }
});
var strings_2 = require("@ethersproject/strings");
Object.defineProperty(exports, "UnicodeNormalizationForm", {
  enumerable: true,
  get: function () {
    return strings_2.UnicodeNormalizationForm;
  }
});
Object.defineProperty(exports, "Utf8ErrorReason", {
  enumerable: true,
  get: function () {
    return strings_2.Utf8ErrorReason;
  }
});
