"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TokenType = void 0;
var TokenType;
(function (TokenType) {
  TokenType[TokenType["None"] = 0] = "None";
  TokenType[TokenType["Trending"] = 1] = "Trending";
  TokenType[TokenType["Practical"] = 2] = "Practical";
  TokenType[TokenType["Bookmark"] = 4] = "Bookmark"; // 0100
})(TokenType = exports.TokenType || (exports.TokenType = {}));
