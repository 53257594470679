"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PresaleFactory = void 0;
exports.PresaleFactory = [{
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'previousAdmin',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newAdmin',
    type: 'address'
  }],
  name: 'AdminChanged',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'beacon',
    type: 'address'
  }],
  name: 'BeaconUpgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'string',
    name: 'presaleName',
    type: 'string'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'presaleContract',
    type: 'address'
  }],
  name: 'Created',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint8',
    name: 'version',
    type: 'uint8'
  }],
  name: 'Initialized',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'Upgraded',
  type: 'event'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'contractArray',
  outputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '_presaleName',
    type: 'string'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'month',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'value',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.Ruleset[]',
    name: '_rulesetList',
    type: 'tuple[]'
  }, {
    internalType: 'uint256[]',
    name: '_spots',
    type: 'uint256[]'
  }, {
    internalType: 'contract IERC20',
    name: '_investToken',
    type: 'address'
  }, {
    internalType: 'contract IERC20',
    name: '_presaleToken',
    type: 'address'
  }, {
    internalType: 'contract IERC20',
    name: '_ticketToken',
    type: 'address'
  }, {
    internalType: 'string',
    name: '_fractalListId',
    type: 'string'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'price',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.PriceStage[]',
    name: '_priceStages',
    type: 'tuple[]'
  }, {
    components: [{
      internalType: 'uint256',
      name: 'startDate',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'tgeDate',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'endDate',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'hardCap',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'softCap',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'minInvest',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'firstHoursBestPrice',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'presaleTokenDecimal',
      type: 'uint256'
    }],
    internalType: 'struct PresaleTypes.PoolParams',
    name: '_poolParams',
    type: 'tuple'
  }],
  name: 'create',
  outputs: [{
    internalType: 'address',
    name: '_contractAddress',
    type: 'address'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '_preSaleName',
    type: 'string'
  }],
  name: 'getContract',
  outputs: [{
    internalType: 'address',
    name: '_contractAddress',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'getOperator',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'getPresaleProxy',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_operator',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_presaleProxy',
    type: 'address'
  }],
  name: 'initialize',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'proxiableUUID',
  outputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_operator',
    type: 'address'
  }],
  name: 'setOperator',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_presaleProxy',
    type: 'address'
  }],
  name: 'setPresaleProxy',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }],
  name: 'upgradeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  name: 'upgradeToAndCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}];
