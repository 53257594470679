"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OpportunityInvestmentTypeName = exports.OpportunityInvestmentType = void 0;
var OpportunityInvestmentType;
(function (OpportunityInvestmentType) {
  OpportunityInvestmentType[OpportunityInvestmentType["ByToken"] = 0] = "ByToken";
  OpportunityInvestmentType[OpportunityInvestmentType["ByTokenAAndTokenB"] = 1] = "ByTokenAAndTokenB";
  OpportunityInvestmentType[OpportunityInvestmentType["ByTokenAOrTokenB"] = 2] = "ByTokenAOrTokenB";
  OpportunityInvestmentType[OpportunityInvestmentType["ByLP"] = 3] = "ByLP";
  OpportunityInvestmentType[OpportunityInvestmentType["ByCrossChain"] = 4] = "ByCrossChain";
  OpportunityInvestmentType[OpportunityInvestmentType["ByPresale"] = 5] = "ByPresale";
  OpportunityInvestmentType[OpportunityInvestmentType["ByPresaleCrossChain"] = 6] = "ByPresaleCrossChain";
})(OpportunityInvestmentType = exports.OpportunityInvestmentType || (exports.OpportunityInvestmentType = {}));
exports.OpportunityInvestmentTypeName = {
  0: 'investByToken',
  1: 'investByTokenAAndTokenB',
  2: 'investByTokenAOrTokenB',
  3: 'investByLP',
  4: 'investByCrossChain'
};
