'use strict';

var toString = Object.prototype.toString;

// TypeError
exports.isArray = function (value, message) {
  if (!Array.isArray(value)) throw TypeError(message);
};
exports.isBoolean = function (value, message) {
  if (toString.call(value) !== '[object Boolean]') throw TypeError(message);
};
exports.isBuffer = function (value, message) {
  if (!Buffer.isBuffer(value)) throw TypeError(message);
};
exports.isFunction = function (value, message) {
  if (toString.call(value) !== '[object Function]') throw TypeError(message);
};
exports.isNumber = function (value, message) {
  if (toString.call(value) !== '[object Number]') throw TypeError(message);
};
exports.isObject = function (value, message) {
  if (toString.call(value) !== '[object Object]') throw TypeError(message);
};

// RangeError
exports.isBufferLength = function (buffer, length, message) {
  if (buffer.length !== length) throw RangeError(message);
};
exports.isBufferLength2 = function (buffer, length1, length2, message) {
  if (buffer.length !== length1 && buffer.length !== length2) throw RangeError(message);
};
exports.isLengthGTZero = function (value, message) {
  if (value.length === 0) throw RangeError(message);
};
exports.isNumberInInterval = function (number, x, y, message) {
  if (number <= x || number >= y) throw RangeError(message);
};