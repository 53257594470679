"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNUSUAL_MODE_PERCENTAGE = exports.BETTER_TRADE_LESS_HOPS_THRESHOLD = exports.ONE_HUNDRED_PERCENT = exports.ZERO_PERCENT = void 0;
const sdk_1 = require("@crowdswap/sdk");
exports.ZERO_PERCENT = new sdk_1.Percent('0');
exports.ONE_HUNDRED_PERCENT = new sdk_1.Percent('1');
exports.BETTER_TRADE_LESS_HOPS_THRESHOLD = new sdk_1.Percent('50', '10000');
exports.UNUSUAL_MODE_PERCENTAGE = 10;
