import { InvalidSerializableTransactionError } from '../../errors/transaction.js';
export function getTransactionType(transaction) {
  if (transaction.type) return transaction.type;
  if (typeof transaction.blobs !== 'undefined' || typeof transaction.blobVersionedHashes !== 'undefined' || typeof transaction.maxFeePerBlobGas !== 'undefined' || typeof transaction.sidecars !== 'undefined') return 'eip4844';
  if (typeof transaction.maxFeePerGas !== 'undefined' || typeof transaction.maxPriorityFeePerGas !== 'undefined') {
    return 'eip1559';
  }
  if (typeof transaction.gasPrice !== 'undefined') {
    if (typeof transaction.accessList !== 'undefined') return 'eip2930';
    return 'legacy';
  }
  throw new InvalidSerializableTransactionError({
    transaction
  });
}
