import { createClient, custom } from 'viem';
import { getAddress, parseAccount } from 'viem/utils';
import { ConnectorAccountNotFoundError, ConnectorNotConnectedError } from '../errors/config.js';
import '../types/utils.js';
/** https://wagmi.sh/core/api/actions/getConnectorClient */
export async function getConnectorClient(config, parameters = {}) {
  // Get connection
  let connection;
  if (parameters.connector) {
    const {
      connector
    } = parameters;
    const [accounts, chainId] = await Promise.all([connector.getAccounts(), connector.getChainId()]);
    connection = {
      accounts: accounts,
      chainId,
      connector
    };
  } else connection = config.state.connections.get(config.state.current);
  if (!connection) throw new ConnectorNotConnectedError();
  const chainId = parameters.chainId ?? connection.chainId;
  const connector = connection.connector;
  if (connector.getClient) return connector.getClient({
    chainId: chainId
  });
  // Default using `custom` transport
  const account = parseAccount(parameters.account ?? connection.accounts[0]);
  account.address = getAddress(account.address); // TODO: Checksum address as part of `parseAccount`?
  const chain = config.chains.find(chain => chain.id === chainId);
  const provider = await connection.connector.getProvider({
    chainId
  });
  // if account was provided, check that it exists on the connector
  if (parameters.account && !connection.accounts.some(x => x.toLowerCase() === account.address.toLowerCase())) throw new ConnectorAccountNotFoundError({
    address: account.address,
    connector
  });
  return createClient({
    account,
    chain,
    name: 'Connector Client',
    transport: opts => custom(provider)({
      ...opts,
      retryCount: 0
    })
  });
}
