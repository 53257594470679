"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KYBER = void 0;
exports.KYBER = [{
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'trader',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'contract IERC20',
    name: 'src',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'contract IERC20',
    name: 'dest',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'destAddress',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'actualSrcAmount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'actualDestAmount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'platformWallet',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'platformFeeBps',
    type: 'uint256'
  }],
  name: 'ExecuteTrade',
  type: 'event'
}, {
  inputs: [{
    internalType: 'contract ERC20',
    name: 'src',
    type: 'address'
  }, {
    internalType: 'contract ERC20',
    name: 'dest',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'srcQty',
    type: 'uint256'
  }],
  name: 'getExpectedRate',
  outputs: [{
    internalType: 'uint256',
    name: 'expectedRate',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'worstRate',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'contract IERC20',
    name: 'src',
    type: 'address'
  }, {
    internalType: 'contract IERC20',
    name: 'dest',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'srcQty',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'platformFeeBps',
    type: 'uint256'
  }, {
    internalType: 'bytes',
    name: 'hint',
    type: 'bytes'
  }],
  name: 'getExpectedRateAfterFee',
  outputs: [{
    internalType: 'uint256',
    name: 'expectedRate',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'contract IERC20',
    name: 'src',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'srcAmount',
    type: 'uint256'
  }, {
    internalType: 'contract IERC20',
    name: 'dest',
    type: 'address'
  }, {
    internalType: 'address payable',
    name: 'destAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'maxDestAmount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'minConversionRate',
    type: 'uint256'
  }, {
    internalType: 'address payable',
    name: 'platformWallet',
    type: 'address'
  }],
  name: 'trade',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'contract ERC20',
    name: 'src',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'srcAmount',
    type: 'uint256'
  }, {
    internalType: 'contract ERC20',
    name: 'dest',
    type: 'address'
  }, {
    internalType: 'address payable',
    name: 'destAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'maxDestAmount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'minConversionRate',
    type: 'uint256'
  }, {
    internalType: 'address payable',
    name: 'walletId',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'hint',
    type: 'bytes'
  }],
  name: 'tradeWithHint',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'contract IERC20',
    name: 'src',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'srcAmount',
    type: 'uint256'
  }, {
    internalType: 'contract IERC20',
    name: 'dest',
    type: 'address'
  }, {
    internalType: 'address payable',
    name: 'destAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'maxDestAmount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'minConversionRate',
    type: 'uint256'
  }, {
    internalType: 'address payable',
    name: 'platformWallet',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'platformFeeBps',
    type: 'uint256'
  }, {
    internalType: 'bytes',
    name: 'hint',
    type: 'bytes'
  }],
  name: 'tradeWithHintAndFee',
  outputs: [{
    internalType: 'uint256',
    name: 'destAmount',
    type: 'uint256'
  }],
  stateMutability: 'payable',
  type: 'function'
}];
