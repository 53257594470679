"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MASTERCHEFV2 = void 0;
exports.MASTERCHEFV2 = [{
  inputs: [{
    internalType: 'contract IMasterChef',
    name: '_MASTER_CHEF',
    type: 'address'
  }, {
    internalType: 'contract IBEP20',
    name: '_CAKE',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_MASTER_PID',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: '_burnAdmin',
    type: 'address'
  }],
  stateMutability: 'nonpayable',
  type: 'constructor'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'uint256',
    name: 'pid',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'allocPoint',
    type: 'uint256'
  }, {
    indexed: true,
    internalType: 'contract IBEP20',
    name: 'lpToken',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'bool',
    name: 'isRegular',
    type: 'bool'
  }],
  name: 'AddPool',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'pid',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'Deposit',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'pid',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'EmergencyWithdraw',
  type: 'event'
}, {
  anonymous: false,
  inputs: [],
  name: 'Init',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'uint256',
    name: 'pid',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'allocPoint',
    type: 'uint256'
  }],
  name: 'SetPool',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'boostContract',
    type: 'address'
  }],
  name: 'UpdateBoostContract',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'pid',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'oldMultiplier',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'newMultiplier',
    type: 'uint256'
  }],
  name: 'UpdateBoostMultiplier',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'oldAdmin',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newAdmin',
    type: 'address'
  }],
  name: 'UpdateBurnAdmin',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint256',
    name: 'burnRate',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'regularFarmRate',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'specialFarmRate',
    type: 'uint256'
  }],
  name: 'UpdateCakeRate',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'uint256',
    name: 'pid',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'lastRewardBlock',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'lpSupply',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'accCakePerShare',
    type: 'uint256'
  }],
  name: 'UpdatePool',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'bool',
    name: 'isValid',
    type: 'bool'
  }],
  name: 'UpdateWhiteList',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'pid',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'Withdraw',
  type: 'event'
}, {
  inputs: [],
  name: 'ACC_CAKE_PRECISION',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'BOOST_PRECISION',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'CAKE',
  outputs: [{
    internalType: 'contract IBEP20',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'CAKE_RATE_TOTAL_PRECISION',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'MASTERCHEF_CAKE_PER_BLOCK',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'MASTER_CHEF',
  outputs: [{
    internalType: 'contract IMasterChef',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'MASTER_PID',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'MAX_BOOST_PRECISION',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_allocPoint',
    type: 'uint256'
  }, {
    internalType: 'contract IBEP20',
    name: '_lpToken',
    type: 'address'
  }, {
    internalType: 'bool',
    name: '_isRegular',
    type: 'bool'
  }, {
    internalType: 'bool',
    name: '_withUpdate',
    type: 'bool'
  }],
  name: 'add',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'boostContract',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'burnAdmin',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bool',
    name: '_withUpdate',
    type: 'bool'
  }],
  name: 'burnCake',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bool',
    name: '_isRegular',
    type: 'bool'
  }],
  name: 'cakePerBlock',
  outputs: [{
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'cakePerBlockToBurn',
  outputs: [{
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'cakeRateToBurn',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'cakeRateToRegularFarm',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'cakeRateToSpecialFarm',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_pid',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }],
  name: 'deposit',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_pid',
    type: 'uint256'
  }],
  name: 'emergencyWithdraw',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_user',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_pid',
    type: 'uint256'
  }],
  name: 'getBoostMultiplier',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'harvestFromMasterChef',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'contract IBEP20',
    name: 'dummyToken',
    type: 'address'
  }],
  name: 'init',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'lastBurnedBlock',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'lpToken',
  outputs: [{
    internalType: 'contract IBEP20',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'massUpdatePools',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_pid',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: '_user',
    type: 'address'
  }],
  name: 'pendingCake',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'poolInfo',
  outputs: [{
    internalType: 'uint256',
    name: 'accCakePerShare',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'lastRewardBlock',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'allocPoint',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'totalBoostedShare',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: 'isRegular',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'poolLength',
  outputs: [{
    internalType: 'uint256',
    name: 'pools',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'renounceOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_pid',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_allocPoint',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: '_withUpdate',
    type: 'bool'
  }],
  name: 'set',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'totalRegularAllocPoint',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'totalSpecialAllocPoint',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_newBoostContract',
    type: 'address'
  }],
  name: 'updateBoostContract',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_user',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_pid',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_newMultiplier',
    type: 'uint256'
  }],
  name: 'updateBoostMultiplier',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_newAdmin',
    type: 'address'
  }],
  name: 'updateBurnAdmin',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_burnRate',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_regularFarmRate',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_specialFarmRate',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: '_withUpdate',
    type: 'bool'
  }],
  name: 'updateCakeRate',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_pid',
    type: 'uint256'
  }],
  name: 'updatePool',
  outputs: [{
    components: [{
      internalType: 'uint256',
      name: 'accCakePerShare',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'lastRewardBlock',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'allocPoint',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'totalBoostedShare',
      type: 'uint256'
    }, {
      internalType: 'bool',
      name: 'isRegular',
      type: 'bool'
    }],
    internalType: 'struct MasterChefV2.PoolInfo',
    name: 'pool',
    type: 'tuple'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_user',
    type: 'address'
  }, {
    internalType: 'bool',
    name: '_isValid',
    type: 'bool'
  }],
  name: 'updateWhiteList',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'userInfo',
  outputs: [{
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'rewardDebt',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'boostMultiplier',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'whiteList',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_pid',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }],
  name: 'withdraw',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}];
