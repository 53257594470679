"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MULTICALL_ADDRESS = void 0;
const networks_1 = require("./networks");
exports.MULTICALL_ADDRESS = {
  [networks_1.Networks.AVALANCHE]: '0xa00FB557AA68d2e98A830642DBbFA534E8512E5f',
  [networks_1.Networks.ARBITRUM]: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
  [networks_1.Networks.BSCMAIN]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [networks_1.Networks.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [networks_1.Networks.POLYGON_MAINNET]: '0x3eF6A01A0f81D6046290f3e2A8c5b843e738E604',
  [networks_1.Networks.ZKSYNC]: '0x47898B2C52C957663aE9AB46922dCec150a2272c',
  [networks_1.Networks.OPTIMISM]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [networks_1.Networks.LINEA]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [networks_1.Networks.BASE]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [networks_1.Networks.DEFI]: '0x233bF280274f5BAe12FAeA57834c6a92042C3bce',
  [networks_1.Networks.ROOTSTOCK]: '0x6c62bf5440de2cb157205b15c424bceb5c3368f5'
};
