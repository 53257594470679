"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CrosschainFeeType = void 0;
var CrosschainFeeType;
(function (CrosschainFeeType) {
  CrosschainFeeType[CrosschainFeeType["NetworkFee"] = 0] = "NetworkFee";
  CrosschainFeeType[CrosschainFeeType["ProtocolFee"] = 1] = "ProtocolFee";
  CrosschainFeeType[CrosschainFeeType["OperatingExpensesFee"] = 2] = "OperatingExpensesFee";
  CrosschainFeeType[CrosschainFeeType["CrowdSwapFee"] = 3] = "CrowdSwapFee";
  CrosschainFeeType[CrosschainFeeType["DlnFee"] = 4] = "DlnFee";
  CrosschainFeeType[CrosschainFeeType["TakerFee"] = 5] = "TakerFee";
  CrosschainFeeType[CrosschainFeeType["AffiliateFee"] = 6] = "AffiliateFee";
})(CrosschainFeeType = exports.CrosschainFeeType || (exports.CrosschainFeeType = {}));
