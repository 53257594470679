"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.multiCall = void 0;
const utils_1 = require("ethers/lib/utils");
const bytecode_json_1 = require("./bytecode.json");
const utils_2 = require("./utils");
async function multiCall(provider_, arg0, arg1, arg2) {
  const provider = utils_2.toProvider(provider_);
  let inputs = [];
  let strict;
  if (utils_2.isInterface(arg0) || utils_2.isJsonFragmentArray(arg0)) {
    if (!Array.isArray(arg1)) {
      throw new Error(`Second parameter must be array of call inputs if first is interface.`);
    }
    inputs = arg1;
    for (let input of inputs) {
      if (!input.interface) input.interface = arg0;
    }
    strict = arg2;
  } else {
    inputs = arg0;
    strict = arg1;
  }
  const targets = [];
  const datas = [];
  const interfaces = [];
  for (let input of inputs) {
    let _interface;
    if (!input.interface) {
      throw new Error(`Call input must include interface.`);
    }
    if (utils_2.isInterface(input.interface)) {
      _interface = input.interface;
    } else {
      _interface = new utils_1.Interface(input.interface);
    }
    interfaces.push(_interface);
    const calldata = _interface.encodeFunctionData(input.function, input.args);
    datas.push(calldata);
    targets.push(input.target);
  }
  const inputData = utils_1.defaultAbiCoder.encode(['address[]', 'bytes[]'], [targets, datas]);
  const fulldata = (strict ? bytecode_json_1.MultiCallStrict : bytecode_json_1.MultiCall).concat(inputData.slice(2));
  const encodedReturnData = await provider.call({
    data: fulldata
  });
  const [blockNumber, returndatas] = utils_1.defaultAbiCoder.decode(['uint256', 'bytes[]'], encodedReturnData);
  const results = [];
  for (let i = 0; i < inputs.length; i++) {
    const returndata = returndatas[i];
    let result;
    if (!strict && returndata == '0x') {
      result = null;
    } else {
      result = interfaces[i].decodeFunctionResult(inputs[i].function, returndata);
      if (Array.isArray(result) && result.length == 1) {
        result = result[0];
      }
    }
    results.push(result);
  }
  return [blockNumber.toNumber(), results];
}
exports.multiCall = multiCall;