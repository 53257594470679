// src/Builder.ts
function Builder(typeOrTemplate, templateOrOverride, override) {
  let type;
  let template;
  let overrideValues;
  if (typeOrTemplate instanceof Function) {
    type = typeOrTemplate;
    template = templateOrOverride;
    overrideValues = override;
  } else {
    template = typeOrTemplate;
    overrideValues = templateOrOverride;
  }
  const built = template ? Object.assign({}, template) : {};
  const builder = new Proxy({}, {
    get(target, prop) {
      if ("build" === prop) {
        if (overrideValues) {
          Object.assign(built, overrideValues);
        }
        if (type) {
          const obj = new type();
          return () => Object.assign(obj, {
            ...built
          });
        } else {
          return () => built;
        }
      }
      return (...args) => {
        if (0 === args.length) {
          return built[prop.toString()];
        }
        built[prop.toString()] = args[0];
        return builder;
      };
    }
  });
  return builder;
}

// src/StrictBuilder.ts
function StrictBuilder() {
  const built = {};
  const strictbuilder = new Proxy({}, {
    get(target, prop) {
      if ("build" === prop) {
        return () => built;
      }
      return (...args) => {
        if (0 === args.length) {
          return built[prop.toString()];
        }
        built[prop.toString()] = args[0];
        return strictbuilder;
      };
    }
  });
  return strictbuilder;
}
export { Builder, StrictBuilder };
