"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AFFILIATE_FEE = void 0;
exports.AFFILIATE_FEE = [{
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'previousAdmin',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newAdmin',
    type: 'address'
  }],
  name: 'AdminChanged',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint256',
    name: 'countOfNewData',
    type: 'uint256'
  }],
  name: 'BalancesUpdated',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'beacon',
    type: 'address'
  }],
  name: 'BeaconUpgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint8',
    name: 'version',
    type: 'uint8'
  }],
  name: 'Initialized',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Paused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'agent',
    type: 'address'
  }],
  name: 'SetUpdateAgent',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Unpaused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'Upgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'Withdrawn',
  type: 'event'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'addresses',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'addressesLength',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'affiliatorIndex',
  outputs: [{
    internalType: 'uint256',
    name: 'totalBalance',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'totalBalanceInUsd',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'released',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: 'exist',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'feeToken',
  outputs: [{
    internalType: 'contract IERC20Upgradeable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address[]',
    name: '_affiliator',
    type: 'address[]'
  }],
  name: 'getBalances',
  outputs: [{
    components: [{
      internalType: 'uint256',
      name: 'totalBalance',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'totalBalanceInUsd',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'released',
      type: 'uint256'
    }, {
      internalType: 'bool',
      name: 'exist',
      type: 'bool'
    }],
    internalType: 'struct AffilateFeeHolder.AffiliatorStruct[]',
    name: '',
    type: 'tuple[]'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_affiliator',
    type: 'address'
  }],
  name: 'getReleasable',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_updateAgent',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_feeToken',
    type: 'address'
  }],
  name: 'initialize',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'paused',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'proxiableUUID',
  outputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_updateAgent',
    type: 'address'
  }],
  name: 'setUpdateAgent',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'address',
      name: 'affiliatorAddress',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: 'balance',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'balanceInUsd',
      type: 'uint256'
    }],
    internalType: 'struct AffilateFeeHolder.UpdateStruct[]',
    name: '_updateBalaces',
    type: 'tuple[]'
  }],
  name: 'updateBalances',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }],
  name: 'upgradeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  name: 'upgradeToAndCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: '_receiverAccount',
    type: 'address'
  }],
  name: 'withdraw',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}];
