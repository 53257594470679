"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CurvePoolZapContract = void 0;
exports.CurvePoolZapContract = [{
  stateMutability: 'nonpayable',
  type: 'constructor',
  inputs: [],
  outputs: []
}, {
  stateMutability: 'view',
  type: 'function',
  name: 'coins',
  inputs: [{
    name: 'i',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'address'
  }]
}, {
  stateMutability: 'view',
  type: 'function',
  name: 'underlying_coins',
  inputs: [{
    name: 'i',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'address'
  }]
}, {
  stateMutability: 'view',
  type: 'function',
  name: 'pool',
  inputs: [],
  outputs: [{
    name: '',
    type: 'address'
  }]
}, {
  stateMutability: 'view',
  type: 'function',
  name: 'base_pool',
  inputs: [],
  outputs: [{
    name: '',
    type: 'address'
  }]
}, {
  stateMutability: 'view',
  type: 'function',
  name: 'token',
  inputs: [],
  outputs: [{
    name: '',
    type: 'address'
  }]
}, {
  stateMutability: 'view',
  type: 'function',
  name: 'price_oracle',
  inputs: [],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  stateMutability: 'view',
  type: 'function',
  name: 'price_scale',
  inputs: [],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  stateMutability: 'nonpayable',
  type: 'function',
  name: 'add_liquidity',
  inputs: [{
    name: '_amounts',
    type: 'uint256[4]'
  }, {
    name: '_min_mint_amount',
    type: 'uint256'
  }],
  outputs: []
}, {
  stateMutability: 'nonpayable',
  type: 'function',
  name: 'add_liquidity',
  inputs: [{
    name: '_amounts',
    type: 'uint256[4]'
  }, {
    name: '_min_mint_amount',
    type: 'uint256'
  }, {
    name: '_receiver',
    type: 'address'
  }],
  outputs: []
}, {
  stateMutability: 'nonpayable',
  type: 'function',
  name: 'exchange_underlying',
  inputs: [{
    name: 'i',
    type: 'uint256'
  }, {
    name: 'j',
    type: 'uint256'
  }, {
    name: '_dx',
    type: 'uint256'
  }, {
    name: '_min_dy',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  stateMutability: 'nonpayable',
  type: 'function',
  name: 'remove_liquidity',
  inputs: [{
    name: '_amount',
    type: 'uint256'
  }, {
    name: '_min_amounts',
    type: 'uint256[4]'
  }],
  outputs: []
}, {
  stateMutability: 'nonpayable',
  type: 'function',
  name: 'remove_liquidity',
  inputs: [{
    name: '_amount',
    type: 'uint256'
  }, {
    name: '_min_amounts',
    type: 'uint256[4]'
  }, {
    name: '_receiver',
    type: 'address'
  }],
  outputs: []
}, {
  stateMutability: 'nonpayable',
  type: 'function',
  name: 'remove_liquidity_one_coin',
  inputs: [{
    name: '_token_amount',
    type: 'uint256'
  }, {
    name: 'i',
    type: 'uint256'
  }, {
    name: '_min_amount',
    type: 'uint256'
  }],
  outputs: []
}, {
  stateMutability: 'nonpayable',
  type: 'function',
  name: 'remove_liquidity_one_coin',
  inputs: [{
    name: '_token_amount',
    type: 'uint256'
  }, {
    name: 'i',
    type: 'uint256'
  }, {
    name: '_min_amount',
    type: 'uint256'
  }, {
    name: '_receiver',
    type: 'address'
  }],
  outputs: []
}, {
  stateMutability: 'view',
  type: 'function',
  name: 'get_dy_underlying',
  inputs: [{
    name: 'i',
    type: 'uint256'
  }, {
    name: 'j',
    type: 'uint256'
  }, {
    name: '_dx',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  stateMutability: 'view',
  type: 'function',
  name: 'calc_token_amount',
  inputs: [{
    name: '_amounts',
    type: 'uint256[4]'
  }],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  stateMutability: 'view',
  type: 'function',
  name: 'calc_withdraw_one_coin',
  inputs: [{
    name: 'token_amount',
    type: 'uint256'
  }, {
    name: 'i',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}];
