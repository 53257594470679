"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ETFProxy = void 0;
exports.ETFProxy = [{
  "inputs": [],
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "address",
    "name": "previousAdmin",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "address",
    "name": "newAdmin",
    "type": "address"
  }],
  "name": "AdminChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "beacon",
    "type": "address"
  }],
  "name": "BeaconUpgraded",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "user",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "amount",
    "type": "uint256"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "totalFee",
    "type": "uint256"
  }],
  "name": "FeeDeducted",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "uint8",
    "name": "version",
    "type": "uint8"
  }],
  "name": "Initialized",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "user",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "investId",
    "type": "uint256"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "planId",
    "type": "uint256"
  }, {
    "indexed": false,
    "internalType": "address",
    "name": "initiator",
    "type": "address"
  }],
  "name": "Invested",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "previousOwner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "address",
    "name": "account",
    "type": "address"
  }],
  "name": "Paused",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "ETFReceipt",
    "type": "address"
  }],
  "name": "SetETFReceiptAddress",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "user",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "address",
    "name": "feeTo",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "stakeFee",
    "type": "uint256"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "unstakeFee",
    "type": "uint256"
  }],
  "name": "SetFee",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "swapContract",
    "type": "address"
  }],
  "name": "SetSwapContract",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "address",
    "name": "account",
    "type": "address"
  }],
  "name": "Unpaused",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "implementation",
    "type": "address"
  }],
  "name": "Upgraded",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "user",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "investId",
    "type": "uint256"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "planId",
    "type": "uint256"
  }],
  "name": "Withdrawn",
  "type": "event"
}, {
  "inputs": [],
  "name": "ETFReceiptAddress",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "MAX_FEE",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "MAX_P",
  "outputs": [{
    "internalType": "uint16",
    "name": "",
    "type": "uint16"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "claimOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "feeInfo",
  "outputs": [{
    "internalType": "address payable",
    "name": "feeTo",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "investFee",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "withdrawFee",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_ETFReceiptAddress",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "_swapContract",
    "type": "address"
  }, {
    "components": [{
      "internalType": "address payable",
      "name": "feeTo",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "investFee",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "withdrawFee",
      "type": "uint256"
    }],
    "internalType": "struct ETFProxy.FeeInfo",
    "name": "_feeInfo",
    "type": "tuple"
  }],
  "name": "initialize",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_userAddress",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "_planId",
    "type": "uint256"
  }, {
    "internalType": "contract IERC20Upgradeable",
    "name": "_tokenIn",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "_amountIn",
    "type": "uint256"
  }, {
    "components": [{
      "internalType": "contract IERC20Upgradeable",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint64",
      "name": "price",
      "type": "uint64"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "struct ETFProxy.SwapInfo[]",
    "name": "_swaps",
    "type": "tuple[]"
  }],
  "name": "invest",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
}, {
  "inputs": [],
  "name": "owner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "pause",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "paused",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "pendingOwner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "proxiableUUID",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_ETFReceiptAddress",
    "type": "address"
  }],
  "name": "setETFReceiptAddress",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address payable",
      "name": "feeTo",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "investFee",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "withdrawFee",
      "type": "uint256"
    }],
    "internalType": "struct ETFProxy.FeeInfo",
    "name": "_feeInfo",
    "type": "tuple"
  }],
  "name": "setFee",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_swapContract",
    "type": "address"
  }],
  "name": "setSwapContract",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "swapContract",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "transferOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "unpause",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newImplementation",
    "type": "address"
  }],
  "name": "upgradeTo",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newImplementation",
    "type": "address"
  }, {
    "internalType": "bytes",
    "name": "data",
    "type": "bytes"
  }],
  "name": "upgradeToAndCall",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "_tokenId",
    "type": "uint256"
  }, {
    "internalType": "contract IERC20Upgradeable",
    "name": "_tokenOut",
    "type": "address"
  }, {
    "internalType": "uint16",
    "name": "_percentage",
    "type": "uint16"
  }, {
    "components": [{
      "internalType": "contract IERC20Upgradeable",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint64",
      "name": "price",
      "type": "uint64"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "struct ETFProxy.SwapInfo[]",
    "name": "_swaps",
    "type": "tuple[]"
  }],
  "name": "withdrawWithSwap",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "_tokenId",
    "type": "uint256"
  }, {
    "internalType": "uint16",
    "name": "_percentage",
    "type": "uint16"
  }],
  "name": "withdrawWithoutSwap",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "stateMutability": "payable",
  "type": "receive"
}];
