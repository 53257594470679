"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MulticallService = void 0;
const ethers_1 = require("ethers");
const networks_1 = require("../networks");
const abis_1 = require("../../abis");
const abi_1 = require("@ethersproject/abi");
class MulticallService {
  constructor() {}
  async call(provider, abi, data) {
    try {
      const multicallContract = new ethers_1.Contract(networks_1.MULTICALL_ADDRESS[(await provider.getNetwork()).chainId], abis_1.MULTICALL, provider);
      let callData = [];
      const iFace = new abi_1.Interface(abi);
      for (const item of data) {
        const populatedData = iFace.encodeFunctionData(item.method, item.params);
        callData.push([item.target, populatedData]);
      }
      let resultOfMultiCall = (await multicallContract.callStatic.aggregate(callData)).returnData;
      let result = [];
      for (let i = 0; i < resultOfMultiCall.length; i++) {
        const parsedResult = iFace.decodeFunctionResult(data[i].method, resultOfMultiCall[i]);
        result.push(parsedResult);
      }
      return result;
    } catch (e) {
      console.error(e);
    }
  }
}
exports.MulticallService = MulticallService;
