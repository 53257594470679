"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MultiCall = void 0;
const utils_1 = require("./utils");
const generic_1 = require("./generic");
const tokens_1 = require("./tokens");
const uniswap_1 = require("./uniswap");
class MultiCall {
  constructor(provider) {
    this.provider = utils_1.toProvider(provider);
  }
  async multiCall(arg0, arg1, arg2) {
    return generic_1.multiCall(this.provider, arg0, arg1, arg2);
  }
  async getBalances(tokens, account) {
    return tokens_1.getBalances(this.provider, tokens, account);
  }
  async getBalancesAndAllowances(tokens, owner, spender) {
    return tokens_1.getBalancesAndAllowances(this.provider, tokens, owner, spender);
  }
  async getReserves(pairs) {
    return uniswap_1.getReserves(this.provider, pairs);
  }
}
exports.MultiCall = MultiCall;