"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RevenueDto = void 0;
class RevenueDto {
  constructor() {
    this.totalVolume = '';
    this.currentMonthVolume = '';
    this.totalFee = '';
    this.currentMonthFee = '';
    this.runningMonthPercentage = 0;
    this.followingMonthPercentage = 0;
    this.currentMonthRegistration = 0;
    this.totalRegistration = 0;
    this.walletAddress = '';
  }
}
exports.RevenueDto = RevenueDto;
