"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LPSTAKINGREWARDS = void 0;
exports.LPSTAKINGREWARDS = [{
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "user",
    "type": "address"
  }],
  "name": "AddToEligibleUsers",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "address",
    "name": "previousAdmin",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "address",
    "name": "newAdmin",
    "type": "address"
  }],
  "name": "AdminChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "beacon",
    "type": "address"
  }],
  "name": "BeaconUpgraded",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "uint8",
    "name": "version",
    "type": "uint8"
  }],
  "name": "Initialized",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "user",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "amount",
    "type": "uint256"
  }],
  "name": "LPStaked",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "previousOwner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "address",
    "name": "account",
    "type": "address"
  }],
  "name": "Paused",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "uint256",
    "name": "reward",
    "type": "uint256"
  }],
  "name": "RewardAdded",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "uint256",
    "name": "oldDuration",
    "type": "uint256"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "newDuration",
    "type": "uint256"
  }],
  "name": "RewardsDurationUpdated",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "uint128",
    "name": "oldStartTime",
    "type": "uint128"
  }, {
    "indexed": false,
    "internalType": "uint128",
    "name": "newStartTime",
    "type": "uint128"
  }],
  "name": "StartTime",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "address",
    "name": "account",
    "type": "address"
  }],
  "name": "Unpaused",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "implementation",
    "type": "address"
  }],
  "name": "Upgraded",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "user",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "lPBalance",
    "type": "uint256"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "rewardsBalance",
    "type": "uint256"
  }],
  "name": "Withdrawn",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "user",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "amount",
    "type": "uint256"
  }],
  "name": "WithdrawnRewards",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "address",
    "name": "caller",
    "type": "address"
  }],
  "name": "callerSet",
  "type": "event"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_account",
    "type": "address"
  }],
  "name": "addToEligibleUsers",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_account",
    "type": "address"
  }],
  "name": "balanceOf",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "claimOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_account",
    "type": "address"
  }],
  "name": "earned",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "name": "eligibleUsers",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "getRewardForDuration",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_lpStakingToken",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "_rewardToken",
    "type": "address"
  }, {
    "internalType": "uint128",
    "name": "_rewardsDuration",
    "type": "uint128"
  }, {
    "internalType": "uint128",
    "name": "_startTime",
    "type": "uint128"
  }],
  "name": "initialize",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "lastTimeRewardApplicable",
  "outputs": [{
    "internalType": "uint128",
    "name": "",
    "type": "uint128"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "lastUpdatedTime",
  "outputs": [{
    "internalType": "uint128",
    "name": "",
    "type": "uint128"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "name": "lpStakeholders",
  "outputs": [{
    "internalType": "uint256",
    "name": "userRewardPerTokenPaid",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "lpRewards",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "userLpBalance",
    "type": "uint256"
  }, {
    "internalType": "bool",
    "name": "exist",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "lpStakingToken",
  "outputs": [{
    "internalType": "contract IERC20Upgradeable",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "reward",
    "type": "uint256"
  }],
  "name": "notifyRewardAmount",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "opportunityContract",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "owner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "pause",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "paused",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "pendingOwner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "periodFinish",
  "outputs": [{
    "internalType": "uint128",
    "name": "",
    "type": "uint128"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "proxiableUUID",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "resonateAdapter",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "rewardPerToken",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "rewardPerTokenStored",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "rewardRate",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "rewardToken",
  "outputs": [{
    "internalType": "contract IERC20Upgradeable",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "rewardsDuration",
  "outputs": [{
    "internalType": "uint128",
    "name": "",
    "type": "uint128"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_opportunityContract",
    "type": "address"
  }],
  "name": "setOpportunityContract",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_resonateAdapter",
    "type": "address"
  }],
  "name": "setResonateAdapter",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint128",
    "name": "_rewardsDuration",
    "type": "uint128"
  }],
  "name": "setRewardsDuration",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint128",
    "name": "_startTime",
    "type": "uint128"
  }],
  "name": "setStartTime",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "_amount",
    "type": "uint256"
  }, {
    "internalType": "address",
    "name": "_account",
    "type": "address"
  }],
  "name": "stakeLP",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "startTime",
  "outputs": [{
    "internalType": "uint128",
    "name": "",
    "type": "uint128"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "totalSupply",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "transferOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "unpause",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newImplementation",
    "type": "address"
  }],
  "name": "upgradeTo",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newImplementation",
    "type": "address"
  }, {
    "internalType": "bytes",
    "name": "data",
    "type": "bytes"
  }],
  "name": "upgradeToAndCall",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "_lpAmount",
    "type": "uint256"
  }, {
    "internalType": "address",
    "name": "_originAccount",
    "type": "address"
  }],
  "name": "withdraw",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "_lpAmount",
    "type": "uint256"
  }, {
    "internalType": "address",
    "name": "_originAccount",
    "type": "address"
  }],
  "name": "withdrawByOwner",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "_lpRewards",
    "type": "uint256"
  }, {
    "internalType": "address",
    "name": "_receiverAccount",
    "type": "address"
  }],
  "name": "withdrawRewards",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}];
