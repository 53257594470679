import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private _darkModeChangeSubject = new BehaviorSubject(false);

  constructor() {
    if (localStorage.getItem('darkMode') === null) {
      localStorage.setItem('darkMode', 'false');
    } else {
      switch (localStorage.getItem('darkMode')) {
        case 'true': {
          this.darkModeChangeSubject.next(true);
          document.body.classList.add('dark');
          break;
        }
        case 'false': {
          this.darkModeChangeSubject.next(false);
          document.body.classList.remove('dark');
          break;
        }
      }
    }
  }

  get darkModeChangeSubject() {
    return this._darkModeChangeSubject;
  }
}
