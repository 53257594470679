"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CROWD_SWAP_OTC = void 0;
exports.CROWD_SWAP_OTC = [{
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'previousAdmin',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newAdmin',
    type: 'address'
  }],
  name: 'AdminChanged',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'beacon',
    type: 'address'
  }],
  name: 'BeaconUpgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'feeAmount',
    type: 'uint256'
  }],
  name: 'FeeDeducted',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint8',
    name: 'version',
    type: 'uint8'
  }],
  name: 'Initialized',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'sender',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'tokenInAddress',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'priceIn',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountIn',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountOut',
    type: 'uint256'
  }],
  name: 'Swap',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'Upgraded',
  type: 'event'
}, {
  inputs: [{
    internalType: 'address[]',
    name: 'stableCoinsAddressList_',
    type: 'address[]'
  }],
  name: 'addStableCoins',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'address',
      name: 'pairAddress',
      type: 'address'
    }, {
      internalType: 'address',
      name: 'tokenAddress',
      type: 'address'
    }],
    internalType: 'struct CrowdSwapOtc.UniswapPairDesc[]',
    name: 'uniswapPairDescsList_',
    type: 'tuple[]'
  }],
  name: 'addUniswapPairs',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'valueIn_',
    type: 'uint256'
  }],
  name: 'canSwap',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'feePercentage',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'tokenAddress_',
    type: 'address'
  }],
  name: 'getPrice',
  outputs: [{
    internalType: 'uint256',
    name: 'price',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'valueInThreshold_',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: 'tokenOutAddress_',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'feePercentage_',
    type: 'uint256'
  }, {
    internalType: 'address payable',
    name: 'feeTo_',
    type: 'address'
  }, {
    internalType: 'address payable',
    name: 'treasury_',
    type: 'address'
  }, {
    internalType: 'address[]',
    name: 'stableCoinsAddressList_',
    type: 'address[]'
  }, {
    components: [{
      internalType: 'address',
      name: 'pairAddress',
      type: 'address'
    }, {
      internalType: 'address',
      name: 'tokenAddress',
      type: 'address'
    }],
    internalType: 'struct CrowdSwapOtc.UniswapPairDesc[]',
    name: 'uniswapPairDescsList_',
    type: 'tuple[]'
  }],
  name: 'initialize',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'maxDailySwap',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'proxiableUUID',
  outputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'feePercentage_',
    type: 'uint256'
  }],
  name: 'setFeePercentage',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address payable',
    name: 'feeTo_',
    type: 'address'
  }],
  name: 'setFeeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'maxDailySwap_',
    type: 'uint256'
  }],
  name: 'setMaxDailySwap',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'tokenOutAddress_',
    type: 'address'
  }],
  name: 'setTokenOutAddress',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'toleranceAmount_',
    type: 'uint256'
  }],
  name: 'setToleranceAmount',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address payable',
    name: 'treasury_',
    type: 'address'
  }],
  name: 'setTreasury',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'valueInThreshold_',
    type: 'uint256'
  }],
  name: 'setValueInThreshold',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'startOfToday',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'tokenInAddress_',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amountIn_',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'amountOutMin_',
    type: 'uint256'
  }, {
    internalType: 'address payable',
    name: 'receiverAddress_',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'deadline_',
    type: 'uint256'
  }],
  name: 'swapExactTokensForTokens',
  outputs: [{
    internalType: 'uint256',
    name: 'amountOut',
    type: 'uint256'
  }],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [],
  name: 'tokenOutAddress',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'toleranceAmount',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'totalTodaySwap',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }],
  name: 'upgradeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  name: 'upgradeToAndCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [],
  name: 'valueInThreshold',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}];
