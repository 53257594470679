"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RootstockNetworks = exports.DeFiNetworks = exports.BaseNetworks = exports.LineaNetworks = exports.OptimismNetworks = exports.ZksyncNetworks = exports.ArbitrumNetworks = exports.ApexNetworks = exports.AvalancheNetworks = exports.PolygonNetworks = exports.BscNetworks = exports.EthereumNetworks = exports.Networks = exports.NetworksByName = exports.MainNetworksById = exports.NetworksById = exports.ROOTSTOCK_NAME = exports.DEFI_NAME = exports.BASE_NAME = exports.LINEA_NAME = exports.OPTIMISM_NAME = exports.ZKSYNCTEST_NAME = exports.ZKSYNC_NAME = exports.ARBITRUM_NAME = exports.APEX_NAME = exports.AVALANCHE_FUJI_NAME = exports.AVALANCHE_NAME = exports.POLYGON_MUMBAI_NAME = exports.POLYGON_MAINNET_NAME = exports.BSCTEST_NAME = exports.BSCMAIN_NAME = exports.KOVAN_NAME = exports.GOERLI_NAME = exports.RINKEBY_NAME = exports.ROPSTEN_NAME = exports.MAINNET_NAME = void 0;
exports.MAINNET_NAME = 'MAINNET';
exports.ROPSTEN_NAME = 'ROPSTEN';
exports.RINKEBY_NAME = 'RINKEBY';
exports.GOERLI_NAME = 'GOERLI';
exports.KOVAN_NAME = 'KOVAN';
exports.BSCMAIN_NAME = 'BSCMAIN';
exports.BSCTEST_NAME = 'BSCTEST';
exports.POLYGON_MAINNET_NAME = 'POLYGON_MAINNET';
exports.POLYGON_MUMBAI_NAME = 'POLYGON_MUMBAI';
exports.AVALANCHE_NAME = 'AVALANCHE';
exports.AVALANCHE_FUJI_NAME = 'AVALANCHE_FUJI';
exports.APEX_NAME = 'APEX';
exports.ARBITRUM_NAME = 'ARBITRUM';
exports.ZKSYNC_NAME = 'ZKSYNC';
exports.ZKSYNCTEST_NAME = 'ZKSYNCTEST';
exports.OPTIMISM_NAME = 'OPTIMISM';
exports.LINEA_NAME = 'LINEA';
exports.BASE_NAME = 'BASE';
exports.DEFI_NAME = 'DEFI';
exports.ROOTSTOCK_NAME = 'ROOTSTOCK';
exports.NetworksById = {
  1: exports.MAINNET_NAME,
  3: exports.ROPSTEN_NAME,
  4: exports.RINKEBY_NAME,
  5: exports.GOERLI_NAME,
  42: exports.KOVAN_NAME,
  56: exports.BSCMAIN_NAME,
  97: exports.BSCTEST_NAME,
  137: exports.POLYGON_MAINNET_NAME,
  80001: exports.POLYGON_MUMBAI_NAME,
  43114: exports.AVALANCHE_NAME,
  43113: exports.AVALANCHE_FUJI_NAME,
  1501795822: exports.APEX_NAME,
  42161: exports.ARBITRUM_NAME,
  324: exports.ZKSYNC_NAME,
  280: exports.ZKSYNCTEST_NAME,
  10: exports.OPTIMISM_NAME,
  59144: exports.LINEA_NAME,
  8453: exports.BASE_NAME,
  1130: exports.DEFI_NAME,
  30: exports.ROOTSTOCK_NAME
};
exports.MainNetworksById = {
  1: exports.MAINNET_NAME,
  56: exports.BSCMAIN_NAME,
  137: exports.POLYGON_MAINNET_NAME,
  43114: exports.AVALANCHE_NAME,
  // 1501795822: APEX_NAME,
  42161: exports.ARBITRUM_NAME,
  324: exports.ZKSYNC_NAME,
  10: exports.OPTIMISM_NAME,
  59144: exports.LINEA_NAME,
  8453: exports.BASE_NAME,
  1130: exports.DEFI_NAME,
  30: exports.ROOTSTOCK_NAME
};
exports.NetworksByName = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
  BSCMAIN: 56,
  BSCTEST: 97,
  POLYGON_MAINNET: 137,
  POLYGON_MUMBAI: 80001,
  AVALANCHE: 43114,
  AVALANCHE_FUJI: 43113,
  APEX: 1501795822,
  ARBITRUM: 42161,
  ZKSYNC: 324,
  ZKSYNCTEST: 280,
  OPTIMISM: 10,
  LINEA: 59144,
  BASE: 8453,
  DEFI: 1130,
  ROOTSTOCK: 30
};
exports.Networks = {
  ...exports.NetworksById,
  ...exports.MainNetworksById,
  ...exports.NetworksByName,
  MAINNET_NAME: exports.MAINNET_NAME,
  ROPSTEN_NAME: exports.ROPSTEN_NAME,
  RINKEBY_NAME: exports.RINKEBY_NAME,
  GOERLI_NAME: exports.GOERLI_NAME,
  KOVAN_NAME: exports.KOVAN_NAME,
  BSCMAIN_NAME: exports.BSCMAIN_NAME,
  BSCTEST_NAME: exports.BSCTEST_NAME,
  POLYGON_MAINNET_NAME: exports.POLYGON_MAINNET_NAME,
  POLYGON_MUMBAI_NAME: exports.POLYGON_MUMBAI_NAME,
  AVALANCHE_NAME: exports.AVALANCHE_NAME,
  AVALANCHE_FUJI_NAME: exports.AVALANCHE_FUJI_NAME,
  APEX_NAME: exports.APEX_NAME,
  ARBITRUM_NAME: exports.ARBITRUM_NAME,
  ZKSYNC_NAME: exports.ZKSYNC_NAME,
  ZKSYNCTEST_NAME: exports.ZKSYNCTEST_NAME,
  OPTIMISM_NAME: exports.OPTIMISM_NAME,
  LINEA_NAME: exports.LINEA_NAME,
  BASE_NAME: exports.BASE_NAME,
  DEFI_NAME: exports.DEFI_NAME,
  ROOTSTOCK_NAME: exports.ROOTSTOCK_NAME
};
exports.EthereumNetworks = [exports.Networks.MAINNET, exports.Networks.ROPSTEN, exports.Networks.RINKEBY, exports.Networks.GOERLI, exports.Networks.KOVAN];
exports.BscNetworks = [exports.Networks.BSCMAIN, exports.Networks.BSCTEST];
exports.PolygonNetworks = [exports.Networks.POLYGON_MAINNET, exports.Networks.POLYGON_MUMBAI];
exports.AvalancheNetworks = [exports.Networks.AVALANCHE, exports.Networks.AVALANCHE_FUJI];
exports.ApexNetworks = [exports.Networks.APEX];
exports.ArbitrumNetworks = [exports.Networks.ARBITRUM];
exports.ZksyncNetworks = [exports.Networks.ZKSYNC, exports.Networks.ZKSYNCTEST];
exports.OptimismNetworks = [exports.Networks.OPTIMISM];
exports.LineaNetworks = [exports.Networks.LINEA];
exports.BaseNetworks = [exports.Networks.BASE];
exports.DeFiNetworks = [exports.Networks.DEFI];
exports.RootstockNetworks = [exports.Networks.ROOTSTOCK];
