"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KyberQuoteV2 = void 0;
exports.KyberQuoteV2 = [{
  inputs: [{
    internalType: 'address',
    name: '_factory',
    type: 'address'
  }],
  stateMutability: 'nonpayable',
  type: 'constructor'
}, {
  inputs: [],
  name: 'factory',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bytes',
    name: 'path',
    type: 'bytes'
  }, {
    internalType: 'uint256',
    name: 'amountIn',
    type: 'uint256'
  }],
  name: 'quoteExactInput',
  outputs: [{
    internalType: 'uint256',
    name: 'amountOut',
    type: 'uint256'
  }, {
    internalType: 'uint160[]',
    name: 'afterSqrtPList',
    type: 'uint160[]'
  }, {
    internalType: 'uint32[]',
    name: 'initializedTicksCrossedList',
    type: 'uint32[]'
  }, {
    internalType: 'uint256',
    name: 'gasEstimate',
    type: 'uint256'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'address',
      name: 'tokenIn',
      type: 'address'
    }, {
      internalType: 'address',
      name: 'tokenOut',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: 'amountIn',
      type: 'uint256'
    }, {
      internalType: 'uint24',
      name: 'feeUnits',
      type: 'uint24'
    }, {
      internalType: 'uint160',
      name: 'limitSqrtP',
      type: 'uint160'
    }],
    internalType: 'struct IQuoterV2.QuoteExactInputSingleParams',
    name: 'params',
    type: 'tuple'
  }],
  name: 'quoteExactInputSingle',
  outputs: [{
    components: [{
      internalType: 'uint256',
      name: 'usedAmount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'returnedAmount',
      type: 'uint256'
    }, {
      internalType: 'uint160',
      name: 'afterSqrtP',
      type: 'uint160'
    }, {
      internalType: 'uint32',
      name: 'initializedTicksCrossed',
      type: 'uint32'
    }, {
      internalType: 'uint256',
      name: 'gasEstimate',
      type: 'uint256'
    }],
    internalType: 'struct IQuoterV2.QuoteOutput',
    name: 'output',
    type: 'tuple'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bytes',
    name: 'path',
    type: 'bytes'
  }, {
    internalType: 'uint256',
    name: 'amountOut',
    type: 'uint256'
  }],
  name: 'quoteExactOutput',
  outputs: [{
    internalType: 'uint256',
    name: 'amountIn',
    type: 'uint256'
  }, {
    internalType: 'uint160[]',
    name: 'afterSqrtPList',
    type: 'uint160[]'
  }, {
    internalType: 'uint32[]',
    name: 'initializedTicksCrossedList',
    type: 'uint32[]'
  }, {
    internalType: 'uint256',
    name: 'gasEstimate',
    type: 'uint256'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'address',
      name: 'tokenIn',
      type: 'address'
    }, {
      internalType: 'address',
      name: 'tokenOut',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint24',
      name: 'feeUnits',
      type: 'uint24'
    }, {
      internalType: 'uint160',
      name: 'limitSqrtP',
      type: 'uint160'
    }],
    internalType: 'struct IQuoterV2.QuoteExactOutputSingleParams',
    name: 'params',
    type: 'tuple'
  }],
  name: 'quoteExactOutputSingle',
  outputs: [{
    components: [{
      internalType: 'uint256',
      name: 'usedAmount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'returnedAmount',
      type: 'uint256'
    }, {
      internalType: 'uint160',
      name: 'afterSqrtP',
      type: 'uint160'
    }, {
      internalType: 'uint32',
      name: 'initializedTicksCrossed',
      type: 'uint32'
    }, {
      internalType: 'uint256',
      name: 'gasEstimate',
      type: 'uint256'
    }],
    internalType: 'struct IQuoterV2.QuoteOutput',
    name: 'output',
    type: 'tuple'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'int256',
    name: 'amount0Delta',
    type: 'int256'
  }, {
    internalType: 'int256',
    name: 'amount1Delta',
    type: 'int256'
  }, {
    internalType: 'bytes',
    name: 'path',
    type: 'bytes'
  }],
  name: 'swapCallback',
  outputs: [],
  stateMutability: 'view',
  type: 'function'
}];
