"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TypedContractBuilder = void 0;
class TypedContractBuilder {
  static plugIn(networkServices, factories) {
    return Object.keys(factories).reduce((acc, factoryKey) => {
      const {
        contract__factory,
        address
      } = factories[factoryKey];
      const className = factoryKey.replace('__factory', '');
      Object.keys(address).forEach(key => {
        const chainId = parseInt(key, 10);
        const address_ = address[chainId];
        const provider = networkServices[chainId].getProvider();
        if (!acc[chainId]) {
          acc[chainId] = {};
        }
        acc[chainId][className] = contract__factory.connect(address_, provider);
      });
      return acc;
    }, {});
  }
}
exports.TypedContractBuilder = TypedContractBuilder;
