"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OPPORTUNITY_ADAPTER = void 0;
exports.OPPORTUNITY_ADAPTER = [{
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'previousAdmin',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newAdmin',
    type: 'address'
  }],
  name: 'AdminChanged',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'beacon',
    type: 'address'
  }],
  name: 'BeaconUpgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'to',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'opportunityAddress',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'investmentAssetAddress',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'middleTokenAddress',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'outTokenAddress',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountIn',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'deductedAmount',
    type: 'uint256'
  }],
  name: 'CrossDexSwap',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'feeAmount',
    type: 'uint256'
  }],
  name: 'FeeDeducted',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint8',
    name: 'version',
    type: 'uint8'
  }],
  name: 'Initialized',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'to',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'opportunityAddress',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'investmentAssetAddress',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountIn',
    type: 'uint256'
  }],
  name: 'InvestIn',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'Upgraded',
  type: 'event'
}, {
  inputs: [{
    components: [{
      internalType: 'uint256',
      name: 'flag',
      type: 'uint256'
    }, {
      internalType: 'address',
      name: 'adr',
      type: 'address'
    }],
    internalType: 'struct CrossChainAdapter.OpportunityAddress[]',
    name: '_opportunityAddresses',
    type: 'tuple[]'
  }],
  name: 'addOpportunities',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint16',
    name: '_opportunityFlag',
    type: 'uint16'
  }, {
    internalType: 'address payable',
    name: '_onBehalfOfAddress',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_investmentAssetAddress',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_middleTokenAddress',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_outTokenAddress',
    type: 'address'
  }, {
    components: [{
      internalType: 'address',
      name: 'to',
      type: 'address'
    }, {
      internalType: 'bytes4',
      name: 'selector',
      type: 'bytes4'
    }, {
      internalType: 'uint256',
      name: 'value',
      type: 'uint256'
    }, {
      internalType: 'bytes[]',
      name: 'params',
      type: 'bytes[]'
    }, {
      internalType: 'bool',
      name: 'isReplace',
      type: 'bool'
    }, {
      internalType: 'uint8',
      name: 'index',
      type: 'uint8'
    }],
    internalType: 'struct ICrossDex.CallInfo',
    name: '_swap1',
    type: 'tuple'
  }, {
    components: [{
      internalType: 'address',
      name: 'to',
      type: 'address'
    }, {
      internalType: 'bytes4',
      name: 'selector',
      type: 'bytes4'
    }, {
      internalType: 'uint256',
      name: 'value',
      type: 'uint256'
    }, {
      internalType: 'bytes[]',
      name: 'params',
      type: 'bytes[]'
    }, {
      internalType: 'bool',
      name: 'isReplace',
      type: 'bool'
    }, {
      internalType: 'uint8',
      name: 'index',
      type: 'uint8'
    }],
    internalType: 'struct ICrossDex.CallInfo',
    name: '_swap2',
    type: 'tuple'
  }],
  name: 'crossDexSwap',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint16',
    name: '_opportunityFlag',
    type: 'uint16'
  }, {
    internalType: 'address payable',
    name: '_onBehalfOfAddress',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_investmentAssetAddress',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_outTokenAddress',
    type: 'address'
  }, {
    internalType: 'uint16',
    name: '_dexFlag',
    type: 'uint16'
  }, {
    internalType: 'string',
    name: '_presaleName',
    type: 'string'
  }, {
    components: [{
      internalType: 'address',
      name: 'to',
      type: 'address'
    }, {
      internalType: 'bytes4',
      name: 'selector',
      type: 'bytes4'
    }, {
      internalType: 'uint256',
      name: 'value',
      type: 'uint256'
    }, {
      internalType: 'bytes[]',
      name: 'params',
      type: 'bytes[]'
    }, {
      internalType: 'bool',
      name: 'isReplace',
      type: 'bool'
    }, {
      internalType: 'uint8',
      name: 'index',
      type: 'uint8'
    }],
    internalType: 'struct ICrossDex.CallInfo',
    name: '_swap',
    type: 'tuple'
  }],
  name: 'crowdSaleInvest',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'feePercentage',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'feeTo',
  outputs: [{
    internalType: 'address payable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'uint256',
      name: 'flag',
      type: 'uint256'
    }, {
      internalType: 'address',
      name: 'adr',
      type: 'address'
    }],
    internalType: 'struct CrossChainAdapter.OpportunityAddress[]',
    name: '_opportunityAddresses',
    type: 'tuple[]'
  }, {
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }, {
    internalType: 'address payable',
    name: '_feeTo',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_fixedDestinationTokenAddress',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_middleTokenAddress',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_swapContract',
    type: 'address'
  }],
  name: 'initialize',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint16',
    name: '_opportunityFlag',
    type: 'uint16'
  }, {
    internalType: 'address',
    name: '_investmentAssetAddress',
    type: 'address'
  }, {
    internalType: 'address payable',
    name: '_onBehalfOfAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amountIn',
    type: 'uint256'
  }, {
    internalType: 'bytes',
    name: '_data',
    type: 'bytes'
  }],
  name: 'investIn',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_onBehalfOfAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amountIn',
    type: 'uint256'
  }, {
    internalType: 'bytes',
    name: '_swapData0',
    type: 'bytes'
  }, {
    internalType: 'bytes',
    name: '_swapData1',
    type: 'bytes'
  }],
  name: 'multiSwap',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'opportunities',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'proxiableUUID',
  outputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }],
  name: 'setFeePercentage',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address payable',
    name: '_feeTo',
    type: 'address'
  }],
  name: 'setFeeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_fixedDestinationTokenAddress',
    type: 'address'
  }],
  name: 'setFixedDestinationTokenAddress',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_middleTokenAddress',
    type: 'address'
  }],
  name: 'setMiddleTokenAddress',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_swapContract',
    type: 'address'
  }],
  name: 'setSwapContract',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'swapContract',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }],
  name: 'upgradeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  name: 'upgradeToAndCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  stateMutability: 'payable',
  type: 'receive'
}];
