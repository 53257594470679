"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInterface = exports.isJsonFragmentArray = exports.toProvider = void 0;
const providers_1 = require("@ethersproject/providers");
function toProvider(provider) {
  if (Object.keys(provider).includes('currentProvider')) {
    return new providers_1.Web3Provider(provider.currentProvider);
  } else {
    return provider;
  }
}
exports.toProvider = toProvider;
function isJsonFragmentArray(input) {
  if (!Array.isArray(input)) return false;
  const inputKeys = Object.keys(input[0]);
  if (!inputKeys.includes('target') && !inputKeys.includes('function')) return true;
  return false;
}
exports.isJsonFragmentArray = isJsonFragmentArray;
function isInterface(input) {
  return input._isInterface;
}
exports.isInterface = isInterface;