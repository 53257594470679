"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ETFReceipt = void 0;
exports.ETFReceipt = [{
  "inputs": [],
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "address",
    "name": "previousAdmin",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "address",
    "name": "newAdmin",
    "type": "address"
  }],
  "name": "AdminChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "approved",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "Approval",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "bool",
    "name": "approved",
    "type": "bool"
  }],
  "name": "ApprovalForAll",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "beacon",
    "type": "address"
  }],
  "name": "BeaconUpgraded",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "Burned",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "newId",
    "type": "uint256"
  }],
  "name": "BurnedAndMinted",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "uint8",
    "name": "version",
    "type": "uint8"
  }],
  "name": "Initialized",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "Minted",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "previousOwner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "planId",
    "type": "uint256"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "tokenLength",
    "type": "uint256"
  }],
  "name": "PlanCreated",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "planId",
    "type": "uint256"
  }, {
    "indexed": false,
    "internalType": "bool",
    "name": "isActive",
    "type": "bool"
  }, {
    "indexed": false,
    "internalType": "string",
    "name": "name",
    "type": "string"
  }],
  "name": "PlanUpdated",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "Transfer",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "implementation",
    "type": "address"
  }],
  "name": "Upgraded",
  "type": "event"
}, {
  "inputs": [],
  "name": "MAX_P",
  "outputs": [{
    "internalType": "uint16",
    "name": "",
    "type": "uint16"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "approve",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }],
  "name": "balanceOf",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "_tokenId",
    "type": "uint256"
  }],
  "name": "burn",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint32",
    "name": "_tokenId",
    "type": "uint32"
  }, {
    "internalType": "address",
    "name": "_to",
    "type": "address"
  }, {
    "internalType": "uint16",
    "name": "_planId",
    "type": "uint16"
  }, {
    "components": [{
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint128",
      "name": "amount",
      "type": "uint128"
    }, {
      "internalType": "uint64",
      "name": "price",
      "type": "uint64"
    }],
    "internalType": "struct IETFReceipt.TokenDetail[]",
    "name": "_tokenDetails",
    "type": "tuple[]"
  }],
  "name": "burnAndMint",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "bytes",
    "name": "data",
    "type": "bytes"
  }],
  "name": "bytesToStruct",
  "outputs": [{
    "components": [{
      "internalType": "uint32",
      "name": "id",
      "type": "uint32"
    }, {
      "internalType": "uint16",
      "name": "planId",
      "type": "uint16"
    }, {
      "internalType": "uint32",
      "name": "createTime",
      "type": "uint32"
    }, {
      "components": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      }, {
        "internalType": "uint128",
        "name": "amount",
        "type": "uint128"
      }, {
        "internalType": "uint64",
        "name": "price",
        "type": "uint64"
      }],
      "internalType": "struct IETFReceipt.TokenDetail[]",
      "name": "tokenDetails",
      "type": "tuple[]"
    }],
    "internalType": "struct IETFReceipt.InvestDetail",
    "name": "",
    "type": "tuple"
  }],
  "stateMutability": "pure",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "_planId",
    "type": "uint256"
  }, {
    "internalType": "string",
    "name": "_name",
    "type": "string"
  }, {
    "internalType": "bool",
    "name": "_isActive",
    "type": "bool"
  }],
  "name": "changePlanActiveStatus",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "claimOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "string",
    "name": "_name",
    "type": "string"
  }, {
    "components": [{
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint16",
      "name": "percentage",
      "type": "uint16"
    }],
    "internalType": "struct IETFReceipt.TokenPercentage[]",
    "name": "_tokenPercentages",
    "type": "tuple[]"
  }],
  "name": "createPlan",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "getAllPlans",
  "outputs": [{
    "components": [{
      "internalType": "uint256",
      "name": "id",
      "type": "uint256"
    }, {
      "internalType": "string",
      "name": "name",
      "type": "string"
    }, {
      "internalType": "bool",
      "name": "active",
      "type": "bool"
    }, {
      "components": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      }, {
        "internalType": "uint16",
        "name": "percentage",
        "type": "uint16"
      }],
      "internalType": "struct IETFReceipt.TokenPercentage[]",
      "name": "tokenPercentages",
      "type": "tuple[]"
    }],
    "internalType": "struct IETFReceipt.PlanDetail[]",
    "name": "",
    "type": "tuple[]"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "getApproved",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_owner",
    "type": "address"
  }],
  "name": "getTokensByOwner",
  "outputs": [{
    "components": [{
      "internalType": "uint32",
      "name": "id",
      "type": "uint32"
    }, {
      "internalType": "uint16",
      "name": "planId",
      "type": "uint16"
    }, {
      "internalType": "uint32",
      "name": "createTime",
      "type": "uint32"
    }, {
      "components": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      }, {
        "internalType": "uint128",
        "name": "amount",
        "type": "uint128"
      }, {
        "internalType": "uint64",
        "name": "price",
        "type": "uint64"
      }],
      "internalType": "struct IETFReceipt.TokenDetail[]",
      "name": "tokenDetails",
      "type": "tuple[]"
    }],
    "internalType": "struct IETFReceipt.InvestDetail[]",
    "name": "",
    "type": "tuple[]"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "string",
    "name": "name",
    "type": "string"
  }, {
    "internalType": "string",
    "name": "symbol",
    "type": "string"
  }],
  "name": "initialize",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }],
  "name": "isApprovedForAll",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_to",
    "type": "address"
  }, {
    "internalType": "uint16",
    "name": "_planId",
    "type": "uint16"
  }, {
    "components": [{
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint128",
      "name": "amount",
      "type": "uint128"
    }, {
      "internalType": "uint64",
      "name": "price",
      "type": "uint64"
    }],
    "internalType": "struct IETFReceipt.TokenDetail[]",
    "name": "_tokenDetails",
    "type": "tuple[]"
  }],
  "name": "mint",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "name",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "owner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "ownerOf",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "pendingOwner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "_planId",
    "type": "uint256"
  }],
  "name": "planByPlanId",
  "outputs": [{
    "components": [{
      "internalType": "uint256",
      "name": "id",
      "type": "uint256"
    }, {
      "internalType": "string",
      "name": "name",
      "type": "string"
    }, {
      "internalType": "bool",
      "name": "active",
      "type": "bool"
    }, {
      "components": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      }, {
        "internalType": "uint16",
        "name": "percentage",
        "type": "uint16"
      }],
      "internalType": "struct IETFReceipt.TokenPercentage[]",
      "name": "tokenPercentages",
      "type": "tuple[]"
    }],
    "internalType": "struct IETFReceipt.PlanDetail",
    "name": "",
    "type": "tuple"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "name": "planTokenPercentages",
  "outputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "internalType": "uint16",
    "name": "percentage",
    "type": "uint16"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "name": "plans",
  "outputs": [{
    "internalType": "string",
    "name": "name",
    "type": "string"
  }, {
    "internalType": "bool",
    "name": "active",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "proxiableUUID",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "safeTransferFrom",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }, {
    "internalType": "bytes",
    "name": "data",
    "type": "bytes"
  }],
  "name": "safeTransferFrom",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }, {
    "internalType": "bool",
    "name": "approved",
    "type": "bool"
  }],
  "name": "setApprovalForAll",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_ETFProxyAddress",
    "type": "address"
  }],
  "name": "setETFProxyAddress",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "uint32",
      "name": "id",
      "type": "uint32"
    }, {
      "internalType": "uint16",
      "name": "planId",
      "type": "uint16"
    }, {
      "internalType": "uint32",
      "name": "createTime",
      "type": "uint32"
    }, {
      "components": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      }, {
        "internalType": "uint128",
        "name": "amount",
        "type": "uint128"
      }, {
        "internalType": "uint64",
        "name": "price",
        "type": "uint64"
      }],
      "internalType": "struct IETFReceipt.TokenDetail[]",
      "name": "tokenDetails",
      "type": "tuple[]"
    }],
    "internalType": "struct IETFReceipt.InvestDetail",
    "name": "_struct",
    "type": "tuple"
  }],
  "name": "structToBytes",
  "outputs": [{
    "internalType": "bytes",
    "name": "",
    "type": "bytes"
  }],
  "stateMutability": "pure",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "bytes4",
    "name": "interfaceId",
    "type": "bytes4"
  }],
  "name": "supportsInterface",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "symbol",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "index",
    "type": "uint256"
  }],
  "name": "tokenByIndex",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_userAddress",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "_tokenId",
    "type": "uint256"
  }],
  "name": "tokenByTokenId",
  "outputs": [{
    "components": [{
      "internalType": "uint32",
      "name": "id",
      "type": "uint32"
    }, {
      "internalType": "uint16",
      "name": "planId",
      "type": "uint16"
    }, {
      "internalType": "uint32",
      "name": "createTime",
      "type": "uint32"
    }, {
      "components": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
      }, {
        "internalType": "uint128",
        "name": "amount",
        "type": "uint128"
      }, {
        "internalType": "uint64",
        "name": "price",
        "type": "uint64"
      }],
      "internalType": "struct IETFReceipt.TokenDetail[]",
      "name": "tokenDetails",
      "type": "tuple[]"
    }],
    "internalType": "struct IETFReceipt.InvestDetail",
    "name": "",
    "type": "tuple"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "index",
    "type": "uint256"
  }],
  "name": "tokenOfOwnerByIndex",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "tokenURI",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "totalSupply",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  }],
  "name": "transferFrom",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "transferOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newImplementation",
    "type": "address"
  }],
  "name": "upgradeTo",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newImplementation",
    "type": "address"
  }, {
    "internalType": "bytes",
    "name": "data",
    "type": "bytes"
  }],
  "name": "upgradeToAndCall",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
}];
