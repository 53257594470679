"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Path = void 0;
/// @title Functions for manipulating path data for multihop swaps
class Path {
  /// @notice Returns true iff the path contains two or more pools
  /// @param path The encoded swap path
  /// @return True if path contains two or more pools, otherwise false
  static hasMultiplePools(path) {
    return path.length >= Path.MULTIPLE_POOLS_MIN_LENGTH;
  }
  /// @notice Skips a token + fee element from the buffer and returns the remainder
  /// @param path The swap path
  /// @return The remaining token + fee elements in the path
  static skipToken(path) {
    return path.slice(Path.NEXT_OFFSET, path.length);
  }
  static realizePath(pathContainsFee) {
    if (pathContainsFee.length < Path.NEXT_OFFSET) {
      return [];
    }
    pathContainsFee = pathContainsFee.replace('0x', '');
    let path = ['0x' + pathContainsFee.slice(0, Path.ADDR_SIZE), '0x' + pathContainsFee.slice(Path.NEXT_OFFSET, Path.POP_OFFSET)];
    while (Path.hasMultiplePools(pathContainsFee)) {
      pathContainsFee = Path.skipToken(pathContainsFee);
      path.push('0x' + pathContainsFee.slice(Path.NEXT_OFFSET, Path.POP_OFFSET));
    }
    return path;
  }
}
exports.Path = Path;
/// @dev The length of the string encoded address
Path.ADDR_SIZE = 40;
/// @dev The length of the string encoded fee
Path.FEE_SIZE = 6;
/// @dev The offset of a single token address and pool fee
Path.NEXT_OFFSET = Path.ADDR_SIZE + Path.FEE_SIZE;
/// @dev The offset of an encoded pool key
Path.POP_OFFSET = Path.NEXT_OFFSET + Path.ADDR_SIZE;
/// @dev The minimum length of an encoding that contains 2 or more pools
Path.MULTIPLE_POOLS_MIN_LENGTH = Path.POP_OFFSET + Path.NEXT_OFFSET;
