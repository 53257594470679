"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrivatesaleOpportunity = void 0;
exports.PrivatesaleOpportunity = [{
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'previousAdmin',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newAdmin',
    type: 'address'
  }],
  name: 'AdminChanged',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'beacon',
    type: 'address'
  }],
  name: 'BeaconUpgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'feeAmount',
    type: 'uint256'
  }],
  name: 'FeeDeducted',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint8',
    name: 'version',
    type: 'uint8'
  }],
  name: 'Initialized',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'string',
    name: 'presaleName',
    type: 'string'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'Invested',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'string',
    name: 'presaleName',
    type: 'string'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'InvestedByToken',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Paused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'string',
    name: 'presaleName',
    type: 'string'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'Purchased',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'string',
    name: 'presaleName',
    type: 'string'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'fromToken',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'toToken',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountIn',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amountOut',
    type: 'uint256'
  }],
  name: 'Swapped',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Unpaused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'Upgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'receiver',
    type: 'address'
  }],
  name: 'WithdrawnFunds',
  type: 'event'
}, {
  inputs: [{
    internalType: 'address[]',
    name: 'whiteListedInvestorsList_',
    type: 'address[]'
  }],
  name: 'addWhiteListedInvestors',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '_presaleName',
    type: 'string'
  }, {
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }],
  name: 'buyTicket',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'factoryAddress',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'feeTo',
  outputs: [{
    internalType: 'address payable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'fractalContract_',
    type: 'address'
  }, {
    internalType: 'address[]',
    name: 'whiteListedInvestorsList_',
    type: 'address[]'
  }, {
    internalType: 'address',
    name: 'swapContract_',
    type: 'address'
  }, {
    internalType: 'address payable',
    name: 'feeTo_',
    type: 'address'
  }],
  name: 'initialize',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '_presaleName',
    type: 'string'
  }, {
    internalType: 'address',
    name: '_investToken',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_ticketToken',
    type: 'address'
  }, {
    internalType: 'string',
    name: '_fractalListId',
    type: 'string'
  }, {
    internalType: 'address',
    name: '_presaleContract',
    type: 'address'
  }],
  name: 'insertPresaleProject',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '_presaleName',
    type: 'string'
  }, {
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }],
  name: 'invest',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '_presaleName',
    type: 'string'
  }, {
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'contract IERC20Upgradeable',
    name: '_token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    internalType: 'bytes',
    name: '_swapData',
    type: 'bytes'
  }],
  name: 'investByToken',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '_presaleName',
    type: 'string'
  }, {
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }],
  name: 'isKYC',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pause',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'paused',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '',
    type: 'string'
  }],
  name: 'presaleProject',
  outputs: [{
    internalType: 'address',
    name: 'investToken',
    type: 'address'
  }, {
    internalType: 'address',
    name: 'ticketToken',
    type: 'address'
  }, {
    internalType: 'string',
    name: 'fractalListId',
    type: 'string'
  }, {
    internalType: 'address',
    name: 'presaleContract',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'feePercentage',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: 'pause',
    type: 'bool'
  }, {
    internalType: 'bool',
    name: 'exist',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'proxiableUUID',
  outputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'factoryAddress_',
    type: 'address'
  }],
  name: 'setFactoryAddress',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address payable',
    name: 'feeTo_',
    type: 'address'
  }],
  name: 'setFeeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'fractalContract_',
    type: 'address'
  }],
  name: 'setFractalContract',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'swapContract',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'unpause',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'string',
    name: '_presaleName',
    type: 'string'
  }, {
    internalType: 'address',
    name: '_investToken',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_ticketToken',
    type: 'address'
  }, {
    internalType: 'string',
    name: '_fractalListId',
    type: 'string'
  }, {
    internalType: 'address',
    name: '_presaleContract',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_feePercentage',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: '_pause',
    type: 'bool'
  }],
  name: 'updatePresaleProject',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }],
  name: 'upgradeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  name: 'upgradeToAndCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    internalType: 'address payable',
    name: '_receiver',
    type: 'address'
  }],
  name: 'withdrawFunds',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}];
