import { Injectable } from '@angular/core';
import {
  NzNotificationDataOptions,
  NzNotificationService
} from 'ng-zorro-antd/notification';

export interface INotificationData<T> {
  title: string;
  content: string;
  options?: T;
}

@Injectable()
export class NotificationService {
  constructor(protected nzNotificationService: NzNotificationService) {}

  error<T>(data: INotificationData<T>) {
    this.nzNotificationService.error(
      data.title,
      data.content,
      <NzNotificationDataOptions<{}> | undefined>data.options ?? {
        nzClass: '',
        nzDuration: 10000
      }
    );
  }

  success<T>(data: INotificationData<T>) {
    this.nzNotificationService.success(
      data.title,
      data.content,
      <NzNotificationDataOptions<{}> | undefined>data.options ?? {
        nzClass: '',
        nzDuration: 10000
      }
    );
  }
}
