"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getReserves = void 0;
const ethers_1 = require("ethers");
const utils_1 = require("ethers/lib/utils");
const utils_2 = require("./utils");
const bytecode_json_1 = require("./bytecode.json");
async function getReserves(provider_, pairs) {
  const provider = utils_2.toProvider(provider_);
  const inputData = utils_1.defaultAbiCoder.encode(['address[]'], [pairs]);
  const bytecode = bytecode_json_1.UniswapReservesGetter.concat(inputData.slice(2));
  const encodedReturnData = await provider.call({
    data: bytecode
  });
  const [blockNumber, decodedReturnData] = utils_1.defaultAbiCoder.decode(['uint256', 'bytes32[]'], encodedReturnData);
  const allReserves = {};
  for (let i = 0; i < pairs.length; i++) {
    const decodedData = Buffer.from(decodedReturnData[i].slice(2), 'hex');
    const r0_bytes = decodedData.slice(0, 14);
    const r1_bytes = decodedData.slice(14, 28);
    const t_bytes = decodedData.slice(28);
    const pair = pairs[i];
    allReserves[pair] = {
      reserve0: ethers_1.BigNumber.from(r0_bytes),
      reserve1: ethers_1.BigNumber.from(r1_bytes),
      blockTimestampLast: ethers_1.BigNumber.from(t_bytes).toNumber()
    };
  }
  return [blockNumber.toNumber(), allReserves];
}
exports.getReserves = getReserves;