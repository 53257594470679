import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'DateTimeToDisplay'
})
export class DateTimeToDisplay implements PipeTransform {
  transform(data: number): string {
    const date = new Date(data)
      .toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/\,/g, '')
      .replace(/\//g, ' ');

    const time = new Date(data).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit'
    });

    return `
    <div class="d-flex align-items-center">
      <span class="font-14">
        {1}
      </span>
      <span class="font-12 ml-10px">
        {2}
      </span>
    </div>
    `
      .replace('{1}', date)
      .replace('{2}', time);
  }
}
