"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOCKED_STAKING_REWARDS = void 0;
exports.LOCKED_STAKING_REWARDS = [{
  inputs: [],
  stateMutability: 'nonpayable',
  type: 'constructor'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'previousAdmin',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newAdmin',
    type: 'address'
  }],
  name: 'AdminChanged',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'beacon',
    type: 'address'
  }],
  name: 'BeaconUpgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'planId',
    type: 'uint256'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'oldStakeId',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'newStakeId',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'startTime',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'endTime',
    type: 'uint256'
  }],
  name: 'Extended',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'totalFee',
    type: 'uint256'
  }],
  name: 'FeeDeducted',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint8',
    name: 'version',
    type: 'uint8'
  }],
  name: 'Initialized',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Paused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'operator',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'planId',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'duration',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'apr',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'defaultApr',
    type: 'uint256'
  }],
  name: 'PlanCreated',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'operator',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'planId',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'newDuration',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'newApr',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'newDefaultApr',
    type: 'uint256'
  }],
  name: 'PlanUpdated',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'feeTo',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'stakeFee',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'unstakeFee',
    type: 'uint256'
  }],
  name: 'SetFee',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'planId',
    type: 'uint256'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'stakeId',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'startTime',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'endTime',
    type: 'uint256'
  }],
  name: 'Staked',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Unpaused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'Upgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'planId',
    type: 'uint256'
  }, {
    indexed: true,
    internalType: 'uint256',
    name: 'stakeId',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'startTime',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'endTime',
    type: 'uint256'
  }],
  name: 'Withdrawn',
  type: 'event'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint128',
    name: '_duration',
    type: 'uint128'
  }, {
    internalType: 'uint16',
    name: '_apr',
    type: 'uint16'
  }, {
    internalType: 'uint16',
    name: '_defaultApr',
    type: 'uint16'
  }],
  name: 'createPlan',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_stakeId',
    type: 'uint256'
  }],
  name: 'extend',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'feeInfo',
  outputs: [{
    internalType: 'address payable',
    name: 'feeTo',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'stakeFee',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'unstakeFee',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'getAllPlans',
  outputs: [{
    components: [{
      internalType: 'uint256',
      name: 'id',
      type: 'uint256'
    }, {
      internalType: 'uint128',
      name: 'duration',
      type: 'uint128'
    }, {
      internalType: 'uint16',
      name: 'apr',
      type: 'uint16'
    }, {
      internalType: 'uint16',
      name: 'defaultApr',
      type: 'uint16'
    }, {
      internalType: 'bool',
      name: 'active',
      type: 'bool'
    }, {
      internalType: 'bool',
      name: 'exists',
      type: 'bool'
    }],
    internalType: 'struct LockableStakingRewards.Plan[]',
    name: '',
    type: 'tuple[]'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'getStakedAddressCount',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '_stakeId',
    type: 'uint256'
  }],
  name: 'getUserReward',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }],
  name: 'getUserStakingRecords',
  outputs: [{
    components: [{
      internalType: 'uint256',
      name: 'id',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'planId',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'reward',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'paidAmount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'startTime',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'endTime',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'lastWithdrawalTime',
      type: 'uint256'
    }, {
      internalType: 'bool',
      name: 'archived',
      type: 'bool'
    }],
    internalType: 'struct LockableStakingRewards.Stake[]',
    name: '',
    type: 'tuple[]'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_userAddress',
    type: 'address'
  }],
  name: 'getUserTotalStakedAmount',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'hasStaked',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_stakingToken',
    type: 'address'
  }, {
    components: [{
      internalType: 'address payable',
      name: 'feeTo',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: 'stakeFee',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'unstakeFee',
      type: 'uint256'
    }],
    internalType: 'struct LockableStakingRewards.FeeInfo',
    name: '_feeInfo',
    type: 'tuple'
  }],
  name: 'initialize',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pause',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'paused',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'planCounter',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'planInvestmentInfo',
  outputs: [{
    internalType: 'uint256',
    name: 'totalInvestAmount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'totalInvestCount',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'plans',
  outputs: [{
    internalType: 'uint256',
    name: 'id',
    type: 'uint256'
  }, {
    internalType: 'uint128',
    name: 'duration',
    type: 'uint128'
  }, {
    internalType: 'uint16',
    name: 'apr',
    type: 'uint16'
  }, {
    internalType: 'uint16',
    name: 'defaultApr',
    type: 'uint16'
  }, {
    internalType: 'bool',
    name: 'active',
    type: 'bool'
  }, {
    internalType: 'bool',
    name: 'exists',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'proxiableUUID',
  outputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    components: [{
      internalType: 'address payable',
      name: 'feeTo',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: 'stakeFee',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'unstakeFee',
      type: 'uint256'
    }],
    internalType: 'struct LockableStakingRewards.FeeInfo',
    name: '_feeInfo',
    type: 'tuple'
  }],
  name: 'setFee',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_planId',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }],
  name: 'stake',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'stakeCounter',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'stakerList',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'stakingToken',
  outputs: [{
    internalType: 'contract IERC20Upgradeable',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'unpause',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_planId',
    type: 'uint256'
  }, {
    internalType: 'uint128',
    name: '_newDuration',
    type: 'uint128'
  }, {
    internalType: 'uint16',
    name: '_newApr',
    type: 'uint16'
  }, {
    internalType: 'uint16',
    name: '_newDefaultApr',
    type: 'uint16'
  }, {
    internalType: 'bool',
    name: '_newActive',
    type: 'bool'
  }],
  name: 'updatePlan',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }],
  name: 'upgradeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  name: 'upgradeToAndCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'userStakes',
  outputs: [{
    internalType: 'uint256',
    name: 'id',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'planId',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'reward',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'paidAmount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'startTime',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'endTime',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'lastWithdrawalTime',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: 'archived',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_stakeId',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: '_max',
    type: 'bool'
  }],
  name: 'withdraw',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}];
