"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEBRIDGE_SWAP = void 0;
exports.DEBRIDGE_SWAP = {
  crossChainForwarderABI: [{
    inputs: [{
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }],
    name: 'NotEnoughSrcFundsIn',
    type: 'error'
  }, {
    inputs: [],
    name: 'SignatureInvalidV',
    type: 'error'
  }, {
    inputs: [{
      internalType: 'address',
      name: 'srcTokenOut',
      type: 'address'
    }],
    name: 'SwapEmptyResult',
    type: 'error'
  }, {
    inputs: [{
      internalType: 'address',
      name: 'srcRouter',
      type: 'address'
    }],
    name: 'SwapFailed',
    type: 'error'
  }, {
    inputs: [],
    name: 'WrongArgumentLength',
    type: 'error'
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'bytes32',
      name: 'previousAdminRole',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'bytes32',
      name: 'newAdminRole',
      type: 'bytes32'
    }],
    name: 'RoleAdminChanged',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'account',
      type: 'address'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'sender',
      type: 'address'
    }],
    name: 'RoleGranted',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'account',
      type: 'address'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'sender',
      type: 'address'
    }],
    name: 'RoleRevoked',
    type: 'event'
  }, {
    inputs: [],
    name: 'BPS_DENOMINATOR',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'NATIVE_TOKEN',
    outputs: [{
      internalType: 'address',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'deBridgeGate',
    outputs: [{
      internalType: 'contract IDeBridgeGate',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }],
    name: 'getRoleAdmin',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'hasRole',
    outputs: [{
      internalType: 'bool',
      name: '',
      type: 'bool'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'contract IDeBridgeGate',
      name: '_deBridgeGate',
      type: 'address'
    }],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '_srcTokenIn',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: '_srcInAmount',
      type: 'uint256'
    }, {
      internalType: 'bytes',
      name: '_srcTokenInPermit',
      type: 'bytes'
    }, {
      internalType: 'bytes',
      name: '_dstDetails',
      type: 'bytes'
    }],
    name: 'send',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes4',
      name: 'interfaceId',
      type: 'bytes4'
    }],
    name: 'supportsInterface',
    outputs: [{
      internalType: 'bool',
      name: '',
      type: 'bool'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '_srcTokenIn',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: '_srcAmountIn',
      type: 'uint256'
    }, {
      internalType: 'bytes',
      name: '_srcTokenInPermit',
      type: 'bytes'
    }, {
      internalType: 'address',
      name: '_srcSwapRouter',
      type: 'address'
    }, {
      internalType: 'bytes',
      name: '_srcSwapCalldata',
      type: 'bytes'
    }, {
      internalType: 'address',
      name: '_srcTokenOut',
      type: 'address'
    }, {
      internalType: 'bytes',
      name: '_dstDetails',
      type: 'bytes'
    }],
    name: 'swapAndSend',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  }, {
    stateMutability: 'payable',
    type: 'receive'
  }],
  receivingForwarderABI: [{
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'bytes32',
      name: 'previousAdminRole',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'bytes32',
      name: 'newAdminRole',
      type: 'bytes32'
    }],
    name: 'RoleAdminChanged',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'account',
      type: 'address'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'sender',
      type: 'address'
    }],
    name: 'RoleGranted',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'account',
      type: 'address'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'sender',
      type: 'address'
    }],
    name: 'RoleRevoked',
    type: 'event'
  }, {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'NATIVE_TOKEN',
    outputs: [{
      internalType: 'address',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '_dstTokenIn',
      type: 'address'
    }, {
      internalType: 'address',
      name: '_router',
      type: 'address'
    }, {
      internalType: 'bytes',
      name: '_routerCalldata',
      type: 'bytes'
    }, {
      internalType: 'address',
      name: '_dstTokenOut',
      type: 'address'
    }, {
      internalType: 'address',
      name: '_fallbackAddress',
      type: 'address'
    }],
    name: 'forward',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }],
    name: 'getRoleAdmin',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'hasRole',
    outputs: [{
      internalType: 'bool',
      name: '',
      type: 'bool'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes4',
      name: 'interfaceId',
      type: 'bytes4'
    }],
    name: 'supportsInterface',
    outputs: [{
      internalType: 'bool',
      name: '',
      type: 'bool'
    }],
    stateMutability: 'view',
    type: 'function'
  }],
  deBridgeGateABI: [{
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor'
  }, {
    inputs: [],
    name: 'AdminBadRole',
    type: 'error'
  }, {
    inputs: [],
    name: 'AssetAlreadyExist',
    type: 'error'
  }, {
    inputs: [],
    name: 'DebridgeNotFound',
    type: 'error'
  }, {
    inputs: [],
    name: 'EthTransferFailed',
    type: 'error'
  }, {
    inputs: [],
    name: 'FeeContractUpdaterBadRole',
    type: 'error'
  }, {
    inputs: [],
    name: 'FeeProxyBadRole',
    type: 'error'
  }, {
    inputs: [],
    name: 'GovMonitoringBadRole',
    type: 'error'
  }, {
    inputs: [],
    name: 'InvalidTokenToSend',
    type: 'error'
  }, {
    inputs: [],
    name: 'NotEnoughReserves',
    type: 'error'
  }, {
    inputs: [],
    name: 'NotSupportedFixedFee',
    type: 'error'
  }, {
    inputs: [],
    name: 'ProposedFeeTooHigh',
    type: 'error'
  }, {
    inputs: [],
    name: 'SignatureInvalidV',
    type: 'error'
  }, {
    inputs: [],
    name: 'SubmissionBlocked',
    type: 'error'
  }, {
    inputs: [],
    name: 'SubmissionUsed',
    type: 'error'
  }, {
    inputs: [],
    name: 'TransferAmountNotCoverFees',
    type: 'error'
  }, {
    inputs: [],
    name: 'TransferAmountTooHigh',
    type: 'error'
  }, {
    inputs: [],
    name: 'WrongArgument',
    type: 'error'
  }, {
    inputs: [],
    name: 'WrongArgumentLength',
    type: 'error'
  }, {
    inputs: [],
    name: 'WrongAutoArgument',
    type: 'error'
  }, {
    inputs: [],
    name: 'WrongChainFrom',
    type: 'error'
  }, {
    inputs: [],
    name: 'WrongChainTo',
    type: 'error'
  }, {
    inputs: [],
    name: 'ZeroAddress',
    type: 'error'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'bytes32',
      name: 'submissionId',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'bool',
      name: 'success',
      type: 'bool'
    }, {
      indexed: false,
      internalType: 'address',
      name: 'callProxy',
      type: 'address'
    }],
    name: 'AutoRequestExecuted',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'bytes32',
      name: 'submissionId',
      type: 'bytes32'
    }],
    name: 'Blocked',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'address',
      name: 'callProxy',
      type: 'address'
    }],
    name: 'CallProxyUpdated',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'uint256',
      name: 'chainId',
      type: 'uint256'
    }, {
      indexed: false,
      internalType: 'bool',
      name: 'isSupported',
      type: 'bool'
    }, {
      indexed: false,
      internalType: 'bool',
      name: 'isChainFrom',
      type: 'bool'
    }],
    name: 'ChainSupportUpdated',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'uint256',
      name: 'chainIds',
      type: 'uint256'
    }, {
      components: [{
        internalType: 'uint256',
        name: 'fixedNativeFee',
        type: 'uint256'
      }, {
        internalType: 'bool',
        name: 'isSupported',
        type: 'bool'
      }, {
        internalType: 'uint16',
        name: 'transferFeeBps',
        type: 'uint16'
      }],
      indexed: false,
      internalType: 'struct IDeBridgeGate.ChainSupportInfo',
      name: 'chainSupportInfo',
      type: 'tuple'
    }, {
      indexed: false,
      internalType: 'bool',
      name: 'isChainFrom',
      type: 'bool'
    }],
    name: 'ChainsSupportUpdated',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'bytes32',
      name: 'submissionId',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'bytes32',
      name: 'debridgeId',
      type: 'bytes32'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'receiver',
      type: 'address'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'nonce',
      type: 'uint256'
    }, {
      indexed: true,
      internalType: 'uint256',
      name: 'chainIdFrom',
      type: 'uint256'
    }, {
      indexed: false,
      internalType: 'bytes',
      name: 'autoParams',
      type: 'bytes'
    }, {
      indexed: false,
      internalType: 'bool',
      name: 'isNativeToken',
      type: 'bool'
    }],
    name: 'Claimed',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'uint256',
      name: 'globalFixedNativeFee',
      type: 'uint256'
    }],
    name: 'FixedNativeFeeAutoUpdated',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'uint256',
      name: 'globalFixedNativeFee',
      type: 'uint256'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'globalTransferFeeBps',
      type: 'uint256'
    }],
    name: 'FixedNativeFeeUpdated',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'bytes32',
      name: 'submissionId',
      type: 'bytes32'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'lockedOrMintedAmount',
      type: 'uint256'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'totalSupply',
      type: 'uint256'
    }],
    name: 'MonitoringClaimEvent',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'bytes32',
      name: 'submissionId',
      type: 'bytes32'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'nonce',
      type: 'uint256'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'lockedOrMintedAmount',
      type: 'uint256'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'totalSupply',
      type: 'uint256'
    }],
    name: 'MonitoringSendEvent',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'bytes32',
      name: 'debridgeId',
      type: 'bytes32'
    }, {
      indexed: false,
      internalType: 'address',
      name: 'tokenAddress',
      type: 'address'
    }, {
      indexed: false,
      internalType: 'bytes',
      name: 'nativeAddress',
      type: 'bytes'
    }, {
      indexed: true,
      internalType: 'uint256',
      name: 'nativeChainId',
      type: 'uint256'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'maxAmount',
      type: 'uint256'
    }, {
      indexed: false,
      internalType: 'uint16',
      name: 'minReservesBps',
      type: 'uint16'
    }],
    name: 'PairAdded',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'Paused',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'bytes32',
      name: 'previousAdminRole',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'bytes32',
      name: 'newAdminRole',
      type: 'bytes32'
    }],
    name: 'RoleAdminChanged',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'account',
      type: 'address'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'sender',
      type: 'address'
    }],
    name: 'RoleGranted',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'account',
      type: 'address'
    }, {
      indexed: true,
      internalType: 'address',
      name: 'sender',
      type: 'address'
    }],
    name: 'RoleRevoked',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'bytes32',
      name: 'submissionId',
      type: 'bytes32'
    }, {
      indexed: true,
      internalType: 'bytes32',
      name: 'debridgeId',
      type: 'bytes32'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256'
    }, {
      indexed: false,
      internalType: 'bytes',
      name: 'receiver',
      type: 'bytes'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'nonce',
      type: 'uint256'
    }, {
      indexed: true,
      internalType: 'uint256',
      name: 'chainIdTo',
      type: 'uint256'
    }, {
      indexed: false,
      internalType: 'uint32',
      name: 'referralCode',
      type: 'uint32'
    }, {
      components: [{
        internalType: 'uint256',
        name: 'receivedAmount',
        type: 'uint256'
      }, {
        internalType: 'uint256',
        name: 'fixFee',
        type: 'uint256'
      }, {
        internalType: 'uint256',
        name: 'transferFee',
        type: 'uint256'
      }, {
        internalType: 'bool',
        name: 'useAssetFee',
        type: 'bool'
      }, {
        internalType: 'bool',
        name: 'isNativeToken',
        type: 'bool'
      }],
      indexed: false,
      internalType: 'struct IDeBridgeGate.FeeParams',
      name: 'feeParams',
      type: 'tuple'
    }, {
      indexed: false,
      internalType: 'bytes',
      name: 'autoParams',
      type: 'bytes'
    }, {
      indexed: false,
      internalType: 'address',
      name: 'nativeSender',
      type: 'address'
    }],
    name: 'Sent',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'bytes32',
      name: 'submissionId',
      type: 'bytes32'
    }],
    name: 'Unblocked',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'Unpaused',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'bytes32',
      name: 'debridgeId',
      type: 'bytes32'
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'fee',
      type: 'uint256'
    }],
    name: 'WithdrawnFee',
    type: 'event'
  }, {
    inputs: [],
    name: 'BPS_DENOMINATOR',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'DEPLOY_PREFIX',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'GOVMONITORING_ROLE',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'SUBMISSION_PREFIX',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint256',
      name: '_globalFixedNativeFee',
      type: 'uint256'
    }],
    name: 'autoUpdateFixedNativeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32[]',
      name: '_submissionIds',
      type: 'bytes32[]'
    }, {
      internalType: 'bool',
      name: 'isBlocked',
      type: 'bool'
    }],
    name: 'blockSubmission',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [],
    name: 'callProxy',
    outputs: [{
      internalType: 'address',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '_debridgeId',
      type: 'bytes32'
    }, {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: '_chainIdFrom',
      type: 'uint256'
    }, {
      internalType: 'address',
      name: '_receiver',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: '_nonce',
      type: 'uint256'
    }, {
      internalType: 'bytes',
      name: '_signatures',
      type: 'bytes'
    }, {
      internalType: 'bytes',
      name: '_autoParams',
      type: 'bytes'
    }],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [],
    name: 'deBridgeTokenDeployer',
    outputs: [{
      internalType: 'address',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes',
      name: '_nativeTokenAddress',
      type: 'bytes'
    }, {
      internalType: 'uint256',
      name: '_nativeChainId',
      type: 'uint256'
    }, {
      internalType: 'string',
      name: '_name',
      type: 'string'
    }, {
      internalType: 'string',
      name: '_symbol',
      type: 'string'
    }, {
      internalType: 'uint8',
      name: '_decimals',
      type: 'uint8'
    }, {
      internalType: 'bytes',
      name: '_signatures',
      type: 'bytes'
    }],
    name: 'deployNewAsset',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [],
    name: 'excessConfirmations',
    outputs: [{
      internalType: 'uint8',
      name: '',
      type: 'uint8'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'feeContractUpdater',
    outputs: [{
      internalType: 'address',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '',
      type: 'address'
    }],
    name: 'feeDiscount',
    outputs: [{
      internalType: 'uint16',
      name: 'discountFixBps',
      type: 'uint16'
    }, {
      internalType: 'uint16',
      name: 'discountTransferBps',
      type: 'uint16'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'feeProxy',
    outputs: [{
      internalType: 'address',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'gap0',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'gap1',
    outputs: [{
      internalType: 'address',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    name: 'getAmountThreshold',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    name: 'getChainFromConfig',
    outputs: [{
      internalType: 'uint256',
      name: 'fixedNativeFee',
      type: 'uint256'
    }, {
      internalType: 'bool',
      name: 'isSupported',
      type: 'bool'
    }, {
      internalType: 'uint16',
      name: 'transferFeeBps',
      type: 'uint16'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'getChainId',
    outputs: [{
      internalType: 'uint256',
      name: 'cid',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    name: 'getChainToConfig',
    outputs: [{
      internalType: 'uint256',
      name: 'fixedNativeFee',
      type: 'uint256'
    }, {
      internalType: 'bool',
      name: 'isSupported',
      type: 'bool'
    }, {
      internalType: 'uint16',
      name: 'transferFeeBps',
      type: 'uint16'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    name: 'getDebridge',
    outputs: [{
      internalType: 'uint256',
      name: 'chainId',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'maxAmount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'balance',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'lockedInStrategies',
      type: 'uint256'
    }, {
      internalType: 'address',
      name: 'tokenAddress',
      type: 'address'
    }, {
      internalType: 'uint16',
      name: 'minReservesBps',
      type: 'uint16'
    }, {
      internalType: 'bool',
      name: 'exist',
      type: 'bool'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '_debridgeId',
      type: 'bytes32'
    }, {
      internalType: 'uint256',
      name: '_chainId',
      type: 'uint256'
    }],
    name: 'getDebridgeChainAssetFixedFee',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    name: 'getDebridgeFeeInfo',
    outputs: [{
      internalType: 'uint256',
      name: 'collectedFees',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: 'withdrawnFees',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint256',
      name: '_chainId',
      type: 'uint256'
    }, {
      internalType: 'address',
      name: '_tokenAddress',
      type: 'address'
    }],
    name: 'getDebridgeId',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'pure',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '_debridgeId',
      type: 'bytes32'
    }, {
      internalType: 'string',
      name: '_name',
      type: 'string'
    }, {
      internalType: 'string',
      name: '_symbol',
      type: 'string'
    }, {
      internalType: 'uint8',
      name: '_decimals',
      type: 'uint8'
    }],
    name: 'getDeployId',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'pure',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '',
      type: 'address'
    }],
    name: 'getNativeInfo',
    outputs: [{
      internalType: 'uint256',
      name: 'nativeChainId',
      type: 'uint256'
    }, {
      internalType: 'bytes',
      name: 'nativeAddress',
      type: 'bytes'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }],
    name: 'getRoleAdmin',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '_debridgeId',
      type: 'bytes32'
    }, {
      internalType: 'uint256',
      name: '_chainIdFrom',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256'
    }, {
      internalType: 'address',
      name: '_receiver',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: '_nonce',
      type: 'uint256'
    }, {
      components: [{
        internalType: 'uint256',
        name: 'executionFee',
        type: 'uint256'
      }, {
        internalType: 'uint256',
        name: 'flags',
        type: 'uint256'
      }, {
        internalType: 'address',
        name: 'fallbackAddress',
        type: 'address'
      }, {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes'
      }, {
        internalType: 'bytes',
        name: 'nativeSender',
        type: 'bytes'
      }],
      internalType: 'struct IDeBridgeGate.SubmissionAutoParamsFrom',
      name: '_autoParams',
      type: 'tuple'
    }, {
      internalType: 'bool',
      name: '_hasAutoParams',
      type: 'bool'
    }, {
      internalType: 'address',
      name: '_sender',
      type: 'address'
    }],
    name: 'getSubmissionIdFrom',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint256',
      name: '_chainId',
      type: 'uint256'
    }, {
      internalType: 'bytes',
      name: '_tokenAddress',
      type: 'bytes'
    }],
    name: 'getbDebridgeId',
    outputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    stateMutability: 'pure',
    type: 'function'
  }, {
    inputs: [],
    name: 'globalFixedNativeFee',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'globalTransferFeeBps',
    outputs: [{
      internalType: 'uint16',
      name: '',
      type: 'uint16'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'hasRole',
    outputs: [{
      internalType: 'bool',
      name: '',
      type: 'bool'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint8',
      name: '_excessConfirmations',
      type: 'uint8'
    }, {
      internalType: 'contract IWETH',
      name: '_weth',
      type: 'address'
    }],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    name: 'isBlockedSubmission',
    outputs: [{
      internalType: 'bool',
      name: '',
      type: 'bool'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '',
      type: 'bytes32'
    }],
    name: 'isSubmissionUsed',
    outputs: [{
      internalType: 'bool',
      name: '',
      type: 'bool'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'lockedClaim',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'nonce',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'pause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [],
    name: 'paused',
    outputs: [{
      internalType: 'bool',
      name: '',
      type: 'bool'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: 'role',
      type: 'bytes32'
    }, {
      internalType: 'address',
      name: 'account',
      type: 'address'
    }],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '_tokenAddress',
      type: 'address'
    }, {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: '_chainIdTo',
      type: 'uint256'
    }, {
      internalType: 'bytes',
      name: '_receiver',
      type: 'bytes'
    }, {
      internalType: 'bytes',
      name: '_permitEnvelope',
      type: 'bytes'
    }, {
      internalType: 'bool',
      name: '_useAssetFee',
      type: 'bool'
    }, {
      internalType: 'uint32',
      name: '_referralCode',
      type: 'uint32'
    }, {
      internalType: 'bytes',
      name: '_autoParams',
      type: 'bytes'
    }],
    name: 'send',
    outputs: [{
      internalType: 'bytes32',
      name: 'submissionId',
      type: 'bytes32'
    }],
    stateMutability: 'payable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint256',
      name: '_chainIdTo',
      type: 'uint256'
    }, {
      internalType: 'bytes',
      name: '_targetContractAddress',
      type: 'bytes'
    }, {
      internalType: 'bytes',
      name: '_targetContractCalldata',
      type: 'bytes'
    }, {
      internalType: 'uint256',
      name: '_flags',
      type: 'uint256'
    }, {
      internalType: 'uint32',
      name: '_referralCode',
      type: 'uint32'
    }],
    name: 'sendMessage',
    outputs: [{
      internalType: 'bytes32',
      name: 'submissionId',
      type: 'bytes32'
    }],
    stateMutability: 'payable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint256',
      name: '_chainIdTo',
      type: 'uint256'
    }, {
      internalType: 'bytes',
      name: '_targetContractAddress',
      type: 'bytes'
    }, {
      internalType: 'bytes',
      name: '_targetContractCalldata',
      type: 'bytes'
    }],
    name: 'sendMessage',
    outputs: [{
      internalType: 'bytes32',
      name: 'submissionId',
      type: 'bytes32'
    }],
    stateMutability: 'payable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '_callProxy',
      type: 'address'
    }],
    name: 'setCallProxy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint256',
      name: '_chainId',
      type: 'uint256'
    }, {
      internalType: 'bool',
      name: '_isSupported',
      type: 'bool'
    }, {
      internalType: 'bool',
      name: '_isChainFrom',
      type: 'bool'
    }],
    name: 'setChainSupport',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '_deBridgeTokenDeployer',
      type: 'address'
    }],
    name: 'setDeBridgeTokenDeployer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '_value',
      type: 'address'
    }],
    name: 'setFeeContractUpdater',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '_feeProxy',
      type: 'address'
    }],
    name: 'setFeeProxy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '_verifier',
      type: 'address'
    }],
    name: 'setSignatureVerifier',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'contract IWethGate',
      name: '_wethGate',
      type: 'address'
    }],
    name: 'setWethGate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [],
    name: 'signatureVerifier',
    outputs: [{
      internalType: 'address',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes4',
      name: 'interfaceId',
      type: 'bytes4'
    }],
    name: 'supportsInterface',
    outputs: [{
      internalType: 'bool',
      name: '',
      type: 'bool'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '_debridgeId',
      type: 'bytes32'
    }, {
      internalType: 'uint256',
      name: '_maxAmount',
      type: 'uint256'
    }, {
      internalType: 'uint16',
      name: '_minReservesBps',
      type: 'uint16'
    }, {
      internalType: 'uint256',
      name: '_amountThreshold',
      type: 'uint256'
    }],
    name: 'updateAsset',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '_debridgeId',
      type: 'bytes32'
    }, {
      internalType: 'uint256[]',
      name: '_supportedChainIds',
      type: 'uint256[]'
    }, {
      internalType: 'uint256[]',
      name: '_assetFeesInfo',
      type: 'uint256[]'
    }],
    name: 'updateAssetFixedFees',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint256[]',
      name: '_chainIds',
      type: 'uint256[]'
    }, {
      components: [{
        internalType: 'uint256',
        name: 'fixedNativeFee',
        type: 'uint256'
      }, {
        internalType: 'bool',
        name: 'isSupported',
        type: 'bool'
      }, {
        internalType: 'uint16',
        name: 'transferFeeBps',
        type: 'uint16'
      }],
      internalType: 'struct IDeBridgeGate.ChainSupportInfo[]',
      name: '_chainSupportInfo',
      type: 'tuple[]'
    }, {
      internalType: 'bool',
      name: '_isChainFrom',
      type: 'bool'
    }],
    name: 'updateChainSupport',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint8',
      name: '_excessConfirmations',
      type: 'uint8'
    }],
    name: 'updateExcessConfirmations',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'address',
      name: '_address',
      type: 'address'
    }, {
      internalType: 'uint16',
      name: '_discountFixBps',
      type: 'uint16'
    }, {
      internalType: 'uint16',
      name: '_discountTransferBps',
      type: 'uint16'
    }],
    name: 'updateFeeDiscount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'uint256',
      name: '_globalFixedNativeFee',
      type: 'uint256'
    }, {
      internalType: 'uint16',
      name: '_globalTransferFeeBps',
      type: 'uint16'
    }],
    name: 'updateGlobalFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [],
    name: 'version',
    outputs: [{
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }],
    stateMutability: 'pure',
    type: 'function'
  }, {
    inputs: [],
    name: 'weth',
    outputs: [{
      internalType: 'contract IWETH',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [],
    name: 'wethGate',
    outputs: [{
      internalType: 'contract IWethGate',
      name: '',
      type: 'address'
    }],
    stateMutability: 'view',
    type: 'function'
  }, {
    inputs: [{
      internalType: 'bytes32',
      name: '_debridgeId',
      type: 'bytes32'
    }],
    name: 'withdrawFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    stateMutability: 'payable',
    type: 'receive'
  }]
};
