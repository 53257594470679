"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DISTRIBUTION = void 0;
exports.DISTRIBUTION = [{
  inputs: [],
  stateMutability: 'nonpayable',
  type: 'constructor'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'CrowdLinearDistributionCreated',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'from',
    type: 'address'
  }],
  name: 'CrowdLinearDistributionInitialized',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'TokensReleased',
  type: 'event'
}, {
  stateMutability: 'nonpayable',
  type: 'fallback'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: '_beneficiaries',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: '_beneficiaryIndex',
  outputs: [{
    internalType: 'uint256',
    name: '_start',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_initial',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_released',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_balance',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_vestingType',
    type: 'uint256'
  }, {
    internalType: 'bool',
    name: '_exist',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: '_tokenAddress',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'enum CrowdLinearDistribution.VestingType',
    name: '',
    type: 'uint8'
  }],
  name: '_vestingTypeIndex',
  outputs: [{
    internalType: 'uint256',
    name: '_initial',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: '_allocatedInitial',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'start',
    type: 'uint256'
  }, {
    internalType: 'uint8',
    name: 'vestingType',
    type: 'uint8'
  }, {
    internalType: 'uint256',
    name: 'initial',
    type: 'uint256'
  }],
  name: 'create',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'getBalance',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'vestingType',
    type: 'uint256'
  }],
  name: 'getBeneficiaries',
  outputs: [{
    internalType: 'address[]',
    name: '',
    type: 'address[]'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'getBeneficiary',
  outputs: [{
    components: [{
      internalType: 'uint256',
      name: '_start',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: '_initial',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: '_released',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: '_balance',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: '_vestingType',
      type: 'uint256'
    }, {
      internalType: 'bool',
      name: '_exist',
      type: 'bool'
    }, {
      components: [{
        internalType: 'uint256',
        name: '_month',
        type: 'uint256'
      }, {
        internalType: 'uint256',
        name: '_value',
        type: 'uint256'
      }],
      internalType: 'struct CrowdLinearDistribution.Ruleset[]',
      name: '_ruleset',
      type: 'tuple[]'
    }],
    internalType: 'struct CrowdLinearDistribution.BeneficiaryStruct',
    name: '',
    type: 'tuple'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'getInitial',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'getReleasable',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'getReleased',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'getStart',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'getTotal',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'getVested',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'getVestingType',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'vestingType',
    type: 'uint256'
  }],
  name: 'getVestingTypeStruct',
  outputs: [{
    components: [{
      internalType: 'uint256',
      name: '_initial',
      type: 'uint256'
    }, {
      internalType: 'uint256',
      name: '_allocatedInitial',
      type: 'uint256'
    }, {
      components: [{
        internalType: 'uint256',
        name: '_month',
        type: 'uint256'
      }, {
        internalType: 'uint256',
        name: '_value',
        type: 'uint256'
      }],
      internalType: 'struct CrowdLinearDistribution.Ruleset[]',
      name: '_ruleset',
      type: 'tuple[]'
    }],
    internalType: 'struct CrowdLinearDistribution.VestingTypeStruct',
    name: '',
    type: 'tuple'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'tokenAddress',
    type: 'address'
  }],
  name: 'initialize',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'beneficiary',
    type: 'address'
  }],
  name: 'release',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}];
