const createKeccak = require('./keccak');
const createShake = require('./shake');
module.exports = function (KeccakState) {
  const Keccak = createKeccak(KeccakState);
  const Shake = createShake(KeccakState);
  return function (algorithm, options) {
    const hash = typeof algorithm === 'string' ? algorithm.toLowerCase() : algorithm;
    switch (hash) {
      case 'keccak224':
        return new Keccak(1152, 448, null, 224, options);
      case 'keccak256':
        return new Keccak(1088, 512, null, 256, options);
      case 'keccak384':
        return new Keccak(832, 768, null, 384, options);
      case 'keccak512':
        return new Keccak(576, 1024, null, 512, options);
      case 'sha3-224':
        return new Keccak(1152, 448, 0x06, 224, options);
      case 'sha3-256':
        return new Keccak(1088, 512, 0x06, 256, options);
      case 'sha3-384':
        return new Keccak(832, 768, 0x06, 384, options);
      case 'sha3-512':
        return new Keccak(576, 1024, 0x06, 512, options);
      case 'shake128':
        return new Shake(1344, 256, 0x1f, options);
      case 'shake256':
        return new Shake(1088, 512, 0x1f, options);
      default:
        throw new Error('Invald algorithm: ' + algorithm);
    }
  };
};