import 'viem';
import '../createConfig.js';
import '../types/properties.js';
import '../types/utils.js';
export function getClient(config, parameters = {}) {
  let client = undefined;
  try {
    client = config.getClient(parameters);
  } catch {}
  return client;
}
