"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CrowdSwapBridge = void 0;
exports.CrowdSwapBridge = [{
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'previousAdmin',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newAdmin',
    type: 'address'
  }],
  name: 'AdminChanged',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'beacon',
    type: 'address'
  }],
  name: 'BeaconUpgraded',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'uint8',
    name: 'version',
    type: 'uint8'
  }],
  name: 'Initialized',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'sender',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'receiver',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'fromChainId',
    type: 'uint256'
  }],
  name: 'LogSwapIn',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'sender',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'receiver',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'toChainId',
    type: 'uint256'
  }],
  name: 'LogSwapOut',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'sender',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'receiver',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'toChainId',
    type: 'uint256'
  }],
  name: 'LogSwapOutFail',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Paused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'chainId',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'oldPeer',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newPeer',
    type: 'address'
  }],
  name: 'SetBridgePeers',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'oldCallProxy',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newCallProxy',
    type: 'address'
  }],
  name: 'SetCallProxy',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'bool',
    name: 'oldShouldLock',
    type: 'bool'
  }, {
    indexed: false,
    internalType: 'bool',
    name: 'newShouldLock',
    type: 'bool'
  }],
  name: 'SetShouldLock',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'user',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'srcToken',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'chainId',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'oldDstToken',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'newDstToken',
    type: 'address'
  }],
  name: 'SetTokenPeers',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'Unpaused',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'implementation',
    type: 'address'
  }],
  name: 'Upgraded',
  type: 'event'
}, {
  inputs: [{
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  name: 'anyExecute',
  outputs: [{
    internalType: 'bool',
    name: 'success',
    type: 'bool'
  }, {
    internalType: 'bytes',
    name: 'result',
    type: 'bytes'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'bridgePeers',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'callProxy',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'claimOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'executor',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bool',
    name: '_shouldLock',
    type: 'bool'
  }, {
    internalType: 'address',
    name: '_callProxy',
    type: 'address'
  }],
  name: 'initialize',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pause',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'paused',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'pendingOwner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'proxiableUUID',
  outputs: [{
    internalType: 'bytes32',
    name: '',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256[]',
    name: '_chainIds',
    type: 'uint256[]'
  }, {
    internalType: 'address[]',
    name: '_peers',
    type: 'address[]'
  }],
  name: 'setBridgePeers',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_callProxy',
    type: 'address'
  }],
  name: 'setCallProxy',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'bool',
    name: '_shouldLock',
    type: 'bool'
  }],
  name: 'setShouldLock',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_srcToken',
    type: 'address'
  }, {
    internalType: 'uint256[]',
    name: '_chainIds',
    type: 'uint256[]'
  }, {
    internalType: 'address[]',
    name: '_dstTokens',
    type: 'address[]'
  }],
  name: 'setTokenPeers',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'shouldLock',
  outputs: [{
    internalType: 'bool',
    name: '',
    type: 'bool'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'token',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    internalType: 'address',
    name: 'receiver',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'toChainId',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'flags',
    type: 'uint256'
  }],
  name: 'swapOut',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  name: 'tokenPeers',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'unpause',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }],
  name: 'upgradeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address'
  }, {
    internalType: 'bytes',
    name: 'data',
    type: 'bytes'
  }],
  name: 'upgradeToAndCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}];
