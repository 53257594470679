import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import axios from 'axios';

const baseUrl = environment.SCANNER_BASEURL + '/scanner/' || '';

@Injectable()
export class ScannerService {
  public userStatus;

  constructor() {}

  public async getData(txHash: string) {
    if (!txHash) {
      return;
    }

    const url = baseUrl + txHash;
    const result = await axios.get(url);

    if (result.status === 200) {
      return result.data;
    }

    console.log(`Cannot fetch data`);
    return;
  }
}
