"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WhiteList = void 0;
const networks_1 = require("./utils/networks/networks");
exports.WhiteList = {
  CROWD: {
    [networks_1.Networks.POLYGON_MAINNET]: {
      acceptablePaths: ['USDT->CROWD', 'CROWD->USDT', 'CROWD->WMATIC', 'WMATIC->CROWD', 'USDC->CROWD', 'CROWD->USDC'],
      acceptablePathsAddresses: ['0XC2132D05D31C914A87C6611C10748AEB04B58E8F', '0X483DD3425278C1F79F377F1034D9D2CAE55648B6', '0X2791BCA1F2DE4661ED88A30C99A7A9449AA84174', '0X0D500B1D8E8EF31E21C99D1DB9A6444D3ADF1270'],
      poolAddress: ''
    },
    [networks_1.Networks.BSCMAIN]: {
      acceptablePaths: ['WBNB->CROWD', 'CROWD->WBNB'],
      acceptablePathsAddresses: ['0XA5D4B64A639D93B660CDA04D331374DA1108F8F5', '0XBB4CDB9CBD36B01BD1CBAEBF2DE08D9173BC095C'],
      poolAddress: ''
    },
    [networks_1.Networks.ARBITRUM]: {
      acceptablePaths: ['ARB->CROWD', 'CROWD->ARB'],
      acceptablePathsAddresses: ['0X912CE59144191C1204E64559FE8253A0E49E6548', '0X250CAFF618CF205997309940C14C52B5DCEB351E'],
      poolAddress: ''
    },
    [networks_1.Networks.ZKSYNC]: {
      acceptablePaths: ['WETH->CROWD', 'CROWD->WETH'],
      acceptablePathsAddresses: ['0X5AEA5775959FBC2557CC8789BC1BF90A239D9A91', '0X405A7426277C288E963A9B6AB2C9E5E6C4B5F05C'],
      poolAddress: ''
    }
  }
};
