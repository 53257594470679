import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'RemainingTime'
})
export class RemainingTimePipe implements PipeTransform {
  transform(fromDate: number, toDate: number): string {
    const remaining = toDate * 1000 - fromDate * 1000;

    const remainingHours = Math.floor(remaining / (1000 * 60 * 60));
    const remainingMinutes = Math.floor(remaining / (1000 * 60));
    const remainingSeconds = Math.floor(remaining / 1000);

    return remainingHours > 0
      ? `${remainingHours} hour${remainingHours > 1 ? 's' : ''} spent`
      : remainingMinutes > 0
        ? `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''} spent`
        : remainingSeconds > 0
          ? `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''} spent`
          : '';
  }
}
